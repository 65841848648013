import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { guardarPerfil } from '../../actions/configuracion';

const Configuracion = ({ history }) =>{

    const dispatch = useDispatch();

    const perfil = useSelector(state => state.auth);
    const { permisos_configuracion } = useSelector(state => state.auth.user);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(guardarPerfil(perfilData));
        } catch (error) {
            return false;
        }
    }
    
    const [perfilData, setPerfilData ] = useState({
        nombre: '',
        email: '',
        password: ''
    });

    useEffect(() => {
        setPerfilData({
            ...perfilData,
            id: perfil.user?._id,
            nombre: perfil.user?.nombre,
            email: perfil.user?.email
        })
    }, [perfil]);

    const onChange = (e) => {
        setPerfilData({
            ...perfilData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Información personal</h3>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Nombre:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e)} value={perfilData.nombre}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email de acceso" name="email" onChange={e => onChange(e)} value={perfilData.email}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña de acceso" name="password" onChange={e => onChange(e)} value={perfilData.password}></input>
                    </div>
                </div>
                {
                    permisos_configuracion?.guardar ?
                        <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Guardar perfil</button>
                    :
                        null
                }
            </form>
        </div>
    )
}

export default Configuracion;