import React, { useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { detallesFarmacia, inventarioFarmacia, eliminarCajero } from '../../actions/farmacias';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const FarmaciaInventario = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>Buscando listado de productos...</b></p>);

    const { id } = useParams();

    const dispatch = useDispatch();

    const { detalles, inventario } = useSelector(state => state.farmacias);

    useEffect(() => {
        dispatch(detallesFarmacia(id));
        dispatch(inventarioFarmacia(id));
    }, [id]);

    const columnsInventario = [
        {
            name: 'Nombre',
            selector: 'nombre',
            sortable: true,
            cell: (item) => {
                if(item.cantidad && item.reorden){
                    if(item.cantidad <= item.reorden){
                        return <b className="indicador_reorden">{item.nombre ? item.nombre : '-'}</b>;
                    }else{
                        return item.nombre ? item.nombre : '-';
                    }
                }else{
                    return item.nombre ? item.nombre : '-';
                }
            }
        },
        {
            name: 'Categoria',
            selector: 'nombre_categoria',
            sortable: true,
            cell: (item) => {
                if(item.cantidad && item.reorden){
                    if(item.cantidad <= item.reorden){
                        return <b className="indicador_reorden">{item.nombre_categoria ? item.nombre_categoria : '-'}</b>;
                    }else{
                        return item.nombre_categoria ? item.nombre_categoria : '-';
                    }
                }else{
                    return item.nombre_categoria ? item.nombre_categoria : '-';
                }
            }
        },
        {
            name: 'Punto de reorden',
            selector: 'reorden',
            sortable: false,
            cell: (item) => {
                if(item.cantidad && item.reorden){
                    if(item.cantidad <= item.reorden){
                        return <b className="indicador_reorden">{item.reorden ? item.reorden : '-'}</b>;
                    }else{
                        return item.reorden ? item.reorden : '-';
                    }
                }else{
                    return item.reorden ? item.reorden : '-';
                }
            }
        },
        {
            name: 'Cantidad',
            selector: 'cantidad',
            sortable: false,
            cell: (item) => {
                if(item.cantidad && item.reorden){
                    if(item.cantidad <= item.reorden){
                        return <b className="indicador_reorden">{item.cantidad}</b>;
                    }else{
                        return item.cantidad;
                    }
                }else{
                    return '-';
                }
            }
        }
    ]

    return (
        detalles ? <Fragment>
            <div className="row px-5 pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Inventario ({detalles.nombre})</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={styles.sectionContainer}>
                                        <DataTable
                                            columns={columnsInventario}
                                            data={inventario || []}
                                            defaultSortField="title"
                                            noHeader={true}
                                            pagination
                                            paginationComponentOptions={paginationOptions}
                                            noDataComponent={noDataConst}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default FarmaciaInventario;
