import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import { editarAviso, detallesAviso } from '../../actions/avisos';

registerLocale('es', es);

const AvisoEditar = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const aviso = useSelector(state => state.avisos);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(editarAviso(avisoData));
            history.push('/avisos');
        } catch (error) {
            return false;
        }
    }
    
    const [avisoData, setAvisoData] = useState({
        id: '',
        mensaje: '',
        fecha_inicio: '',
        fecha_fin: ''
    });
    
    const onChange = (e) => {
        setAvisoData({
            ...avisoData, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        dispatch(detallesAviso(id));
    }, [id]);

    useEffect(() => {
        setAvisoData({
            ...avisoData,
            id: aviso.detalles?._id,
            mensaje: aviso.detalles?.mensaje,
            fecha_inicio: aviso.detalles?.fecha_inicio,
            fecha_fin: aviso.detalles?.fecha_fin
        })
    }, [aviso]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar aviso</h3>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Mensaje</label> <br></br>
                        <input type="text" className="form-control" placeholder="Mensaje" name="mensaje" onChange={e => onChange(e) } value={avisoData.mensaje || ''}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Fecha de inicio:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ avisoData.fecha_inicio ? new Date(avisoData.fecha_inicio) : '' } onChange={ date => setAvisoData({ ...avisoData, fecha_inicio: date })}/>
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Fecha de fin:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ avisoData.fecha_fin ? new Date(avisoData.fecha_fin) : '' } onChange={ date => setAvisoData({ ...avisoData, fecha_fin: date })}/>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Editar aviso</button>
            </form>
        </div>
    )
}

export default AvisoEditar;