import axios from 'axios';

import { LOAD_ESTADISTICA } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const generarReporte = (datos) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_reportes/${datos.reporte}`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarEstadistica = (datos) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if(datos){
        const body = JSON.stringify(datos);

        try {
            dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_estadisticas/${datos.ruta}`, body, config);
            dispatch(changeLoader(false));

            dispatch({
                type: LOAD_ESTADISTICA,
                payload: res.data.datos
            });
        } catch (error) {
            dispatch(changeLoader(false));
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: LOAD_ESTADISTICA,
            payload: null
        });
    }
}