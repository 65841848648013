import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { editarDistribuidor, detallesDistribuidor } from '../../actions/distribuidores';

const DistribuidorEditar = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const distribuidor = useSelector(state => state.distribuidores);
    
    const [distribuidorData, setDistribuidorData ] = useState({
        id: '',
        nombre: '',
        telefono: '',
        sexo: '',
        email: '',
        password: '',
        imagen: ''
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined,
    });

    let img = createRef();

    useEffect(() => {
        dispatch(detallesDistribuidor(id));
    }, []);

    useEffect(() => {
        setDistribuidorData({
            ...distribuidorData,
            id: distribuidor.detalles?._id,
            nombre: distribuidor.detalles?.nombre,
            telefono: distribuidor.detalles?.telefono,
            sexo: distribuidor.detalles?.sexo,
            email: distribuidor.detalles?.email,
            password: '',
            imagen: distribuidor.detalles?.url_perfil_g
        })
    }, [distribuidor]);
    
    const onChange = (e) => {
        setDistribuidorData({
            ...distribuidorData, [e.target.name]: e.target.value
        })
    }

    const onChangeImg = () => {
        setProfilePicture({
            ...profilePicture, img: img.current.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        

        try {
            let formData = new FormData();

            formData.append('id', distribuidorData.id);
            formData.append('nombre', distribuidorData.nombre);
            formData.append('telefono', distribuidorData.telefono);
            formData.append('sexo', distribuidorData.sexo);
            formData.append('email', distribuidorData.email);
            formData.append('password', distribuidorData.password);
            formData.append('perfil', profilePicture.img);

            dispatch(editarDistribuidor(formData));
            history.push('/distribuidores');
        } catch (error) {
            return false;
        }

    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar distribuidor</h3>
                <div className="row mb-3">
                    <div className="col-12 form-group">
                        <div className="picture-container">
                            <div className="picture">
                                <img src={profilePicture.img != null ? URL.createObjectURL(profilePicture.img) : distribuidorData.imagen} className="picture-src" id="wizardPicturePreview" title="" alt=""/>
                                <input type="file" name="image" id="image" ref={img} onChange={onChangeImg} accept="image/*"/>
                            </div>
                            <p className="icon bg-purple">+</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Cliente" name="nombre" onChange={e => onChange(e) } value={distribuidorData.nombre || ''}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono</label> <br></br>
                        <input type="text" className="form-control" placeholder="Teléfono" name="telefono" onChange={e => onChange(e) } value={distribuidorData.telefono || ''}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Sexo</label> <br></br>
                        <select className="form-control" name="sexo" value={distribuidorData.sexo || ''} onChange={e => onChange(e)}>
                            <option value="">Elegir sexo</option>
                            <option value="f">Femenino</option>
                            <option value="m">Masculino</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email" name="email" onChange={e => onChange(e) } value={distribuidorData.email || ''}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña" name="password" onChange={e => onChange(e) } value={distribuidorData.password || ''}></input>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Editar distribuidor</button>
            </form>
        </div>
    )
}

export default DistribuidorEditar;