import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { buscarPromDes, statusPromDes, eliminarPromDes } from '../../actions/promociones';

const PromocionesProductos = ({ history }) => {

    const { id } = useParams();

    const dispatch = useDispatch();

    const { promdes } = useSelector(state => state.promociones);
    const { permisos_promociones_productos } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.tipo == 0 ? 'Promoción' : 'Descuento'}</td>
                            <td>{item.producto?.nombre || '-'}</td>
                            <td>{item.concepto}</td>
                            <td>{item.tipo == 1 ? item.porcentaje + ' %' : item.cantidad_comprar + ' x ' + item.cantidad_cobrar}</td>
                            <td>
                                {
                                    permisos_promociones_productos?.status ?
                                        <button type="button" className={'btn btn-link ' + (item.activo ? 'text-success' : 'text-danger')} onClick={() => status(item._id)}>{item.activo ? 'Desactivar' : 'Activar'}</button>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_promociones_productos?.editar ?
                                        <Link className="btn btn-link text-link" to={`/promociones/productos_editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_promociones_productos?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const status = (id) => {
        dispatch(statusPromDes(id));
    }

    const eliminar = (id) => {
        dispatch(eliminarPromDes(id));
    }

    useEffect(() => {
        let id_producto = id;

        if(!id_producto){
            id_producto = null;
        }

        dispatch(buscarPromDes(id_producto));
    }, [id]);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Descuentos y promociones a productos</h3>
                        <p className="text-muted">{promdes?.length} promocion{promdes?.length == 1 ? '' : 'es'} o descuento{promdes?.length == 1 ? '' : 's'} registrado{promdes?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_promociones_productos?.agregar ?
                                <Link to="/promociones/productos_nueva" className="btn btn-success text-right">Nuevo descuento</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Tipo</th>
                            <th scope="col">Producto</th>
                            <th scope="col">Concepto</th>
                            <th scope="col">Aplicación</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(promdes != undefined ? promdes : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default PromocionesProductos;