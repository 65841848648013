import React, { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { detallesOrden, actualizarStatus } from '../../actions/ordenes';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    bordeDer: {
        borderRight: '1px solid #0000001a'
    },
    botonArchivos: {
        background: 'transparent',
        boxShadow: '0px 0px 0px transparent',
        border: '0px solid transparent',
        textShadow: '0px 0px 0px transparent'
    },
    btnStatus: {
        cursor: 'pointer',
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    progressbar: {
        paddingLeft: '0px'
    }
}

const OrdenDetalles = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay resultados a mostrar</b></p>);

    const { id } = useParams();

    const dispatch = useDispatch();

    const orden = useSelector(state => state.ordenes);
    const { permisos_ordenes_proceso } = useSelector(state => state.auth.user);
    
    const [ordenModal, setOrdenModal] = useState(false);

    const [ordenData, setOrdenData] = useState({
        id: '',
        status: 0
    });

    const handleOrden = () => {
        setOrdenModal(true);
    }

    const changeOrden = (id, status) => {
        dispatch(actualizarStatus({id, status}));
        setOrdenData({ ...ordenData, status: status });
        setOrdenModal(false);
    }

    useEffect(() => {
        dispatch(detallesOrden(id));
    }, [id]);

    useEffect(() => {
        setOrdenData({
            ...ordenData,
            id: orden.detalles?._id,
            status: orden.detalles?.status
        });
    }, [orden]);

    const columnsAcciones = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Acción',
            selector: 'descripcion',
            sortable: true,
            cell: item => item.descripcion ? item.descripcion : '-',
        }
    ]

    const colorOrden = (status) => {
        switch(status){
            case 0:
            case '0':
                return 'col-md-6 offset-md-3 form-control text-white bg-primary';
                break;

            case 1:
            case '1':
                return 'col-md-6 offset-md-3 form-control text-dark bg-warning';
                break;

            case 2:
            case '2':
                return 'col-md-6 offset-md-3 form-control text-white bg-success';
                break;

            case 3:
            case '3':
                return 'col-md-6 offset-md-3 form-control text-white bg-danger';
                break;
        }
    }

    const statusBotonReminder = (status) => {
        switch(status){
            case 0:
                return <Badge variant="primary" style={styles.btnStatus} onClick={() => handleOrden()}>Pendiente</Badge>;
                break;

            case 1:
                return <Badge variant="warning" style={styles.btnStatus} onClick={() => handleOrden()}>En proceso</Badge>;
                break;

            case 2:
                return <Badge variant="success">Finalizada</Badge>;
                break;

            case 3:
                return <Badge variant="danger">Cancelada</Badge>;
                break;
        }
    }

    const statusReminder = (status) => {
        switch(status){
            case 0:
                return <Badge variant="primary">Pendiente</Badge>;
                break;

            case 1:
                return <Badge variant="warning">En proceso</Badge>;
                break;

            case 2:
                return <Badge variant="success">Finalizada</Badge>;
                break;

            case 3:
                return <Badge variant="danger">Cancelada</Badge>;
                break;
        }
    }

    return (
        orden.detalles ? <Fragment>

            {/* Modal de órden */}
            <Modal show={ordenModal} onHide={() => {
                setOrdenModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Información de órden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ordenData.status != 3 && (
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <ul id="progressbar" style={styles.progressbar}>
                                    <li className={ordenData.status >= 0 ? 'active venta' : 'venta'} id="iniciada-li"><strong>Pendiente</strong></li>
                                    <li className={ordenData.status >= 1 ? 'active venta' : 'venta'} id="preautorizada-li"><strong>En proceso</strong></li>
                                    <li className={ordenData.status >= 2 ? 'active venta' : 'venta'} id="complementada-li"><strong>Finalizada</strong></li>
                                </ul>
                            </div>
                        </div>
                    )}

                    <p style={styles.info}>Actualiza el <b>status</b> de la órden generada por el distribuidor. Cualquier cambio de status quedará registrado en el historial.</p>
                </Modal.Body>
                <Modal.Footer>
                    <select
                        value={ordenData.status}
                        className={colorOrden(ordenData.status)}
                        onChange={e => {
                            changeOrden(orden.detalles._id, e.target.value);
                        }}
                    >
                        <option value="0">Pendiente</option>
                        <option value="1">En proceso</option>
                        <option value="2">Finalizada</option>
                        <option value="3">Cancelada</option>
                    </select>
                </Modal.Footer>
            </Modal>

            <div className="row px-5 pt-3">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{orden.detalles.cantidad ? orden.detalles.cantidad : 0}</p>
                                    <p style={styles.infoText}>cantidad</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{orden.detalles.numero_ventas ? orden.detalles.numero_ventas : 0}</p>
                                    <p style={styles.infoText}>ventas involucradas</p>
                                </div>
                                <div className="col-md">
                                        {
                                            permisos_ordenes_proceso?.status ?
                                                <p style={styles.infoNumero}>{statusBotonReminder(ordenData.status)}</p>
                                            :
                                                <p style={styles.infoNumero}>{statusReminder(ordenData.status)}</p>
                                        }
                                    <p style={styles.infoText}>status</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Detalles de la órden</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="d-flex justify-content-center">
                                        { orden.detalles.distribuidor?.url_perfil ?
                                            <img
                                                height="150"
                                                className="my-3 text-center"
                                                src={orden.detalles.distribuidor?.url_perfil}
                                            ></img>
                                        :   [
                                                (orden.detalles.distribuidor?.sexo == 'm' ?
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_distribuidor.png"
                                                        ></img>
                                                    :
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_distribuidora.png"
                                                        ></img>
                                                )
                                            ]
                                        }
                                    </div>
                                </div>
                                <div className="col-md-8 row">
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Distribuidor</p>
                                        <p style={styles.info}>{orden.detalles.distribuidor?.nombre ? orden.detalles.distribuidor?.nombre : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Teléfono</p>
                                        <p style={styles.info}>{orden.detalles.distribuidor?.telefono ? orden.detalles.distribuidor?.telefono : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Correo</p>
                                        <p style={styles.info}>{orden.detalles.distribuidor?.email ? orden.detalles.distribuidor?.email : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Fecha de creación</p>
                                        <p style={styles.info}>{new Date(orden.detalles.fecha_creacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Comentarios y observaciones</p>
                                        <p style={styles.info}>{orden.detalles.observaciones ? orden.detalles.observaciones : '-' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Historial de acciones</h5>
                            <div style={styles.sectionContainer}>
                                <DataTable
                                    columns={columnsAcciones}
                                    data={orden?.detalles?.historial_status}
                                    defaultSortField="title"
                                    noHeader={true}
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    noDataComponent={noDataConst}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default OrdenDetalles;
