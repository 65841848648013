import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarProveedores, eliminarProveedor } from '../../actions/proveedores';

const Proveedores = ({ history }) => {

    const dispatch = useDispatch();

    const proveedores = useSelector(state => state.proveedores);
    const { permisos_proveedores } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.contacto}</td>
                            <td>{item.email}</td>
                            <td>{item.telefono}</td>
                            <td>{item.numero_sucursales}</td>
                            <td>
                                {
                                    permisos_proveedores?.editar ?
                                        <Link className="btn btn-link text-link" to={`/proveedores/editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_proveedores?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarProveedor(id));
    }

    useEffect(() => {
        dispatch(buscarProveedores());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Proveedores</h3>
                        <p className="text-muted">{proveedores.proveedores?.length} proveedor{proveedores.proveedores?.length == 1 ? '' : 'es'} registrado{proveedores.proveedores?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_proveedores?.agregar ?
                                <Link to="/proveedores/nuevo" className="btn btn-success text-right">Nuevo proveedor</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Contacto</th>
                            <th scope="col">Email</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Sucursales</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(proveedores.proveedores != undefined ? proveedores.proveedores : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Proveedores;