import { LOAD_PRODUCTOS, ADD_PRODUCTO, EDIT_PRODUCTO, DETAIL_PRODUCTO, DELETE_PRODUCTO,
    LOAD_CATEGORIAS, ADD_CATEGORIA, DELETE_CATEGORIA, EDIT_CATEGORIA, DETAIL_CATEGORIA, 
} from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

// PRODUCTOS

export const buscarProductos = (id_categoria = null) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));

        const body = JSON.stringify({
            id_categoria
        });

        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_productos`, body, config);

        dispatch(changeLoader(false));
        
        dispatch({
            type: LOAD_PRODUCTOS,
            payload: res.data.productos
        });
    } catch (error) {
        dispatch(changeLoader(false));
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const crearProducto = (producto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_productos/agregar`, producto, config);
        dispatch(changeLoader(false));
        console.log(res.data);
        /*dispatch({
            type: ADD_PRODUCTO,
            payload: res.data.producto
        });*/
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarProducto = (producto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    console.log(producto);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_productos/editar`, producto, config);
        dispatch(changeLoader(false));
        /*dispatch({
            type: EDIT_PRODUCTO,
            payload: res.data.producto
        });*/
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesProducto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_productos/detalles`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: DETAIL_PRODUCTO,
            payload: res.data.producto
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const eliminarProducto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_productos/eliminar`, body, config);
        dispatch({
            type: DELETE_PRODUCTO,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// CATEGORIAS

export const buscarCategorias = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_categorias/`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_CATEGORIAS,
            payload: res.data.categorias
        });
    } catch (error) {
        dispatch(changeLoader(false));
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const crearCategoria = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(categoria);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_categorias/agregar`, body, config);
        dispatch(changeLoader(false));
        console.log(res.data);
        dispatch({
            type: ADD_CATEGORIA,
            payload: res.data.categoria
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarCategoria = (producto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(producto);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_categorias/editar`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: EDIT_CATEGORIA,
            payload: res.data.categoria
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesCategoria = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_categorias/detalles`, body, config);
        dispatch(changeLoader(false));
        console.log(res.data.categoria)
        dispatch({
            type: DETAIL_CATEGORIA,
            payload: res.data.categoria
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const eliminarCategoria = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_categorias/eliminar`, body, config);
        dispatch({
            type: DELETE_CATEGORIA,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}