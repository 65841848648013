import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import { crearNoticia } from '../../actions/noticias';

registerLocale('es', es);

const NoticiaNueva = ({ history }) =>{

    const dispatch = useDispatch();
    
    const [noticiaData, setNoticiaData ] = useState({
        titulo: '',
        fecha: '',
        contenido: '',
        imagen: ''
    });

    const [noticiaPicture, setNoticiaPicture] = useState({
        img: undefined,
    });
    
    let { titulo, fecha, contenido, imagen } = noticiaData;

    let img = createRef();
    
    const onChange = (e) => {
        setNoticiaData({
            ...noticiaData, [e.target.name]: e.target.value
        })
    }

    const onChangeImg = () => {
        if(img.current.files.length > 0){
            setNoticiaData({
                ...noticiaData, imagen: URL.createObjectURL(img.current.files[0])
            });

            setNoticiaPicture({
                ...noticiaPicture, img: img.current.files[0]
            });
        }else{
            setNoticiaData({
                ...noticiaData, imagen: ''
            });

            setNoticiaPicture({
                ...noticiaPicture, img: undefined
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let formData = new FormData();
            formData.append('titulo', titulo);
            formData.append('fecha', fecha);
            formData.append('contenido', contenido);
            formData.append('imagen', noticiaPicture.img);
            
            dispatch(crearNoticia(formData));
            history.push('/noticias');
        } catch (error) {
            return false;
        }
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nueva noticia</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Titulo</label> <br></br>
                        <input type="text" className="form-control" placeholder="Titulo de noticia" name="titulo" onChange={e => onChange(e) } value={titulo}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Fecha de publicación:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ fecha ? new Date(fecha) : '' } onChange={ date => setNoticiaData({ ...noticiaData, fecha: date })}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Contenido</label> <br></br>
                        <textarea className="form-control" rows="5" placeholder="Contenido de la noticia" name="contenido" onChange={e => onChange(e) } value={contenido}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Imagen</label> <br></br>
                        { imagen != '' && (
                            <div className="d-flex">
                                <img src={imagen} className="img-fluid rounded mb-5 mt-3 mr-3 picture-med"/>
                            </div>
                        )}
                        <input type="file" className="form-control" name="image" id="image" ref={img} onChange={onChangeImg} accept="image/*" />
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear noticia</button>
            </form>
        </div>
    )
}

export default NoticiaNueva;