import { LOAD_DASHBOARD } from './types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

// LOAD USER
export const loadDashboard = () => async dispatch => {
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_dashboard`);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_DASHBOARD,
            payload: res.data.info
        })
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}