import { LOAD_PAGOS_DISTRIBUIDORES, DETAIL_PAGO_DISTRIBUIDOR, PROCESS_PAGO_DISTRIBUIDOR, LOAD_PAGOS_MEDICOS, DETAIL_PAGO_MEDICO, PROCESS_PAGO_MEDICO } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const buscarPagosDistribuidores = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/distribuidores`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_PAGOS_DISTRIBUIDORES,
            payload: res.data.pagos
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const procesarPagosDistribuidores = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/procesar_distribuidores`, config);
        dispatch(changeLoader(false));
        
        if(res.data.success){
            dispatch({
                type: LOAD_PAGOS_DISTRIBUIDORES,
                payload: res.data.pagos
            });
            dispatch(setAlert(res.data.msg, 'success'));
        }else{
            dispatch(setAlert(res.data.msg, 'warning'));
        }
    } catch (error) {
        console.log(error);
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesPagoDistribuidor = (id, id_distribuidor) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id, id_distribuidor });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/detalle_distribuidor`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: DETAIL_PAGO_DISTRIBUIDOR,
            payload: res.data.pago
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const actualizarPagoDistribuidor = (id, id_distribuidor) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id, id_distribuidor });

    console.log(body);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/pagar_distribuidor`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: PROCESS_PAGO_DISTRIBUIDOR,
            payload: res.data.pago
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const buscarPagosMedicos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/medicos`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_PAGOS_MEDICOS,
            payload: res.data.pagos
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesPagoMedico = (id, id_medico) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id, id_medico });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/detalle_medico`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: DETAIL_PAGO_MEDICO,
            payload: res.data.pago
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const actualizarPagoMedico = (id, id_medico) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id, id_medico });

    console.log(body);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/pagar_medico`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: PROCESS_PAGO_MEDICO,
            payload: res.data.pago
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}