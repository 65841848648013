import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { crearCajero } from '../../actions/farmacias';

const CajeroNuevo = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearCajero(cajeroData));
            history.push('/farmacias/detalles/' + id);
        } catch (error) {
            return false;
        }
    }
    
    const [cajeroData, setCajeroData ] = useState({
        id: id,
        nombre: '',
        telefono: '',
        sexo: '',
        email: '',
        password: 'biogenica'
    });
    
    const { nombre, telefono, sexo, email, password } = cajeroData;
    
    const onChange = (e) => {
        setCajeroData({
            ...cajeroData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nuevo empleado</h3>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Empleado" name="nombre" onChange={e => onChange(e) } value={nombre}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono</label> <br></br>
                        <input type="text" className="form-control" placeholder="Teléfono" name="telefono" onChange={e => onChange(e) } value={telefono}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Sexo</label> <br></br>
                        <select className="form-control" name="sexo" value={sexo} onChange={e => onChange(e)}>
                            <option value="">Elegir sexo</option>
                            <option value="f">Femenino</option>
                            <option value="m">Masculino</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email" name="email" onChange={e => onChange(e) } value={email}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña" name="password" onChange={e => onChange(e) } value={password}></input>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear empleado</button>
            </form>
        </div>
    )
}

export default CajeroNuevo;