import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Modal } from 'react-bootstrap';
import { buscarPagosDistribuidores, procesarPagosDistribuidores } from '../../actions/pagos';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10, textAlign: 'justify' },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    bordeDer: {
        borderRight: '1px solid #0000001a'
    },
    botonArchivos: {
        background: 'transparent',
        boxShadow: '0px 0px 0px transparent',
        border: '0px solid transparent',
        textShadow: '0px 0px 0px transparent'
    },
    btnStatus: {
        cursor: 'pointer',
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    progressbar: {
        paddingLeft: '0px'
    }
}

const PagosDistribuidores = ({ history }) => {

    const dispatch = useDispatch();

    const pagos = useSelector(state => state.pagos);
    const { permisos_pagos_distribuidores } = useSelector(state => state.auth.user);

    const [ordenModal, setOrdenModal] = useState(false);

    const handleOrden = () => {
        setOrdenModal(true);
    }

    const processPagos = () => {
        try {
            dispatch(procesarPagosDistribuidores());
            setOrdenModal(false);
        } catch (error) {
            return false;
        }
    }

    const tableRow = (items) => {

        const numberToDecimal = (number) => {
            number = (Math.round(number * 100) / 100).toFixed(2);
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }

        const facturaReminder = (facturado) => {
            if(facturado){
                return <Badge variant="success">Realizada</Badge>;
            }else{
                return <Badge variant="warning">Pendiente</Badge>;
            }
        }

        const pagoReminder = (pagado) => {
            if(pagado){
                return <Badge variant="success">Realizado</Badge>;
            }else{
                return <Badge variant="warning">Pendiente</Badge>;
            }
        }

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{numberToDecimal(item.total)}</td>
                            <td>{numberToDecimal(item.total_factura)}</td>
                            <td>{new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>{facturaReminder(item.facturado)}</td>
                            <td>{pagoReminder(item.pagado)}</td>
                            <td>
                                {
                                    permisos_pagos_distribuidores?.detalles ?
                                        <Link className="btn btn-link text-link" to={`/pagos/distribuidores/detalles/${item._id}/${item.id_distribuidor}`}>Detalles</Link>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(buscarPagosDistribuidores());
    }, []);

    return (
        <Fragment>
            {/* Modal de órden */}
            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={ordenModal} onHide={() => {
                setOrdenModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Procesar pagos a distribuidores</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={styles.info}>Si das click en el boton de abajo, las ventas, tanto directas como indirectas de los distribuidores hacia los medicos se transformaran en las ganancias generadas, y con ellas, los pagos a realizar a cada uno.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success text-right" onClick={() => processPagos()}>Si, procesar pagos</button>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-8">
                            <h3>Pagos a distribuidores</h3>
                            <p className="text-muted">{pagos.distribuidores?.length} pago{pagos.distribuidores?.length == 1 ? '' : 's'} registrado{pagos.distribuidores?.length == 1 ? '' : 's'}</p>
                        </div>
                        <div className="col-md-4 text-right">
                                {
                                    permisos_pagos_distribuidores?.procesar ?
                                        <button className="btn btn-success text-right" onClick={() => handleOrden()}>Procesar pagos</button>
                                    :
                                        null
                                }
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Distribuidor</th>
                                <th scope="col">Total</th>
                                <th scope="col">Total factura</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Factura</th>
                                <th scope="col">Pago</th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            tableRow(pagos.distribuidores != undefined ? pagos.distribuidores : [])
                        }
                    </table>
                </div>
            </div>
        </Fragment>
    )
}


export default PagosDistribuidores;