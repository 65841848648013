import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { crearTarjeta } from '../../actions/promociones';
import { buscarFarmacias } from '../../actions/farmacias';

const PromocionTarjetaNueva = ({ history }) =>{

    const dispatch = useDispatch();

    const { farmacias } = useSelector(state => state.farmacias);
    
    const [ tarjetaData, setTarjetaData ] = useState({
        numero: '',
        farmacia: null
    });
    
    const onChange = (e) => {
        setTarjetaData({
            ...tarjetaData, [e.target.name]: e.target.value
        })
    }

    const formatCard = e => {
        const inputVal = e.target.value.replace(/ /g, "");
        let inputNumbersOnly = inputVal.replace(/\D/g, "");
    
        if (inputNumbersOnly.length > 16) {
            inputNumbersOnly = inputNumbersOnly.substr(0, 16);
        }
    
        const splits = inputNumbersOnly.match(/.{1,4}/g);
    
        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" ");
        }
    
        setTarjetaData({
            ...tarjetaData, 
            numero: spacedNumber
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(crearTarjeta(tarjetaData));
            
            await setTarjetaData({
                ...tarjetaData, 
                numero: ''
            });
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        dispatch(buscarFarmacias());
    }, []);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nueva tarjeta de cliente</h3>
                <div className="row mt-5">
                    <div className="col-md-5 form-group">
                        <label className="font-weight-bold">Sucursal</label> <br></br>
                        <select className="form-control" name="farmacia" value={tarjetaData.farmacia} onChange={e => onChange(e)}>
                            <option value="null">Selecciona la sucursal</option>
                            {farmacias ? farmacias.map(farmacia => (
                                <option key={farmacia._id} value={farmacia._id}>{farmacia.nombre}</option>
                            )) : null}
                        </select>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Número</label> <br></br>
                        <input type="text" className="form-control" placeholder="Número de tarjeta" name="numero" onChange={formatCard} value={tarjetaData.numero}></input>
                    </div>

                    <div className="col-md-3 form-group">
                        <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear tarjeta</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PromocionTarjetaNueva;