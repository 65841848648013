import { LOAD_NOTICIAS, ADD_NOTICIA, DETAIL_NOTICIA, EDIT_NOTICIA, DELETE_NOTICIA } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_NOTICIAS:
        	return {
                ...state,
                noticias: payload
            }
        case ADD_NOTICIA:
            return {
                ...state,
                noticias: [
                    ...state.noticias, payload
                ]
            }
        case EDIT_NOTICIA:
            return {
                ...state,
                noticias: state.noticias.map( noticia => noticia._id == payload._id ? payload : noticia)
            }
        case DETAIL_NOTICIA:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_NOTICIA:
            return {
                ...state,
                noticias: state.noticias.filter( noticia => noticia._id != payload.id )
            }
        default:
            return state;
    }
}