import { LOAD_ORDENES, LOAD_FINALIZADAS, DETAIL_ORDEN, LOAD_ACCION } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_ORDENES:
        	return {
                ...state,
                ordenes: payload
            }
        case LOAD_FINALIZADAS:
            return {
                ...state,
                finalizadas: payload
            }
        case DETAIL_ORDEN:
        	return {
                ...state,
                detalles: payload
            }
        case LOAD_ACCION:
            return {
                ...state,
                detalles: payload
            }
        default:
            return state;
    }
}