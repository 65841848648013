import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { crearProveedor, buscarSucursales } from '../../actions/proveedores';

const styles = {
    check_zone: {
        display: 'flex',
        alignItems: 'center'
    },
    sin_filas: {
        textAlign: 'center'
    }
}

const ProveedorNuevo = ({ history }) =>{

    const dispatch = useDispatch();

    const proveedores = useSelector(state => state.proveedores);

    const tableRow = (items) => {

        if(items.length == 0){
            return (
                <tbody>
                    <tr>
                        <td colspan={3} style={styles.sin_filas}>No hay sucursales disponibles</td>
                    </tr>
                </tbody>
            );
        }else{
            return (
                <tbody>
                    {items.map(item => (
                        <Fragment key={item._id}>
                            <tr>
                                <td style={styles.check_zone}>
                                    <div className="form-check">
                                        <input id={item._id} type="checkbox" onChange={e => handleCheckbox(e)} value={item.cantidad}></input>
                                    </div>
                                </td>
                                <td>{item.nombre}</td>
                                <td>{item.direccion}</td>
                            </tr>
                        </Fragment>
                    ))}
                </tbody>
            );
        }
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearProveedor(proveedorData));
            history.push('/proveedores');
        } catch (error) {
            return false;
        }
    }

    const handleCheckbox = ({ target }) => {
        if(target.checked){
            setProveedorData({
                ...proveedorData,
                sucursales_id: [...proveedorData.sucursales_id, target.id]
            });
        }else{
            setProveedorData({
                ...proveedorData,
                sucursales_id: proveedorData.sucursales_id.filter( sucursal_id => sucursal_id != target.id )
            });
        }
    }
    
    const [proveedorData, setProveedorData ] = useState({
        nombre: '',
        contacto: '',
        telefono: '',
        email: '',
        password: '',
        titular: '',
        cuenta: '',
        clabe: '',
        banco: '',
        sucursales_id: []
    });
    
    const { nombre, contacto, telefono, email, password, titular, cuenta, clabe, banco } = proveedorData;
    
    const onChange = (e) => {
        setProveedorData({
            ...proveedorData, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        dispatch(buscarSucursales());
    }, []);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nuevo proveedor</h3>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre identificador" name="nombre" onChange={ e => onChange(e) } value={nombre}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Contacto principal</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contacto" name="contacto" onChange={ e => onChange(e) } value={contacto}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono</label> <br></br>
                        <input type="text" className="form-control" placeholder="Teléfono" name="telefono" onChange={ e => onChange(e) } value={telefono}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email" name="email" onChange={ e => onChange(e) } value={email}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña" name="password" onChange={ e => onChange(e) } value={password}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Titular de cuenta</label> <br></br>
                        <input type="text" className="form-control" placeholder="Titular" name="titular" onChange={ e => onChange(e) } value={titular}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">No. de cuenta</label> <br></br>
                        <input type="text" className="form-control" placeholder="Cuenta bancaria" name="cuenta" onChange={ e => onChange(e) } value={cuenta}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">CLABE interbancaria</label> <br></br>
                        <input type="text" className="form-control" placeholder="CLABE" name="clabe" onChange={ e => onChange(e) } value={clabe}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Banco</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre de banco" name="banco" onChange={ e => onChange(e) } value={banco}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Seleccionar</th>
                                    <th scope="col">Nombre de sucursal</th>
                                    <th scope="col">Dirección</th>
                                </tr>
                            </thead>
                            {
                                tableRow(proveedores.sucursales != undefined ? proveedores.sucursales : [])
                            }
                        </table>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear proveedor</button>
            </form>
        </div>
    )
}

export default ProveedorNuevo;