import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { editarTarjeta, detallesTarjeta } from '../../actions/promociones';
import { buscarProductos } from '../../actions/productos';

const PromocionTarjetaEditar = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const tarjeta = useSelector(state => state.promociones);
    const { farmacias } = useSelector(state => state.farmacias);
    
    const [tarjetaData, setTarjetaData ] = useState({
        id: '',
        tipo: 2,
        producto: null,
        concepto: '',
        porcentaje: 0,
        cantidad_comprar: 0,
        cantidad_cobrar: 0
    });
    
    const onChange = (e) => {
        setTarjetaData({
            ...tarjetaData, [e.target.name]: e.target.value
        })
    }

    const formatCard = e => {
        const inputVal = e.target.value.replace(/ /g, "");
        let inputNumbersOnly = inputVal.replace(/\D/g, "");
    
        if (inputNumbersOnly.length > 16) {
            inputNumbersOnly = inputNumbersOnly.substr(0, 16);
        }
    
        const splits = inputNumbersOnly.match(/.{1,4}/g);
    
        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" ");
        }
    
        setTarjetaData({
            ...tarjetaData, 
            numero: spacedNumber
        });
    }

    const formatCardPrev = (numero) => {
        let spacedNumber = "";

        if(numero){
            let inputNumbersOnly = numero.replace(/\D/g, "");
        
            if (inputNumbersOnly.length > 16) {
                inputNumbersOnly = inputNumbersOnly.substr(0, 16);
            }
        
            const splits = inputNumbersOnly.match(/.{1,4}/g);
        
            if (splits) {
                spacedNumber = splits.join(" ");
            }
        }
    
        return spacedNumber;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(editarTarjeta(tarjetaData));
            history.push('/promociones/tarjetas');
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        dispatch(buscarProductos());
        dispatch(detallesTarjeta(id));
    }, []);

    useEffect(() => {
        setTarjetaData({
            ...tarjetaData,
            id: tarjeta.detalles?._id,
            numero: formatCardPrev(tarjeta.detalles?.numero),
            farmacia: tarjeta.detalles?.farmacia
        });
    }, [tarjeta]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar promoción o descuento</h3>
                <div className="row mt-5">
                    <div className="col-md-5 form-group">
                        <label className="font-weight-bold">Sucursal</label> <br></br>
                        <select className="form-control" name="farmacia" value={tarjetaData.farmacia} onChange={e => onChange(e)}>
                            <option value="null">Selecciona la sucursal</option>
                            {farmacias ? farmacias.map(farmacia => (
                                <option key={farmacia._id} value={farmacia._id}>{farmacia.nombre}</option>
                            )) : null}
                        </select>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Número</label> <br></br>
                        <input type="text" className="form-control" placeholder="Número de tarjeta" name="numero" onChange={formatCard} value={tarjetaData.numero}></input>
                    </div>

                    <div className="col-md-3 form-group">
                        <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Editar tarjeta</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PromocionTarjetaEditar;