import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarPacientes, eliminarPaciente } from '../../actions/pacientes';

const Pacientes = ({ history }) => {

    const dispatch = useDispatch();

    const pacientes = useSelector(state => state.pacientes);
    const { permisos_pacientes } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.apellido}</td>
                            <td>{item.telefono}</td>
                            <td>{new Date(item.nacimiento).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>
                                {
                                    permisos_pacientes?.perfil ?
                                        <Link className="btn btn-link text-success" to={`/pacientes/detalles/${item._id}`}>Perfil</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_pacientes?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarPaciente(id));
    }

    useEffect(() => {
        dispatch(buscarPacientes());
    }, [])

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Pacientes</h3>
                        <p className="text-muted">{pacientes.pacientes?.length} paciente{pacientes.pacientes?.length == 1 ? '' : 's'} registrado{pacientes.pacientes?.length == 1 ? '' : 's'}</p>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Fecha de nacimiento</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(pacientes.pacientes != undefined ? pacientes.pacientes : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Pacientes;