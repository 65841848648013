import React, { useEffect, useState, createRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { buscarCategorias, buscarProductos, editarProducto, detallesProducto } from '../../actions/productos';

const ProductoEditar = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const producto = useSelector(state => state.productos);
    const categorias = useSelector(state => state.productos.categorias);
    
    const [productoData, setProductoData ] = useState({
        id: '',
        categoria: '',
        nombre: '',
        descripcion: '',
        precio_compra: '',
        reorden: '',
        controlado: false,
        precio_venta: '',
        iva_venta: null,
        precio_oferta: '',
        iva_oferta: null,
        aparece_app: null,
        oferta_activa: null,
        precio_venta_medico: '',
        iva_venta_medico: null,
        precio_oferta_medico: '',
        iva_oferta_medico: null,
        aparece_app_medico: null,
        oferta_activa_medico: null,
        codigo_barras: '',
        clave_alternativa: '',
        imagenes: [],
    });

    const [productoImagen, setProductoImagen] = useState({
        img: undefined
    })
    
    let img = createRef();

    const onChange = (e) => {
        setProductoData({
            ...productoData, [e.target.name]: e.target.value
        })
    }

    const onChangeControlado = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
        }

        if(e.target.value == 'true'){
            valor = true;
        }

        setProductoData({
            ...productoData, [e.target.name]: valor
        })
    }

    const onChangeAparicion = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
        }

        if(e.target.value == 'true'){
            valor = true;
        }

        setProductoData({
            ...productoData, [e.target.name]: valor
        })
    }

    const onChangeImpuestos = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
        }

        if(e.target.value == 'true'){
            valor = true;
        }

        setProductoData({
            ...productoData, [e.target.name]: valor
        })
    }

    const onChangeOferta = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
            setProductoData({
                ...productoData, precio_oferta: '', oferta_activa: valor
            });
        }

        if(e.target.value == 'null'){
            valor = null;
            setProductoData({
                ...productoData, precio_oferta: '', oferta_activa: valor
            });
        }

        if(e.target.value == 'true'){
            valor = true;
            setProductoData({
                ...productoData, oferta_activa: valor
            });
        }
    }

    const onChangeOfertaMedico = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
            setProductoData({
                ...productoData, precio_oferta_medico: '', oferta_activa_medico: valor
            });
        }

        if(e.target.value == 'null'){
            valor = null;
            setProductoData({
                ...productoData, precio_oferta_medico: '', oferta_activa_medico: valor
            });
        }

        if(e.target.value == 'true'){
            valor = true;
            setProductoData({
                ...productoData, oferta_activa_medico: valor
            });
        }
    }

    const onChangeImg = () => {
        setProductoImagen({
            ...productoImagen, img: img.current.files
        });
    }

    useEffect(() => {
        dispatch(buscarProductos());
        dispatch(buscarCategorias());
        dispatch(detallesProducto(id));
    }, []);

    useEffect(() => {
        setProductoData({
            ...productoData,
            id: producto.detalles?._id,
            categoria: producto.detalles?.id_categoria,
            nombre: producto.detalles?.nombre,
            descripcion: producto.detalles?.descripcion,
            precio_compra: producto.detalles?.precio_compra,
            reorden: producto.detalles?.reorden,
            controlado: producto.detalles?.controlado,
            precio_venta: producto.detalles?.precio_venta,
            iva_venta: producto.detalles?.iva_venta,
            precio_oferta: producto.detalles?.precio_oferta,
            iva_oferta: producto.detalles?.iva_oferta,
            aparece_app: producto.detalles?.aparece_app,
            oferta_activa: producto.detalles?.oferta_activa,
            precio_venta_medico: producto.detalles?.precio_venta_medico,
            iva_venta_medico: producto.detalles?.iva_venta_medico,
            precio_oferta_medico: producto.detalles?.precio_oferta_medico,
            iva_oferta_medico: producto.detalles?.iva_oferta_medico,
            aparece_app_medico: producto.detalles?.aparece_app_medico,
            oferta_activa_medico: producto.detalles?.oferta_activa_medico,
            codigo_barras: producto.detalles?.codigo_barras,
            clave_alternativa: producto.detalles?.clave_alternativa,
            imagenes: producto.detalles?.imagenes,
        })
    }, [producto]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let formData = new FormData();
            
            formData.append('id', id);
            formData.append('categoria', productoData.categoria);
            formData.append('nombre', productoData.nombre);
            formData.append('descripcion', productoData.descripcion);
            formData.append('precio_compra', productoData.precio_compra);
            formData.append('reorden', productoData.reorden);
            formData.append('controlado', productoData.controlado);
            formData.append('precio_venta', productoData.precio_venta);
            formData.append('iva_venta', productoData.iva_venta);
            formData.append('precio_oferta', productoData.precio_oferta);
            formData.append('iva_oferta', productoData.iva_oferta);
            formData.append('aparece_app', productoData.aparece_app);
            formData.append('oferta_activa', productoData.oferta_activa);
            formData.append('precio_venta_medico', productoData.precio_venta_medico);
            formData.append('iva_venta_medico', productoData.iva_venta_medico);
            formData.append('precio_oferta_medico', productoData.precio_oferta_medico);
            formData.append('iva_oferta_medico', productoData.iva_oferta_medico);
            formData.append('aparece_app_medico', productoData.aparece_app_medico);
            formData.append('oferta_activa_medico', productoData.oferta_activa_medico);
            formData.append('codigo_barras', productoData.codigo_barras);
            formData.append('clave_alternativa', productoData.clave_alternativa);

            if(productoImagen.img != undefined){
                for (var i = 0; i < productoImagen.img.length; i++) {
                    formData.append('imagenes', productoImagen.img[i]);
                }
            }
            
            await dispatch(editarProducto(formData));

            await history.push('/productos');
        } catch (error) {
            return false;
        }
    }


    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar producto</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre del producto" name="nombre" onChange={e => onChange(e) } value={productoData.nombre || ''}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Categoria</label> <br></br>
                        <select className="form-control" name="categoria" value={productoData.categoria} onChange={e => onChange(e)}>
                            <option value="">Selecciona Categoría</option>
                            {categorias ? categorias.map(categoria => (
                                <option key={categoria._id} value={categoria._id}>{categoria.nombre}</option>
                            )) : null}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Descripción</label> <br></br>
                        <input type="text" className="form-control" placeholder="Descripción" name="descripcion" onChange={e => onChange(e) } value={productoData.descripcion || ''}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de Compra</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de Compra" name="precio_compra" onChange={e => onChange(e) } value={productoData.precio_compra || ''}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Punto de reorden</label> <br></br>
                        <input type="text" className="form-control" placeholder="Punto de reorden" name="reorden" onChange={e => onChange(e) } value={productoData.reorden}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Requiere de receta?</label> <br></br>
                        <select className="form-control" name="controlado" value={productoData.controlado} onChange={e => onChangeControlado(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de venta a paciente</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de venta a paciente" name="precio_venta" onChange={e => onChange(e) } value={productoData.precio_venta || ''}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (venta a paciente)</label> <br></br>
                        <select className="form-control" name="iva_venta" value={productoData.iva_venta} onChange={e => onChangeImpuestos(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Aparece en la app de paciente?</label> <br></br>
                        <select className="form-control" name="aparece_app" value={productoData.aparece_app} onChange={e => onChangeAparicion(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Esta en oferta para paciente?</label> <br></br>
                        <select className="form-control" name="oferta_activa" value={productoData.oferta_activa} onChange={e => onChangeOferta(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de oferta a paciente</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de oferta a paciente" name="precio_oferta" onChange={e => onChange(e) } value={productoData.precio_oferta || ''} disabled={productoData.oferta_activa == true ? false : true}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (oferta a paciente)</label> <br></br>
                        <select className="form-control" name="iva_oferta" value={productoData.iva_oferta} onChange={e => onChangeImpuestos(e)} disabled={productoData.oferta_activa == true ? false : true}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de venta a médico</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de venta a médico" name="precio_venta_medico" onChange={e => onChange(e) } value={productoData.precio_venta_medico || ''}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (venta a médico)</label> <br></br>
                        <select className="form-control" name="iva_venta_medico" value={productoData.iva_venta_medico} onChange={e => onChangeImpuestos(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Aparece en la app de médico?</label> <br></br>
                        <select className="form-control" name="aparece_app_medico" value={productoData.aparece_app_medico} onChange={e => onChangeAparicion(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Esta en oferta para médico?</label> <br></br>
                        <select className="form-control" name="oferta_activa_medico" value={productoData.oferta_activa_medico} onChange={e => onChangeOfertaMedico(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de oferta a médico</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de oferta a médico" name="precio_oferta_medico" onChange={e => onChange(e) } value={productoData.precio_oferta_medico || ''} disabled={productoData.oferta_activa_medico == true ? false : true}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (oferta a médico)</label> <br></br>
                        <select className="form-control" name="iva_oferta_medico" value={productoData.iva_oferta_medico} onChange={e => onChangeImpuestos(e)} disabled={productoData.oferta_activa_medico == true ? false : true}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Clave Alternativa</label> <br></br>
                        <input type="text" className="form-control" placeholder="Clave Alternativa" name="clave_alternativa" onChange={e => onChange(e) } value={productoData.clave_alternativa || ''}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Código de Barras</label> <br></br>
                        <input type="text" className="form-control" placeholder="Código de Barras" name="codigo_barras" onChange={e => onChange(e) } value={productoData.codigo_barras || ''}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Imagenes</label> <br></br>
                        <div className="d-flex">
                            {productoData.imagenes ? productoData.imagenes.map(imagen => (
                                    <img key={imagen} src={imagen} className="img-fluid rounded mb-5 mt-3 mr-3 picture-med"/>
                            )) : null}
                        </div>
                        <input type="file" className="form-control" name="image" ref={img} id="image" onChange={onChangeImg} accept="image/*" multiple/>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right mb-5" onClick={handleSubmit}>Editar producto</button>
            </form>
        </div>
    )
}

export default ProductoEditar;