import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { buscarUbicaciones, eliminarUbicacion } from '../../actions/ubicaciones';
import { detallesFarmacia } from '../../actions/farmacias';

const Ubicaciones = ({ history }) => {

    const { id_farmacia } = useParams();

    const dispatch = useDispatch();

    const ubicaciones = useSelector(state => state.ubicaciones);
    const { detalles } = useSelector(state => state.farmacias);

    const { permisos_noticias } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.nivel}</td>
                            <td>
                                {
                                    permisos_noticias?.editar ?
                                        <Link className="btn btn-link text-link" to={`/farmacias/ubicaciones_editar/${item._id}/${item.sucursal}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_noticias?.editar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id_eliminar) => {
        dispatch(eliminarUbicacion(id_eliminar));
    }
    
    useEffect(async () => {
        if(id_farmacia){
            await dispatch(buscarUbicaciones(id_farmacia));
            await dispatch(detallesFarmacia(id_farmacia));
        }
    }, [id_farmacia]);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Ubicaciones de {detalles?.nombre}</h3>
                        <p className="text-muted">{ubicaciones.ubicaciones?.length} ubicacion{ubicaciones.ubicaciones?.length == 1 ? '' : 'es'} registrada{ubicaciones.ubicaciones?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_noticias?.agregar ?
                                <Link to={'/farmacias/ubicaciones_nueva/' + id_farmacia} className="btn btn-success text-right">Nueva ubicacion</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Nivel</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        
                        tableRow(ubicaciones.ubicaciones != undefined ? ubicaciones.ubicaciones : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Ubicaciones;