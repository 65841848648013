import { LOAD_FARMACIAS, ADD_FARMACIA, DETAIL_FARMACIA, LOAD_ESTADISTICA, INVENTORY_FARMACIA, EDIT_FARMACIA, DELETE_FARMACIA, ADD_CAJERO, EDIT_CAJERO, DETAIL_CAJERO, DELETE_CAJERO } from '../actions/types'

const initialState = {
    farmacias: [],
    detalles: null,
    detalles_cajero: null,
    inventario: null,
    datos_estadistica: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_FARMACIAS:
        	return {
                ...state,
                farmacias: payload
            }
        case ADD_FARMACIA:
            return {
                ...state,
                farmacias: [
                    ...state.farmacias, payload
                ]
            }
        case EDIT_FARMACIA:
            return {
                ...state,
                farmacias: state.farmacias.map( farmacia => farmacia._id == payload._id ? payload : farmacia)
            }
        case DETAIL_FARMACIA:
        	return {
                ...state,
                detalles: payload
            }
        case LOAD_ESTADISTICA:
            return {
                ...state,
                datos_estadistica: payload
            }
        case INVENTORY_FARMACIA:
            return {
                ...state,
                inventario: payload
            }
        case DELETE_FARMACIA:
            return {
                ...state,
                farmacias: state.farmacias.filter( farmacia => farmacia._id != payload.id )
            }
        case ADD_CAJERO:
            return {
                ...state,
                detalles: {
                    ...state.detalles,
                    cajeros: [
                        ...state.detalles.cajeros, payload
                    ]
                }
            }
        case EDIT_CAJERO:
            return {
                ...state,
                detalles: {
                    ...state.detalles,
                    cajeros: state.detalles.cajeros.map( cajero => cajero._id == payload._id ? payload : cajero)
                }
            }
        case DELETE_CAJERO:
            return {
                ...state,
                detalles: {
                    ...state.detalles,
                    cajeros: state.detalles.cajeros.filter( cajero => cajero._id != payload.id )
                }
            }
        case DETAIL_CAJERO:
            return {
                ...state,
                detalles_cajero: payload
            }
        default:
            return state;
    }
}