import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { crearUsuario } from '../../actions/usuarios';

const UsuarioNuevo = ({ history }) =>{

    const dispatch = useDispatch();
    
    const [usuarioData, setUsuarioData ] = useState({
        nombre: '',
        email: '',
        password: ''
    });
    
    const { nombre, email, password } = usuarioData;

    /* Permisos */
    const [superadmin, setSuperadmin] = useState(false);

    const [medicos, setMedicos] = useState({
        all: false,
        ver: false,
        perfil: false,
        eliminar: false
    });
    
    const [pacientes, setPacientes] = useState({
        all: false,
        ver: false,
        perfil: false,
        eliminar: false
    });
    
    const [distribuidores, setDistribuidores] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        perfil: false,
        eliminar: false
    });
    
    const [gerentes, setGerentes] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [proveedores, setProveedores] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [administradores, setAdministradores] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [ordenesProceso, setOrdenesProceso] = useState({
        all: false,
        ver: false,
        detalles: false,
        status: false
    });
    
    const [ordenesFinalizadas, setOrdenesFinalizadas] = useState({
        all: false,
        ver: false,
        detalles: false
    });
    
    const [pagosDistribuidores, setPagosDistribuidores] = useState({
        all: false,
        ver: false,
        procesar: false,
        detalles: false,
        facturas: false,
        pagar: false
    });
    
    const [pagosMedicos, setPagosMedicos] = useState({
        all: false,
        ver: false,
        procesar: false,
        detalles: false,
        facturas: false,
        pagar: false
    });
    
    const [farmacias, setFarmacias] = useState({
        all: false,
        ver: false,
        agregar: false,
        inventario: false,
        detalles: false,
        editar: false,
        eliminar: false,
        estadisticas: false,
        agregar_empleado: false,
        editar_empleado: false,
        eliminar_empleado: false
    });
    
    const [categorias, setCategorias] = useState({
        all: false,
        ver: false,
        agregar: false,
        productos: false,
        editar: false,
        eliminar: false
    });
    
    const [productos, setProductos] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [reportes, setReportes] = useState({
        all: false,
        ver: false,
        procesar: false
    });
    
    const [promocionesGenerales, setPromocionesGenerales] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [promocionesProductos, setPromocionesProductos] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });

    const [promocionesTarjetas, setPromocionesTarjetas] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [avisos, setAvisos] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [noticias, setNoticias] = useState({
        all: false,
        ver: false,
        agregar: false,
        editar: false,
        eliminar: false
    });
    
    const [configuracion, setConfiguracion] = useState({
        all: false,
        ver: false,
        guardar: false
    });
    
    /* Datos */
    const onChange = (e) => {
        setUsuarioData({
            ...usuarioData, [e.target.name]: e.target.value
        })
    }

    /* Permisos */
    const changeCategory = (e) => {

        if(e.target.dataset.type == 'superadmin'){

            setSuperadmin(e.target.checked);

            let temp_medicos = Object.fromEntries(Array.from(document.getElementsByClassName('medicos')).map(element => [element.dataset.field, e.target.checked]));
            let temp_pacientes = Object.fromEntries(Array.from(document.getElementsByClassName('pacientes')).map(element => [element.dataset.field, e.target.checked]));
            let temp_distribuidores = Object.fromEntries(Array.from(document.getElementsByClassName('distribuidores')).map(element => [element.dataset.field, e.target.checked]));
            let temp_gerentes = Object.fromEntries(Array.from(document.getElementsByClassName('gerentes')).map(element => [element.dataset.field, e.target.checked]));
            let temp_proveedores = Object.fromEntries(Array.from(document.getElementsByClassName('proveedores')).map(element => [element.dataset.field, e.target.checked]));
            let temp_administradores = Object.fromEntries(Array.from(document.getElementsByClassName('administradores')).map(element => [element.dataset.field, e.target.checked]));
            let temp_ordenesProceso = Object.fromEntries(Array.from(document.getElementsByClassName('ordenesProceso')).map(element => [element.dataset.field, e.target.checked]));
            let temp_ordenesFinalizadas = Object.fromEntries(Array.from(document.getElementsByClassName('ordenesFinalizadas')).map(element => [element.dataset.field, e.target.checked]));
            let temp_pagosDistribuidores = Object.fromEntries(Array.from(document.getElementsByClassName('pagosDistribuidores')).map(element => [element.dataset.field, e.target.checked]));
            let temp_pagosMedicos = Object.fromEntries(Array.from(document.getElementsByClassName('pagosMedicos')).map(element => [element.dataset.field, e.target.checked]));
            let temp_farmacias = Object.fromEntries(Array.from(document.getElementsByClassName('farmacias')).map(element => [element.dataset.field, e.target.checked]));
            let temp_categorias = Object.fromEntries(Array.from(document.getElementsByClassName('categorias')).map(element => [element.dataset.field, e.target.checked]));
            let temp_productos = Object.fromEntries(Array.from(document.getElementsByClassName('productos')).map(element => [element.dataset.field, e.target.checked]));
            let temp_reportes = Object.fromEntries(Array.from(document.getElementsByClassName('reportes')).map(element => [element.dataset.field, e.target.checked]));
            let temp_promocionesGenerales = Object.fromEntries(Array.from(document.getElementsByClassName('promocionesGenerales')).map(element => [element.dataset.field, e.target.checked]));
            let temp_promocionesProductos = Object.fromEntries(Array.from(document.getElementsByClassName('promocionesProductos')).map(element => [element.dataset.field, e.target.checked]));
            let temp_promocionesTarjetas = Object.fromEntries(Array.from(document.getElementsByClassName('promocionesTarjetas')).map(element => [element.dataset.field, e.target.checked]));
            let temp_avisos = Object.fromEntries(Array.from(document.getElementsByClassName('avisos')).map(element => [element.dataset.field, e.target.checked]));
            let temp_noticias = Object.fromEntries(Array.from(document.getElementsByClassName('noticias')).map(element => [element.dataset.field, e.target.checked]));
            let temp_configuracion = Object.fromEntries(Array.from(document.getElementsByClassName('configuracion')).map(element => [element.dataset.field, e.target.checked]));

            setMedicos({
                ...medicos,
                all: e.target.checked,
                ...temp_medicos
            });

            setPacientes({
                ...pacientes,
                all: e.target.checked,
                ...temp_pacientes
            });

            setDistribuidores({
                ...distribuidores,
                all: e.target.checked,
                ...temp_distribuidores
            });

            setGerentes({
                ...gerentes,
                all: e.target.checked,
                ...temp_gerentes
            });

            setProveedores({
                ...proveedores,
                all: e.target.checked,
                ...temp_proveedores
            });

            setAdministradores({
                ...administradores,
                all: e.target.checked,
                ...temp_administradores
            });

            setOrdenesProceso({
                ...ordenesProceso,
                all: e.target.checked,
                ...temp_ordenesProceso
            });

            setOrdenesFinalizadas({
                ...ordenesFinalizadas,
                all: e.target.checked,
                ...temp_ordenesFinalizadas
            });

            setPagosDistribuidores({
                ...pagosDistribuidores,
                all: e.target.checked,
                ...temp_pagosDistribuidores
            });

            setPagosMedicos({
                ...pagosMedicos,
                all: e.target.checked,
                ...temp_pagosMedicos
            });

            setFarmacias({
                ...farmacias,
                all: e.target.checked,
                ...temp_farmacias
            });

            setCategorias({
                ...categorias,
                all: e.target.checked,
                ...temp_categorias
            });

            setProductos({
                ...productos,
                all: e.target.checked,
                ...temp_productos
            });

            setReportes({
                ...reportes,
                all: e.target.checked,
                ...temp_reportes
            });

            setPromocionesGenerales({
                ...promocionesGenerales,
                all: e.target.checked,
                ...temp_promocionesGenerales
            });

            setPromocionesProductos({
                ...promocionesProductos,
                all: e.target.checked,
                ...temp_promocionesProductos
            });

            setPromocionesTarjetas({
                ...promocionesTarjetas,
                all: e.target.checked,
                ...temp_promocionesTarjetas
            });

            setAvisos({
                ...avisos,
                all: e.target.checked,
                ...temp_avisos
            });

            setNoticias({
                ...noticias,
                all: e.target.checked,
                ...temp_noticias
            });

            setConfiguracion({
                ...configuracion,
                all: e.target.checked,
                ...temp_configuracion
            });
        }else{
            setSuperadmin(e.target.checked === false ? false : superadmin);

            switch(e.target.id){
                case 'medicos':
                    let temp_medicos = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));
                    
                    setMedicos({
                        ...medicos,
                        all: e.target.checked,
                        ...temp_medicos
                    });
                    break;
                    
                case 'pacientes':
                    let temp_pacientes = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));
                    
                    setPacientes({
                        ...pacientes,
                        all: e.target.checked,
                        ...temp_pacientes
                    });
                    break;
                
                case 'distribuidores':
                    let temp_distribuidores = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setDistribuidores({
                        ...distribuidores,
                        all: e.target.checked,
                        ...temp_distribuidores
                    });
                    break;

                case 'gerentes':
                    let temp_gerentes = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setGerentes({
                        ...gerentes,
                        all: e.target.checked,
                        ...temp_gerentes
                    });
                    break;

                case 'proveedores':
                    let temp_proveedores = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setProveedores({
                        ...proveedores,
                        all: e.target.checked,
                        ...temp_proveedores
                    });
                    break;

                case 'administradores':
                    let temp_administradores = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setAdministradores({
                        ...administradores,
                        all: e.target.checked,
                        ...temp_administradores
                    });
                    break;

                case 'ordenesProceso':
                    let temp_ordenesProceso = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setOrdenesProceso({
                        ...ordenesProceso,
                        all: e.target.checked,
                        ...temp_ordenesProceso
                    });
                    break;

                case 'ordenesFinalizadas':
                    let temp_ordenesFinalizadas = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setOrdenesFinalizadas({
                        ...ordenesFinalizadas,
                        all: e.target.checked,
                        ...temp_ordenesFinalizadas
                    });
                    break;

                case 'pagosDistribuidores':
                    let temp_pagosDistribuidores = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setPagosDistribuidores({
                        ...pagosDistribuidores,
                        all: e.target.checked,
                        ...temp_pagosDistribuidores
                    });
                    break;

                case 'pagosMedicos':
                    let temp_pagosMedicos = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setPagosMedicos({
                        ...pagosMedicos,
                        all: e.target.checked,
                        ...temp_pagosMedicos
                    });
                    break;

                case 'farmacias':
                    let temp_farmacias = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setFarmacias({
                        ...farmacias,
                        all: e.target.checked,
                        ...temp_farmacias
                    });
                    break;

                case 'categorias':
                    let temp_categorias = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setCategorias({
                        ...categorias,
                        all: e.target.checked,
                        ...temp_categorias
                    });
                    break;

                case 'productos':
                    let temp_productos = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setProductos({
                        ...productos,
                        all: e.target.checked,
                        ...temp_productos
                    });
                    break;

                case 'reportes':
                    let temp_reportes = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setReportes({
                        ...reportes,
                        all: e.target.checked,
                        ...temp_reportes
                    });
                    break;

                case 'promocionesGenerales':
                    let temp_promocionesGenerales = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setPromocionesGenerales({
                        ...promocionesGenerales,
                        all: e.target.checked,
                        ...temp_promocionesGenerales
                    });
                    break;

                case 'promocionesProductos':
                    let temp_promocionesProductos = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setPromocionesProductos({
                        ...promocionesProductos,
                        all: e.target.checked,
                        ...temp_promocionesProductos
                    });
                    break;

                case 'promocionesTarjetas':
                    let temp_promocionesTarjetas = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setPromocionesTarjetas({
                        ...promocionesTarjetas,
                        all: e.target.checked,
                        ...temp_promocionesTarjetas
                    });
                    break;

                case 'avisos':
                    let temp_avisos = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setAvisos({
                        ...avisos,
                        all: e.target.checked,
                        ...temp_avisos
                    });
                    break;

                case 'noticias':
                    let temp_noticias = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setNoticias({
                        ...noticias,
                        all: e.target.checked,
                        ...temp_noticias
                    });
                    break;

                case 'configuracion':
                    let temp_configuracion = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setConfiguracion({
                        ...configuracion,
                        all: e.target.checked,
                        ...temp_configuracion
                    });
                    break;
            }
        }
    }

    const onChangeCheckbox = (e) => {
        if(!e.target.checked){
            setSuperadmin(false);
        }

        switch(e.target.dataset.type){
            case 'medicos':
                setMedicos({
                    ...medicos,
                    all: !e.target.checked ? false : medicos.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'pacientes':
                setPacientes({
                    ...pacientes,
                    all: !e.target.checked ? false : pacientes.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'distribuidores':
                setDistribuidores({
                    ...distribuidores,
                    all: !e.target.checked ? false : distribuidores.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'gerentes':
                setGerentes({
                    ...gerentes,
                    all: !e.target.checked ? false : gerentes.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'proveedores':
                setProveedores({
                    ...proveedores,
                    all: !e.target.checked ? false : proveedores.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'administradores':
                setAdministradores({
                    ...administradores,
                    all: !e.target.checked ? false : administradores.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'ordenesProceso':
                setOrdenesProceso({
                    ...ordenesProceso,
                    all: !e.target.checked ? false : ordenesProceso.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;
            
            case 'ordenesFinalizadas':
                setOrdenesFinalizadas({
                    ...ordenesFinalizadas,
                    all: !e.target.checked ? false : ordenesFinalizadas.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'pagosDistribuidores':
                setPagosDistribuidores({
                    ...pagosDistribuidores,
                    all: !e.target.checked ? false : pagosDistribuidores.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'pagosMedicos':
                setPagosMedicos({
                    ...pagosMedicos,
                    all: !e.target.checked ? false : pagosMedicos.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'farmacias':
                setFarmacias({
                    ...farmacias,
                    all: !e.target.checked ? false : farmacias.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'categorias':
                setCategorias({
                    ...categorias,
                    all: !e.target.checked ? false : categorias.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'productos':
                setProductos({
                    ...productos,
                    all: !e.target.checked ? false : productos.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'reportes':
                setReportes({
                    ...reportes,
                    all: !e.target.checked ? false : reportes.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'promocionesGenerales':
                setPromocionesGenerales({
                    ...promocionesGenerales,
                    all: !e.target.checked ? false : promocionesGenerales.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'promocionesProductos':
                setPromocionesProductos({
                    ...promocionesProductos,
                    all: !e.target.checked ? false : promocionesProductos.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'promocionesTarjetas':
                setPromocionesTarjetas({
                    ...promocionesTarjetas,
                    all: !e.target.checked ? false : promocionesTarjetas.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'avisos':
                setAvisos({
                    ...avisos,
                    all: !e.target.checked ? false : avisos.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'noticias':
                setNoticias({
                    ...noticias,
                    all: !e.target.checked ? false : noticias.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'configuracion':
                setConfiguracion({
                    ...configuracion,
                    all: !e.target.checked ? false : configuracion.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;
        }
    }

    /* Guardar */
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearUsuario({
                ...usuarioData,
                medicos,
                pacientes,
                distribuidores,
                gerentes,
                proveedores,
                administradores,
                ordenesProceso,
                ordenesFinalizadas,
                pagosDistribuidores,
                pagosMedicos,
                farmacias,
                categorias,
                productos,
                reportes,
                promocionesGenerales,
                promocionesProductos,
                promocionesTarjetas,
                avisos,
                noticias,
                configuracion
            }));

            history.push('/usuarios');
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        if (!medicos.all) {
            let check = Object.values(medicos);
            check.shift();
            if (check.every(element => element)) {
                setMedicos({
                    ...medicos,
                    all: true
                });
            }
        }
    }, [medicos]);

    useEffect(() => {
        if (!pacientes.all) {
            let check = Object.values(pacientes);
            check.shift();
            if (check.every(element => element)) {
                setPacientes({
                    ...pacientes,
                    all: true,
                })
            }
        }
    }, [pacientes]);

    useEffect(() => {
        if (!distribuidores.all) {
            let check = Object.values(distribuidores);
            check.shift();
            if (check.every(element => element)) {
                setDistribuidores({
                    ...distribuidores,
                    all: true,
                })
            }
        }
    }, [distribuidores]);

    useEffect(() => {
        if (!gerentes.all) {
            let check = Object.values(gerentes);
            check.shift();
            if (check.every(element => element)) {
                setGerentes({
                    ...gerentes,
                    all: true,
                })
            }
        }
    }, [gerentes]);

    useEffect(() => {
        if (!proveedores.all) {
            let check = Object.values(proveedores);
            check.shift();
            if (check.every(element => element)) {
                setProveedores({
                    ...proveedores,
                    all: true,
                })
            }
        }
    }, [proveedores]);

    useEffect(() => {
        if (!administradores.all) {
            let check = Object.values(administradores);
            check.shift();
            if (check.every(element => element)) {
                setAdministradores({
                    ...administradores,
                    all: true,
                })
            }
        }
    }, [administradores]);

    useEffect(() => {
        if (!ordenesProceso.all) {
            let check = Object.values(ordenesProceso);
            check.shift();
            if (check.every(element => element)) {
                setOrdenesProceso({
                    ...ordenesProceso,
                    all: true,
                })
            }
        }
    }, [ordenesProceso]);

    useEffect(() => {
        if (!ordenesFinalizadas.all) {
            let check = Object.values(ordenesFinalizadas);
            check.shift();
            if (check.every(element => element)) {
                setOrdenesFinalizadas({
                    ...ordenesFinalizadas,
                    all: true,
                })
            }
        }
    }, [ordenesFinalizadas]);

    useEffect(() => {
        if (!pagosDistribuidores.all) {
            let check = Object.values(pagosDistribuidores);
            check.shift();
            if (check.every(element => element)) {
                setPagosDistribuidores({
                    ...pagosDistribuidores,
                    all: true,
                })
            }
        }
    }, [pagosDistribuidores]);

    useEffect(() => {
        if (!pagosMedicos.all) {
            let check = Object.values(pagosMedicos);
            check.shift();
            if (check.every(element => element)) {
                setPagosMedicos({
                    ...pagosMedicos,
                    all: true,
                })
            }
        }
    }, [pagosMedicos]);

    useEffect(() => {
        if (!farmacias.all) {
            let check = Object.values(farmacias);
            check.shift();
            if (check.every(element => element)) {
                setFarmacias({
                    ...farmacias,
                    all: true,
                })
            }
        }
    }, [farmacias]);

    useEffect(() => {
        if (!categorias.all) {
            let check = Object.values(categorias);
            check.shift();
            if (check.every(element => element)) {
                setCategorias({
                    ...categorias,
                    all: true,
                })
            }
        }
    }, [categorias]);

    useEffect(() => {
        if (!productos.all) {
            let check = Object.values(productos);
            check.shift();
            if (check.every(element => element)) {
                setProductos({
                    ...productos,
                    all: true,
                })
            }
        }
    }, [productos]);

    useEffect(() => {
        if (!reportes.all) {
            let check = Object.values(reportes);
            check.shift();
            if (check.every(element => element)) {
                setReportes({
                    ...reportes,
                    all: true,
                })
            }
        }
    }, [reportes]);

    useEffect(() => {
        if (!promocionesGenerales.all) {
            let check = Object.values(promocionesGenerales);
            check.shift();
            if (check.every(element => element)) {
                setPromocionesGenerales({
                    ...promocionesGenerales,
                    all: true,
                })
            }
        }
    }, [promocionesGenerales]);

    useEffect(() => {
        if (!promocionesProductos.all) {
            let check = Object.values(promocionesProductos);
            check.shift();
            if (check.every(element => element)) {
                setPromocionesProductos({
                    ...promocionesProductos,
                    all: true,
                })
            }
        }
    }, [promocionesProductos]);

    useEffect(() => {
        if (!promocionesTarjetas.all) {
            let check = Object.values(promocionesTarjetas);
            check.shift();
            if (check.every(element => element)) {
                setPromocionesTarjetas({
                    ...promocionesTarjetas,
                    all: true,
                })
            }
        }
    }, [promocionesTarjetas]);

    useEffect(() => {
        if (!avisos.all) {
            let check = Object.values(avisos);
            check.shift();
            if (check.every(element => element)) {
                setAvisos({
                    ...avisos,
                    all: true,
                })
            }
        }
    }, [avisos]);

    useEffect(() => {
        if (!noticias.all) {
            let check = Object.values(noticias);
            check.shift();
            if (check.every(element => element)) {
                setNoticias({
                    ...noticias,
                    all: true,
                })
            }
        }
    }, [noticias]);

    useEffect(() => {
        if (!configuracion.all) {
            let check = Object.values(configuracion);
            check.shift();
            if (check.every(element => element)) {
                setConfiguracion({
                    ...configuracion,
                    all: true,
                })
            }
        }
    }, [configuracion]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nuevo usuario</h3>

                <div className="row mb-4 text-right">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear usuario</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre y apellido" name="nombre" onChange={e => onChange(e) } value={nombre}></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email" name="email" onChange={e => onChange(e) } value={email}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña" name="password" onChange={e => onChange(e) } value={password}></input>
                    </div>
                </div>

                <h5>Permisos</h5>

                <div className="row mt-4 mb-4">

                    {/* Superadmin */}
                    <div className="col-md-12">
                        <div className="card p-4 bg-light">
                            <div className="form-check d-flex align-items-center">
                                <input data-type="superadmin" className="form-check-input" type="checkbox" checked={superadmin} id="superadmin" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <div className="pl-3">
                                    <label className="form-check-label font-weight-bold" htmlFor="superadmin">
                                        Superadmin
                                    </label>
                                    <p className="text-muted m-0">Superadmin tiene todos los permisos.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Medicos */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="medicos" className="form-check-input superadmin" type="checkbox" checked={medicos.all} id="medicos" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="medicos">
                                    Medicos
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input medicos superadmin" type="checkbox" checked={medicos.ver} id="medicos_ver" data-field="ver" data-type="medicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="medicos_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input medicos superadmin" type="checkbox" checked={medicos.perfil} id="medicos_perfil" data-field="perfil" data-type="medicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="medicos_perfil">
                                    Perfil
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input medicos superadmin" type="checkbox" checked={medicos.eliminar} id="medicos_eliminar" data-field="eliminar" data-type="medicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="medicos_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Pacientes */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="pacientes" className="form-check-input superadmin" type="checkbox" checked={pacientes.all} id="pacientes" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="pacientes">
                                    Pacientes
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input pacientes superadmin" type="checkbox" checked={pacientes.ver} id="pacientes_ver" data-field="ver" data-type="pacientes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pacientes_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pacientes superadmin" type="checkbox" checked={pacientes.perfil} id="pacientes_perfil" data-field="perfil" data-type="pacientes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pacientes_perfil">
                                    Perfil
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pacientes superadmin" type="checkbox" checked={pacientes.eliminar} id="pacientes_eliminar" data-field="eliminar" data-type="pacientes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pacientes_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* Distribuidores */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="distribuidores" className="form-check-input superadmin" type="checkbox" checked={distribuidores.all} id="distribuidores" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="distribuidores">
                                    Distribuidores
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input distribuidores superadmin" type="checkbox" checked={distribuidores.ver} id="distribuidores_ver" data-field="ver" data-type="distribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="distribuidores_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input distribuidores superadmin" type="checkbox" checked={distribuidores.agregar} id="distribuidores_agregar" data-field="agregar" data-type="distribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="distribuidores_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input distribuidores superadmin" type="checkbox" checked={distribuidores.editar} id="distribuidores_editar" data-field="editar" data-type="distribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="distribuidores_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input distribuidores superadmin" type="checkbox" checked={distribuidores.perfil} id="distribuidores_perfil" data-field="perfil" data-type="distribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="distribuidores_perfil">
                                    Perfil
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input distribuidores superadmin" type="checkbox" checked={distribuidores.eliminar} id="distribuidores_eliminar" data-field="eliminar" data-type="distribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="distribuidores_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Gerentes */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="gerentes" className="form-check-input superadmin" type="checkbox" checked={gerentes.all} id="gerentes" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="gerentes">
                                    Gerentes
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input gerentes superadmin" type="checkbox" checked={gerentes.ver} id="gerentes_ver" data-field="ver" data-type="gerentes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="gerentes_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input gerentes superadmin" type="checkbox" checked={gerentes.agregar} id="gerentes_agregar" data-field="agregar" data-type="gerentes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="gerentes_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input gerentes superadmin" type="checkbox" checked={gerentes.editar} id="gerentes_editar" data-field="editar" data-type="gerentes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="gerentes_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input gerentes superadmin" type="checkbox" checked={gerentes.eliminar} id="gerentes_eliminar" data-field="eliminar" data-type="gerentes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="gerentes_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Proveedores */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="proveedores" className="form-check-input superadmin" type="checkbox" checked={proveedores.all} id="proveedores" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="proveedores">
                                    Proveedores
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input proveedores superadmin" type="checkbox" checked={proveedores.ver} id="proveedores_ver" data-field="ver" data-type="proveedores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="proveedores_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input proveedores superadmin" type="checkbox" checked={proveedores.agregar} id="proveedores_agregar" data-field="agregar" data-type="proveedores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="proveedores_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input proveedores superadmin" type="checkbox" checked={proveedores.editar} id="proveedores_editar" data-field="editar" data-type="proveedores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="proveedores_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input proveedores superadmin" type="checkbox" checked={proveedores.eliminar} id="proveedores_eliminar" data-field="eliminar" data-type="proveedores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="proveedores_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Administradores */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="administradores" className="form-check-input superadmin" type="checkbox" checked={administradores.all} id="administradores" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="administradores">
                                    Administradores
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input administradores superadmin" type="checkbox" checked={administradores.ver} id="administradores_ver" data-field="ver" data-type="administradores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="administradores_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input administradores superadmin" type="checkbox" checked={administradores.agregar} id="administradores_agregar" data-field="agregar" data-type="administradores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="administradores_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input administradores superadmin" type="checkbox" checked={administradores.editar} id="administradores_editar" data-field="editar" data-type="administradores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="administradores_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input administradores superadmin" type="checkbox" checked={administradores.eliminar} id="administradores_eliminar" data-field="eliminar" data-type="administradores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="administradores_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Ordenes en proceso */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="ordenesProceso" className="form-check-input superadmin" type="checkbox" checked={ordenesProceso.all} id="ordenesProceso" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="ordenesProceso">
                                    Ordenes en proceso
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input ordenesProceso superadmin" type="checkbox" checked={ordenesProceso.ver} id="ordenesProceso_ver" data-field="ver" data-type="ordenesProceso" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="ordenesProceso_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input ordenesProceso superadmin" type="checkbox" checked={ordenesProceso.detalles} id="ordenesProceso_detalles" data-field="detalles" data-type="ordenesProceso" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="ordenesProceso_detalles">
                                    Detalles
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input ordenesProceso superadmin" type="checkbox" checked={ordenesProceso.status} id="ordenesProceso_status" data-field="status" data-type="ordenesProceso" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="ordenesProceso_status">
                                    Status
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Ordenes finalizadas */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="ordenesFinalizadas" className="form-check-input superadmin" type="checkbox" checked={ordenesFinalizadas.all} id="ordenesFinalizadas" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="ordenesFinalizadas">
                                    Ordenes en proceso
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input ordenesFinalizadas superadmin" type="checkbox" checked={ordenesFinalizadas.ver} id="ordenesFinalizadas_ver" data-field="ver" data-type="ordenesFinalizadas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="ordenesFinalizadas_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input ordenesFinalizadas superadmin" type="checkbox" checked={ordenesFinalizadas.detalles} id="ordenesFinalizadas_detalles" data-field="detalles" data-type="ordenesFinalizadas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="ordenesFinalizadas_detalles">
                                    Detalles
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Pagos a distribuidores */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="pagosDistribuidores" className="form-check-input superadmin" type="checkbox" checked={pagosDistribuidores.all} id="pagosDistribuidores" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="pagosDistribuidores">
                                    Pagos a distribuidores
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosDistribuidores superadmin" type="checkbox" checked={pagosDistribuidores.ver} id="pagosDistribuidores_ver" data-field="ver" data-type="pagosDistribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosDistribuidores_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosDistribuidores superadmin" type="checkbox" checked={pagosDistribuidores.procesar} id="pagosDistribuidores_procesar" data-field="procesar" data-type="pagosDistribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosDistribuidores_procesar">
                                    Procesar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosDistribuidores superadmin" type="checkbox" checked={pagosDistribuidores.detalles} id="pagosDistribuidores_detalles" data-field="detalles" data-type="pagosDistribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosDistribuidores_detalles">
                                    Detalles
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosDistribuidores superadmin" type="checkbox" checked={pagosDistribuidores.facturas} id="pagosDistribuidores_facturas" data-field="facturas" data-type="pagosDistribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosDistribuidores_facturas">
                                    Facturas
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosDistribuidores superadmin" type="checkbox" checked={pagosDistribuidores.pagar} id="pagosDistribuidores_pagar" data-field="pagar" data-type="pagosDistribuidores" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosDistribuidores_pagar">
                                    Pagar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Pagos a medicos */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="pagosMedicos" className="form-check-input superadmin" type="checkbox" checked={pagosMedicos.all} id="pagosMedicos" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="pagosMedicos">
                                    Pagos a médicos
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosMedicos superadmin" type="checkbox" checked={pagosMedicos.ver} id="pagosMedicos_ver" data-field="ver" data-type="pagosMedicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosMedicos_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosMedicos superadmin" type="checkbox" checked={pagosMedicos.procesar} id="pagosMedicos_procesar" data-field="procesar" data-type="pagosMedicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosMedicos_procesar">
                                    Procesar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosMedicos superadmin" type="checkbox" checked={pagosMedicos.detalles} id="pagosMedicos_detalles" data-field="detalles" data-type="pagosMedicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosMedicos_detalles">
                                    Detalles
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosMedicos superadmin" type="checkbox" checked={pagosMedicos.facturas} id="pagosMedicos_facturas" data-field="facturas" data-type="pagosMedicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosMedicos_facturas">
                                    Facturas
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagosMedicos superadmin" type="checkbox" checked={pagosMedicos.pagar} id="pagosMedicos_pagar" data-field="pagar" data-type="pagosMedicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagosMedicos_pagar">
                                    Pagar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Pagos a medicos */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="farmacias" className="form-check-input superadmin" type="checkbox" checked={farmacias.all} id="farmacias" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="farmacias">
                                    Farmacias
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.ver} id="farmacias_ver" data-field="ver" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.agregar} id="farmacias_agregar" data-field="agregar" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.inventario} id="farmacias_inventario" data-field="inventario" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_inventario">
                                    Inventario
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.detalles} id="farmacias_detalles" data-field="detalles" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_detalles">
                                    Detalles
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.editar} id="farmacias_editar" data-field="editar" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.eliminar} id="farmacias_eliminar" data-field="eliminar" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_eliminar">
                                    Eliminar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.estadisticas} id="farmacias_estadisticas" data-field="estadisticas" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_estadisticas">
                                    Estadisticas
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.agregar_empleado} id="farmacias_agregar_empleado" data-field="agregar_empleado" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_agregar_empleado">
                                    Agregar empleado
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.editar_empleado} id="farmacias_editar_empleado" data-field="editar_empleado" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_editar_empleado">
                                    Editar empleado
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input farmacias superadmin" type="checkbox" checked={farmacias.eliminar_empleado} id="farmacias_eliminar_empleado" data-field="eliminar_empleado" data-type="farmacias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="farmacias_eliminar_empleado">
                                    Eliminar empleado
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Categorias */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="categorias" className="form-check-input superadmin" type="checkbox" checked={categorias.all} id="categorias" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="categorias">
                                    Categorias
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input categorias superadmin" type="checkbox" checked={categorias.ver} id="categorias_ver" data-field="ver" data-type="categorias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="categorias_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input categorias superadmin" type="checkbox" checked={categorias.agregar} id="categorias_agregar" data-field="agregar" data-type="categorias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="categorias_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input categorias superadmin" type="checkbox" checked={categorias.productos} id="categorias_productos" data-field="productos" data-type="categorias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="categorias_productos">
                                    Productos
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input categorias superadmin" type="checkbox" checked={categorias.editar} id="categorias_editar" data-field="editar" data-type="categorias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="categorias_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input categorias superadmin" type="checkbox" checked={categorias.eliminar} id="categorias_eliminar" data-field="eliminar" data-type="categorias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="categorias_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Productos */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="productos" className="form-check-input superadmin" type="checkbox" checked={productos.all} id="productos" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="productos">
                                    Productos
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input productos superadmin" type="checkbox" checked={productos.ver} id="productos_ver" data-field="ver" data-type="productos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="productos_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input productos superadmin" type="checkbox" checked={productos.agregar} id="productos_agregar" data-field="agregar" data-type="productos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="productos_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input productos superadmin" type="checkbox" checked={productos.editar} id="productos_editar" data-field="editar" data-type="productos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="productos_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input productos superadmin" type="checkbox" checked={productos.eliminar} id="productos_eliminar" data-field="eliminar" data-type="productos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="productos_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Reportes */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="reportes" className="form-check-input superadmin" type="checkbox" checked={reportes.all} id="reportes" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="reportes">
                                    Reportes
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input reportes superadmin" type="checkbox" checked={reportes.ver} id="reportes_ver" data-field="ver" data-type="reportes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="reportes_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input reportes superadmin" type="checkbox" checked={reportes.procesar} id="reportes_procesar" data-field="procesar" data-type="reportes" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="reportes_procesar">
                                    Procesar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Promociones generales */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="promocionesGenerales" className="form-check-input superadmin" type="checkbox" checked={promocionesGenerales.all} id="promocionesGenerales" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="promocionesGenerales">
                                    Promociones generales
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesGenerales superadmin" type="checkbox" checked={promocionesGenerales.ver} id="promocionesGenerales_ver" data-field="ver" data-type="promocionesGenerales" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesGenerales_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesGenerales superadmin" type="checkbox" checked={promocionesGenerales.agregar} id="promocionesGenerales_agregar" data-field="agregar" data-type="promocionesGenerales" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesGenerales_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesGenerales superadmin" type="checkbox" checked={promocionesGenerales.editar} id="promocionesGenerales_editar" data-field="editar" data-type="promocionesGenerales" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesGenerales_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesGenerales superadmin" type="checkbox" checked={promocionesGenerales.eliminar} id="promocionesGenerales_eliminar" data-field="eliminar" data-type="promocionesGenerales" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesGenerales_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Promociones productos */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="promocionesProductos" className="form-check-input superadmin" type="checkbox" checked={promocionesProductos.all} id="promocionesProductos" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="promocionesProductos">
                                    Promociones productos
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesProductos superadmin" type="checkbox" checked={promocionesProductos.ver} id="promocionesProductos_ver" data-field="ver" data-type="promocionesProductos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesProductos_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesProductos superadmin" type="checkbox" checked={promocionesProductos.agregar} id="promocionesProductos_agregar" data-field="agregar" data-type="promocionesProductos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesProductos_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesProductos superadmin" type="checkbox" checked={promocionesProductos.editar} id="promocionesProductos_editar" data-field="editar" data-type="promocionesProductos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesProductos_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesProductos superadmin" type="checkbox" checked={promocionesProductos.status} id="promocionesProductos_status" data-field="status" data-type="promocionesProductos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesProductos_status">
                                    Status
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesProductos superadmin" type="checkbox" checked={promocionesProductos.eliminar} id="promocionesProductos_eliminar" data-field="eliminar" data-type="promocionesProductos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesProductos_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Promociones tarjetas */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="promocionesTarjetas" className="form-check-input superadmin" type="checkbox" checked={promocionesTarjetas.all} id="promocionesTarjetas" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="promocionesTarjetas">
                                    Tarjetas de clientes
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesTarjetas superadmin" type="checkbox" checked={promocionesTarjetas.ver} id="promocionesTarjetas_ver" data-field="ver" data-type="promocionesTarjetas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesTarjetas_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesTarjetas superadmin" type="checkbox" checked={promocionesTarjetas.agregar} id="promocionesTarjetas_agregar" data-field="agregar" data-type="promocionesTarjetas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesTarjetas_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesTarjetas superadmin" type="checkbox" checked={promocionesTarjetas.editar} id="promocionesTarjetas_editar" data-field="editar" data-type="promocionesTarjetas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesTarjetas_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input promocionesTarjetas superadmin" type="checkbox" checked={promocionesTarjetas.eliminar} id="promocionesTarjetas_eliminar" data-field="eliminar" data-type="promocionesTarjetas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="promocionesTarjetas_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Avisos */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="avisos" className="form-check-input superadmin" type="checkbox" checked={avisos.all} id="avisos" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="avisos">
                                    Avisos
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input avisos superadmin" type="checkbox" checked={avisos.ver} id="avisos_ver" data-field="ver" data-type="avisos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="avisos_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input avisos superadmin" type="checkbox" checked={avisos.agregar} id="avisos_agregar" data-field="agregar" data-type="avisos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="avisos_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input avisos superadmin" type="checkbox" checked={avisos.editar} id="avisos_editar" data-field="editar" data-type="avisos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="avisos_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input avisos superadmin" type="checkbox" checked={avisos.eliminar} id="avisos_eliminar" data-field="eliminar" data-type="avisos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="avisos_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Noticias */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="noticias" className="form-check-input superadmin" type="checkbox" checked={noticias.all} id="noticias" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="noticias">
                                    Noticias
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input noticias superadmin" type="checkbox" checked={noticias.ver} id="noticias_ver" data-field="ver" data-type="noticias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="noticias_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input noticias superadmin" type="checkbox" checked={noticias.agregar} id="noticias_agregar" data-field="agregar" data-type="noticias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="noticias_agregar">
                                    Agregar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input noticias superadmin" type="checkbox" checked={noticias.editar} id="noticias_editar" data-field="editar" data-type="noticias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="noticias_editar">
                                    Editar
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input noticias superadmin" type="checkbox" checked={noticias.eliminar} id="noticias_eliminar" data-field="eliminar" data-type="noticias" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="noticias_eliminar">
                                    Eliminar
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Configuracion */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="configuracion" className="form-check-input superadmin" type="checkbox" checked={configuracion.all} id="configuracion" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="configuracion">
                                    Configuracion
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input configuracion superadmin" type="checkbox" checked={configuracion.ver} id="configuracion_ver" data-field="ver" data-type="configuracion" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="configuracion_ver">
                                    Ver seccion
                                </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input configuracion superadmin" type="checkbox" checked={configuracion.guardar} id="configuracion_guardar" data-field="guardar" data-type="configuracion" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="configuracion_guardar">
                                    Guardar
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UsuarioNuevo;