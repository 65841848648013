import { LOAD_USUARIOS, ADD_USUARIO, DETAIL_USUARIO, EDIT_USUARIO, DELETE_USUARIO } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_USUARIOS:
        	return {
                ...state,
                usuarios: payload
            }
        case ADD_USUARIO:
            return {
                ...state,
                usuarios: [
                    ...state.usuarios, payload
                ]
            }
        case EDIT_USUARIO:
            return {
                ...state,
                usuarios: state.usuarios.map( usuario => usuario._id == payload._id ? payload : usuario)
            }
        case DETAIL_USUARIO:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_USUARIO:
            return {
                ...state,
                usuarios: state.usuarios.filter( usuario => usuario._id != payload.id )
            }
        default:
            return state;
    }
}