import { LOAD_PACIENTES, DETAIL_PACIENTE, DELETE_PACIENTE } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_PACIENTES:
        	return {
                ...state,
                pacientes: payload
            }
        case DETAIL_PACIENTE:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_PACIENTE:
            return {
                ...state,
                pacientes: state.pacientes.filter( paciente => paciente._id != payload.id )
            }
        default:
            return state;
    }
}