import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import { crearAviso } from '../../actions/avisos';

registerLocale('es', es);

const AvisoNuevo = ({ history }) =>{

    const dispatch = useDispatch();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearAviso(avisoData));
            history.push('/avisos');
        } catch (error) {
            return false;
        }
    }
    
    const [avisoData, setAvisoData ] = useState({
        mensaje: '',
        fecha_inicio: '',
        fecha_fin: ''
    });
    
    const { mensaje, fecha_inicio, fecha_fin } = avisoData;
    
    const onChange = (e) => {
        setAvisoData({
            ...avisoData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nuevo aviso</h3>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Mensaje</label> <br></br>
                        <input type="text" className="form-control" placeholder="Mensaje" name="mensaje" onChange={e => onChange(e) } value={mensaje}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Fecha de inicio:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ fecha_inicio ? new Date(fecha_inicio) : '' } onChange={ date => setAvisoData({ ...avisoData, fecha_inicio: date })}/>
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Fecha de fin:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ fecha_fin ? new Date(fecha_fin) : '' } onChange={ date => setAvisoData({ ...avisoData, fecha_fin: date })}/>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear aviso</button>
            </form>
        </div>
    )
}

export default AvisoNuevo;