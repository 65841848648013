import { LOAD_PROVEEDORES, LOAD_SUCURSALES_PROVEEDORES, LOAD_EDITABLES_PROVEEDORES, ADD_PROVEEDOR, EDIT_PROVEEDOR, DETAIL_PROVEEDOR, DELETE_PROVEEDOR } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const buscarProveedores = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_proveedores/`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_PROVEEDORES,
            payload: res.data.proveedores
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const buscarSucursales = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_proveedores/sucursales`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_SUCURSALES_PROVEEDORES,
            payload: res.data.sucursales
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const buscarEditables = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_proveedores/editables`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_EDITABLES_PROVEEDORES,
            payload: res.data.sucursales
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const crearProveedor = (proveedor) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proveedor);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_proveedores/agregar`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: ADD_PROVEEDOR,
            payload: res.data.proveedor
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarProveedor = (proveedor) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proveedor);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_proveedores/editar`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: EDIT_PROVEEDOR,
            payload: res.data.proveedor
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesProveedor = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_proveedores/detalles`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: DETAIL_PROVEEDOR,
            payload: res.data.proveedor
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const eliminarProveedor = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_proveedores/eliminar`, body, config);
        dispatch({
            type: DELETE_PROVEEDOR,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}