import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { crearGerente, buscarSucursales } from '../../actions/gerentes';

const styles = {
    check_zone: {
        display: 'flex',
        alignItems: 'center'
    },
    sin_filas: {
        textAlign: 'center'
    }
}

const GerenteNuevo = ({ history }) =>{

    const dispatch = useDispatch();

    const gerentes = useSelector(state => state.gerentes);

    const tableRow = (items) => {

        if(items.length == 0){
            return (
                <tbody>
                    <tr>
                        <td colspan={3} style={styles.sin_filas}>No hay sucursales disponibles</td>
                    </tr>
                </tbody>
            );
        }else{
            return (
                <tbody>
                    {items.map(item => (
                        <Fragment key={item._id}>
                            <tr>
                                <td style={styles.check_zone}>
                                    <div className="form-check">
                                        <input id={item._id} type="checkbox" onChange={e => handleCheckbox(e)} value={item.cantidad}></input>
                                    </div>
                                </td>
                                <td>{item.nombre}</td>
                                <td>{item.direccion}</td>
                            </tr>
                        </Fragment>
                    ))}
                </tbody>
            );
        }
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearGerente(gerenteData));
            history.push('/gerentes');
        } catch (error) {
            return false;
        }
    }

    const handleCheckbox = ({ target }) => {
        if(target.checked){
            setGerenteData({
                ...gerenteData,
                sucursales_id: [...gerenteData.sucursales_id, target.id]
            });
        }else{
            setGerenteData({
                ...gerenteData,
                sucursales_id: gerenteData.sucursales_id.filter( sucursal_id => sucursal_id != target.id )
            });
        }
    }
    
    const [gerenteData, setGerenteData ] = useState({
        nombre: '',
        telefono: '',
        sexo: '',
        email: '',
        password: '',
        sucursales_id: []
    });
    
    const { nombre, telefono, sexo, email, password } = gerenteData;
    
    const onChange = (e) => {
        setGerenteData({
            ...gerenteData, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        dispatch(buscarSucursales());
    }, []);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nuevo gerente</h3>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre y apellido" name="nombre" onChange={ e => onChange(e) } value={nombre}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono</label> <br></br>
                        <input type="text" className="form-control" placeholder="Teléfono" name="telefono" onChange={ e => onChange(e) } value={telefono}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Sexo</label> <br></br>
                        <select className="form-control" name="sexo" value={sexo} onChange={e => onChange(e)}>
                            <option value="">Elegir sexo</option>
                            <option value="f">Femenino</option>
                            <option value="m">Masculino</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email" name="email" onChange={ e => onChange(e) } value={email}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña" name="password" onChange={ e => onChange(e) } value={password}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Seleccionar</th>
                                    <th scope="col">Nombre de sucursal</th>
                                    <th scope="col">Dirección</th>
                                </tr>
                            </thead>
                            {
                                tableRow(gerentes.sucursales != undefined ? gerentes.sucursales : [])
                            }
                        </table>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear gerente</button>
            </form>
        </div>
    )
}

export default GerenteNuevo;