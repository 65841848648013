import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { crearCategoria } from '../../actions/productos';

const CategoriaNueva = ({ history }) =>{

    const dispatch = useDispatch();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearCategoria(categoriaData));
            history.push('/categorias');
        } catch (error) {
            return false;
        }
    }
    
    const [categoriaData, setCategoriaData ] = useState({
        nombre: '',
        descripcion: ''
    });
    
    const { nombre, descripcion } = categoriaData;
    
    const onChange = (e) => {
        setCategoriaData({
            ...categoriaData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nueva Categoria</h3>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={nombre}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Descripción</label> <br></br>
                        <input type="text" className="form-control" placeholder="Descripción" name="descripcion" onChange={e => onChange(e) } value={descripcion}></input>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear categoria</button>
            </form>
        </div>
    )
}

export default CategoriaNueva;