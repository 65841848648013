import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { crearUbicacion } from '../../actions/ubicaciones';
import { detallesFarmacia } from '../../actions/farmacias';

const UbicacionNueva = ({ history }) =>{

    const { id_farmacia } = useParams();

    const dispatch = useDispatch();

    const { detalles } = useSelector(state => state.farmacias);
    
    const [ubicacionData, setUbicacionData ] = useState({
        id_farmacia: null,
        nombre: '', 
        nivel: ''
    });
    
    let { nombre, nivel } = ubicacionData;
    
    const onChange = (e) => {
        setUbicacionData({
            ...ubicacionData, 
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearUbicacion(ubicacionData));
            history.push('/farmacias/ubicaciones/' + id_farmacia);
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        if(id_farmacia){
            dispatch(detallesFarmacia(id_farmacia));

            setUbicacionData({
                ...ubicacionData, 
                id_farmacia
            });
        }
    }, [id_farmacia]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 my-5">Nueva ubicacion de {detalles?.nombre}</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={nombre}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Nivel</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nivel" name="nivel" onChange={e => onChange(e) } value={nivel}></input>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear ubicación</button>
            </form>
        </div>
    )
}

export default UbicacionNueva;