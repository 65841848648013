import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Login
import Login from './components/auth/Login'

// Layout
import NotFoundPage from './components/routing/NotFoundPage'
import PrivateRoute from './components/routing/PrivateRoute'
import Layout from './components/layout/Layout';
import Alert from './components/layout/Alert'

import Dashboard from './components/dashboard/Dashboard'

// Médicos
import Medicos from './components/medicos/Medicos'
import MedicoDetalles from './components/medicos/MedicoDetalles'

// Pacientes
import Pacientes from './components/pacientes/Pacientes'
import PacienteDetalles from './components/pacientes/PacienteDetalles'

// Distribuidores
import Distribuidores from './components/distribuidores/Distribuidores'
import DistribuidorNuevo from './components/distribuidores/DistribuidorNuevo'
import DistribuidorEditar from './components/distribuidores/DistribuidorEditar'
import DistribuidorDetalles from './components/distribuidores/DistribuidorDetalles'

// Gerentes
import Gerentes from './components/gerentes/Gerentes'
import GerenteNuevo from './components/gerentes/GerenteNuevo'
import GerenteEditar from './components/gerentes/GerenteEditar'

// Proveedores
import Proveedores from './components/proveedores/Proveedores'
import ProveedorNuevo from './components/proveedores/ProveedorNuevo'
import ProveedorEditar from './components/proveedores/ProveedorEditar'

// Usuarios
import Usuarios from './components/usuarios/Usuarios'
import UsuarioNuevo from './components/usuarios/UsuarioNuevo'
import UsuarioEditar from './components/usuarios/UsuarioEditar'

// Ordenes
import Ordenes from './components/ordenes/Ordenes'
import OrdenesFinalizadas from './components/ordenes/OrdenesFinalizadas'
import OrdenDetalles from './components/ordenes/OrdenDetalles'

// Pagos
import PagosDistribuidores from './components/pagos/PagosDistribuidores'
import PagoDetallesDistribuidor from './components/pagos/PagoDetallesDistribuidor'
import PagosMedicos from './components/pagos/PagosMedicos'
import PagoDetallesMedico from './components/pagos/PagoDetallesMedico'

// Farmacias
import Farmacias from './components/farmacias/Farmacias'
import FarmaciaNueva from './components/farmacias/FarmaciaNueva'
import FarmaciaEditar from './components/farmacias/FarmaciaEditar'
import FarmaciaDetalles from './components/farmacias/FarmaciaDetalles'
import FarmaciaInventario from './components/farmacias/FarmaciaInventario'
import FarmaciaEstadisticas from './components/farmacias/FarmaciaEstadisticas'
import CajeroNuevo from './components/cajeros/CajeroNuevo'
import CajeroEditar from './components/cajeros/CajeroEditar'

//Productos
import Productos from './components/productos/Productos';
import ProductoNuevo from './components/productos/ProductoNuevo';
import ProductoEditar from './components/productos/ProductoEditar';
import Categorias from './components/productos/Categorias';
import CategoriaNueva from './components/productos/CategoriaNueva';
import CategoriaEditar from './components/productos/CategoriaEditar';

// Ubicaciones
import Ubicaciones from './components/ubicaciones/Ubicaciones'
import UbicacionNueva from './components/ubicaciones/UbicacionNueva'
import UbicacionEditar from './components/ubicaciones/UbicacionEditar'

// Promociones
import Promociones from './components/promociones/Promociones'
import PromocionNueva from './components/promociones/PromocionNueva'
import PromocionEditar from './components/promociones/PromocionEditar'
import PromocionesProductos from './components/promociones/PromocionesProductos'
import PromocionProductoNueva from './components/promociones/PromocionProductoNueva'
import PromocionProductoEditar from './components/promociones/PromocionProductoEditar'
import PromocionesTarjetas from './components/promociones/PromocionesTarjetas'
import PromocionTarjetaNueva from './components/promociones/PromocionTarjetaNueva'
import PromocionTarjetaEditar from './components/promociones/PromocionTarjetaEditar'

// Avisos
import Avisos from './components/avisos/Avisos'
import AvisoNuevo from './components/avisos/AvisoNuevo'
import AvisoEditar from './components/avisos/AvisoEditar'

// Noticias
import Noticias from './components/noticias/Noticias'
import NoticiaNueva from './components/noticias/NoticiaNueva'
import NoticiaEditar from './components/noticias/NoticiaEditar'

// Reportes
import Reportes from './components/reportes/Reportes'

// Configuración
import Configuracion from './components/configuracion/Configuracion'

// Redux
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';

const App = () => {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    useEffect(() => {
        store.dispatch(loadUser());
    }, [])


    return (<Provider store={store}>
        <Router>
            <Fragment>
                <Layout></Layout>
                <section className="container-fluid" style={{ paddingLeft: '64px' }}>
                    <Alert></Alert>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        {/* Médicos */}
                        <PrivateRoute exact path="/medicos" component={Medicos} />
                        <PrivateRoute exact path="/medicos/detalles/:id" component={MedicoDetalles} />

                        {/* Pacientes */}
                        <PrivateRoute exact path="/pacientes" component={Pacientes} />
                        <PrivateRoute exact path="/pacientes/detalles/:id" component={PacienteDetalles} />

                        {/* Distribuidores */}
                        <PrivateRoute exact path="/distribuidores" component={Distribuidores} />
                        <PrivateRoute exact path="/distribuidores/nuevo" component={DistribuidorNuevo} />
                        <PrivateRoute exact path="/distribuidores/editar/:id" component={DistribuidorEditar} />
                        <PrivateRoute exact path="/distribuidores/detalles/:id" component={DistribuidorDetalles} />

                        {/* Gerentes */}
                        <PrivateRoute exact path="/gerentes" component={Gerentes} />
                        <PrivateRoute exact path="/gerentes/nuevo" component={GerenteNuevo} />
                        <PrivateRoute exact path="/gerentes/editar/:id" component={GerenteEditar} />

                        {/* Proveedores */}
                        <PrivateRoute exact path="/proveedores" component={Proveedores} />
                        <PrivateRoute exact path="/proveedores/nuevo" component={ProveedorNuevo} />
                        <PrivateRoute exact path="/proveedores/editar/:id" component={ProveedorEditar} />

                        {/* Usuarios */}
                        <PrivateRoute exact path="/usuarios" component={Usuarios} />
                        <PrivateRoute exact path="/usuarios/nuevo" component={UsuarioNuevo} />
                        <PrivateRoute exact path="/usuarios/editar/:id" component={UsuarioEditar} />

                        {/* Ordenes */}
                        <PrivateRoute exact path="/ordenes" component={Ordenes} />
                        <PrivateRoute exact path="/ordenes/finalizadas" component={OrdenesFinalizadas} />
                        <PrivateRoute exact path="/ordenes/detalles/:id" component={OrdenDetalles} />

                        {/* Pagos */}
                        <PrivateRoute exact path="/pagos/distribuidores" component={PagosDistribuidores} />
                        <PrivateRoute exact path="/pagos/distribuidores/detalles/:id/:id_distribuidor" component={PagoDetallesDistribuidor} />
                        <PrivateRoute exact path="/pagos/medicos" component={PagosMedicos} />
                        <PrivateRoute exact path="/pagos/medicos/detalles/:id/:id_medico" component={PagoDetallesMedico} />

                        {/* Farmacias */}
                        <PrivateRoute exact path="/farmacias" component={Farmacias} />
                        <PrivateRoute exact path="/farmacias/nueva" component={FarmaciaNueva} />
                        <PrivateRoute exact path="/farmacias/editar/:id" component={FarmaciaEditar} />
                        <PrivateRoute exact path="/farmacias/detalles/:id" component={FarmaciaDetalles} />
                        <PrivateRoute exact path="/farmacias/inventario/:id" component={FarmaciaInventario} />
                        <PrivateRoute exact path="/farmacias/ubicaciones/:id_farmacia" component={Ubicaciones} />
                        <PrivateRoute exact path="/farmacias/ubicaciones_nueva/:id_farmacia" component={UbicacionNueva} />
                        <PrivateRoute exact path="/farmacias/ubicaciones_editar/:id/:id_farmacia" component={UbicacionEditar} />
                        <PrivateRoute exact path="/farmacias/estadisticas/:id" component={FarmaciaEstadisticas} />
                        <PrivateRoute exact path="/cajeros/nuevo/:id" component={CajeroNuevo} />
                        <PrivateRoute exact path="/cajeros/editar/:id/:id_farmacia" component={CajeroEditar} />

                        {/* Productos */}
                        <PrivateRoute exact path="/productos" component={Productos} />
                        <PrivateRoute exact path="/productos/:id" component={Productos} />
                        <PrivateRoute exact path="/productos_nuevo" component={ProductoNuevo} />
                        <PrivateRoute exact path="/productos_editar/:id" component={ProductoEditar} />
                        <PrivateRoute exact path="/categorias" component={Categorias} />
                        <PrivateRoute exact path="/categorias/nuevo" component={CategoriaNueva} />
                        <PrivateRoute exact path="/categorias/editar/:id" component={CategoriaEditar} />
                        
                        {/* Promociones */}
                        <PrivateRoute exact path="/promociones" component={Promociones} />
                        <PrivateRoute exact path="/promociones/nueva" component={PromocionNueva} />
                        <PrivateRoute exact path="/promociones/editar/:id" component={PromocionEditar} />
                        <PrivateRoute exact path="/promociones/productos" component={PromocionesProductos} />
                        <PrivateRoute exact path="/promociones/productos/:id" component={PromocionesProductos} />
                        <PrivateRoute exact path="/promociones/productos_nueva" component={PromocionProductoNueva} />
                        <PrivateRoute exact path="/promociones/productos_editar/:id" component={PromocionProductoEditar} />
                        <PrivateRoute exact path="/promociones/tarjetas" component={PromocionesTarjetas} />
                        <PrivateRoute exact path="/promociones/tarjetas/nueva" component={PromocionTarjetaNueva} />
                        <PrivateRoute exact path="/promociones/tarjetas/editar/:id" component={PromocionTarjetaEditar} />

                        {/* Avisos */}
                        <PrivateRoute exact path="/avisos" component={Avisos} />
                        <PrivateRoute exact path="/avisos/nuevo" component={AvisoNuevo} />
                        <PrivateRoute exact path="/avisos/editar/:id" component={AvisoEditar} />

                        {/* Noticias */}
                        <PrivateRoute exact path="/noticias" component={Noticias} />
                        <PrivateRoute exact path="/noticias/nueva" component={NoticiaNueva} />
                        <PrivateRoute exact path="/noticias/editar/:id" component={NoticiaEditar} />

                        {/* Reportes */}
                        <PrivateRoute exact path="/reportes" component={Reportes} />

                        {/* Configuración */}
                        <PrivateRoute exact path="/configuracion" component={Configuracion} />

                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </section>
            </Fragment>
        </Router>
    </Provider>)
}

export default App;
