import {
    LOAD_PROMOCIONES,
    ADD_PROMOCION,
    DETAIL_PROMOCION,
    EDIT_PROMOCION,
    DELETE_PROMOCION,

    LOAD_PROMOCIONES_PRODUCTOS,
    ADD_PROMOCION_PRODUCTO,
    EDIT_PROMOCION_PRODUCTO,
    DETAIL_PROMOCION_PRODUCTO,
    STATUS_PROMOCION_PRODUCTO, 
    DELETE_PROMOCION_PRODUCTO,

    LOAD_TARJETAS,
    ADD_TARJETA,
    DETAIL_TARJETA,
    EDIT_TARJETA,
    DELETE_TARJETA
} from '../actions/types'

const initialState = {
    descuentos: [],
    promdes: [],
    tarjetas: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_PROMOCIONES:
        	return {
                ...state,
                descuentos: payload
            }
        case ADD_PROMOCION:
            return {
                ...state,
                descuentos: [
                    ...state.descuentos, payload
                ]
            }
        case EDIT_PROMOCION:
            return {
                ...state,
                descuentos: state.descuentos.map( promocion => promocion._id == payload._id ? payload : promocion)
            }
        case DETAIL_PROMOCION:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_PROMOCION:
            return {
                ...state,
                descuentos: state.descuentos.filter( promocion => promocion._id != payload.id )
            }

        case LOAD_PROMOCIONES_PRODUCTOS:
            return {
                ...state,
                promdes: payload
            }
        case ADD_PROMOCION_PRODUCTO:
            return {
                ...state,
                promdes: [
                    ...state.promdes, payload
                ]
            }
        case EDIT_PROMOCION_PRODUCTO:
            return {
                ...state,
                promdes: state.promdes.map( promocion => promocion._id == payload._id ? payload : promocion)
            }
        case DETAIL_PROMOCION_PRODUCTO:
            return {
                ...state,
                detalles: payload
            }
        case STATUS_PROMOCION_PRODUCTO:
            return {
                ...state,
                promdes: state.promdes.map( promocion => promocion._id == payload._id ? payload : promocion)
            }
        case DELETE_PROMOCION_PRODUCTO:
            return {
                ...state,
                promdes: state.promdes.filter( promocion => promocion._id != payload.id )
            }

        case LOAD_TARJETAS:
            return {
                ...state,
                tarjetas: payload
            }
        case ADD_TARJETA:
            return {
                ...state,
                tarjetas: [
                    ...state.tarjetas, payload
                ]
            }
        case EDIT_TARJETA:
            return {
                ...state,
                tarjetas: state.tarjetas.map( tarjeta => tarjeta._id == payload._id ? payload : tarjeta)
            }
        case DETAIL_TARJETA:
            return {
                ...state,
                detalles: payload
            }
        case DELETE_TARJETA:
            return {
                ...state,
                tarjetas: state.tarjetas.filter( tarjeta => tarjeta._id != payload.id )
            }
        default:
            return state;
    }
}