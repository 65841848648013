import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { v4 as uuid } from 'uuid';

import { editarFarmacia, detallesFarmacia } from '../../actions/farmacias';


const FarmaciaEditar = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const { detalles } = useSelector(state => state.farmacias);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            let formData = new FormData();

            formData.append('id', farmaciaData.id);
            formData.append('nombre', farmaciaData.nombre);
            formData.append('telefono', farmaciaData.telefono);
            formData.append('descripcion', farmaciaData.descripcion);
            formData.append('direccion', farmaciaData.direccion);
            formData.append('latitud', farmaciaData.latitud);
            formData.append('longitud', farmaciaData.longitud);

            if(farmaciaData.imagenes != undefined){
                for (var i = 0; i < farmaciaData.imagenes.length; i++) {
                    formData.append('imagenes', farmaciaData.imagenes[i]);
                }
            }

            dispatch(editarFarmacia(formData));
            history.push('/farmacias');
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    let imagenes = createRef();
    
    const [farmaciaData, setFarmaciaData ] = useState({
        id: '',
        nombre: '',
        telefono: '',
        descripcion: '',
        direccion: '',
        latitud: '',
        longitud: '',
        imagenes: undefined
    });

    const [coord, setCoord] = useState({
        lat: 21.879529,
        lng: -102.303249
    });
    
    const onChange = (e) => {
        setFarmaciaData({
            ...farmaciaData, [e.target.name]: e.target.value
        })
    };

    const onChangeImagenes = () => {
        setFarmaciaData({
            ...farmaciaData, imagenes: imagenes.current.files
        })
    };

    const onSelectDireccion = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setCoord({
                    lat: latLng.lat, lng: latLng.lng
                }),
                setFarmaciaData({
                    ...farmaciaData, direccion: address, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const onChangeDireccion = address => {
        setFarmaciaData({
            ...farmaciaData, direccion: address
        });
    };

    const onMapClick = ({latLng}) => { 
        setCoord({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
        
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage('en');
        Geocode.fromLatLng(latLng.lat(), latLng.lng())
            .then(
                response => {
                    const address = response.results[0].formatted_address;
                    setFarmaciaData({
                        ...farmaciaData,
                        direccion: address,
                        latitud: latLng.lat(),
                        longitud: latLng.lng()
                    });
                }
            );
    };

    useEffect(() => {
        dispatch(detallesFarmacia(id));
    }, []);

    useEffect(() => {
        setFarmaciaData({
            ...farmaciaData,
            id: detalles?._id,
            nombre: detalles?.nombre,
            telefono: detalles?.telefono,
            descripcion: detalles?.descripcion,
            direccion: detalles?.direccion,
            latitud: detalles?.latitud,
            longitud: detalles?.longitud
        });

        setCoord({
            lat: parseFloat(detalles?.latitud),
            lng: parseFloat(detalles?.longitud)
        });
    }, [detalles]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2 mb-5">
                <h3 className="mb-3 mt-5">Editar farmacia</h3>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre de sucursal" name="nombre" onChange={e => onChange(e) } value={farmaciaData.nombre || ''}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono</label> <br></br>
                        <input type="text" className="form-control" placeholder="Teléfono de contacto" name="telefono" onChange={e => onChange(e) } value={farmaciaData.telefono || ''}></input>
                    </div>

                    <div className="col-md-4 form-group custom-file-upload">
                        <label className="font-weight-bold">Imagenes</label> <br></br>
                        <label className={farmaciaData.imagenes != undefined ? 'custom-file-uploaded' : 'custom-file-upload1'} htmlFor="imagenes"> {farmaciaData.imagenes != undefined ? 'Cambiar' : 'Elegir'} imagenes</label>
                        <input className="col-md-12" name="imagenes" id="imagenes" type="file" ref={imagenes} onChange={onChangeImagenes} accept="image/*" multiple />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Descripción</label> <br></br>
                        <textarea className="form-control" placeholder="Descripción de la sucursal" name="descripcion" onChange={e => onChange(e) } value={farmaciaData.descripcion || ''}></textarea>
                    </div>
                </div>

                <div className="row">
                    <PlacesAutocomplete
                        value={farmaciaData.direccion || ''}
                        onChange={onChangeDireccion}
                        onSelect={onSelectDireccion}
                    >

                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Dirección</label>
                                <input
                                    {...getInputProps({
                                        className: 'location-search-input form-control',
                                        id: 'i-direccion'
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const id = uuid();
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style
                                                })}
                                                key={id}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>

                <div className="row">
                    <div className="col-md-12 form-group">
                        <div className="mapa">
                            <GoogleMap
                                mapContainerStyle={{ width: '100%', height: '50vh' }}
                                center={coord}
                                zoom={16}
                                onRightClick={e => {onMapClick(e)}}
                            >
                                <Marker position={coord} />
                            </GoogleMap>
                        </div>
                    </div>
                </div>

                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Editar farmacia</button>
            </form>
        </div>
    )
}

export default FarmaciaEditar;