import { LOAD_USUARIOS, ADD_USUARIO, EDIT_USUARIO, DETAIL_USUARIO, DELETE_USUARIO } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const buscarUsuarios = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_USUARIOS,
            payload: res.data.usuarios
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const crearUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(usuario);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/registrar`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: ADD_USUARIO,
            payload: res.data.usuario
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    console.log(usuario);

    const body = JSON.stringify(usuario);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/editar`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: EDIT_USUARIO,
            payload: res.data.usuario
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesUsuario = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if(id){
        const body = JSON.stringify({ id });

        try {
            dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/detalles`, body, config);
            dispatch(changeLoader(false));
            dispatch({
                type: DETAIL_USUARIO,
                payload: res.data.usuario
            });
        } catch (error) {
            dispatch(changeLoader(false));
            if(error.response){
                if (error.response.data.errors) {
                    error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
                }else{
                    dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
                }
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }
    }else{
        dispatch({
            type: DETAIL_USUARIO,
            payload: null
        });
    }
}

export const eliminarUsuario = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/eliminar`, body, config);
        dispatch({
            type: DELETE_USUARIO,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}