import { LOAD_PROVEEDORES, LOAD_SUCURSALES_PROVEEDORES, LOAD_EDITABLES_PROVEEDORES, ADD_PROVEEDOR, DETAIL_PROVEEDOR, EDIT_PROVEEDOR, DELETE_PROVEEDOR } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_PROVEEDORES:
        	return {
                ...state,
                proveedores: payload
            }
        case LOAD_SUCURSALES_PROVEEDORES:
            return {
                ...state,
                sucursales: payload
            }
        case LOAD_EDITABLES_PROVEEDORES:
            return {
                ...state,
                editables: payload
            }
        case ADD_PROVEEDOR:
            return {
                ...state,
                proveedores: [
                    ...state.proveedores, payload
                ]
            }
        case EDIT_PROVEEDOR:
            return {
                ...state,
                proveedores: state.proveedores.map( gerente => gerente._id == payload._id ? payload : gerente)
            }
        case DETAIL_PROVEEDOR:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_PROVEEDOR:
            return {
                ...state,
                proveedores: state.proveedores.filter( gerente => gerente._id != payload.id )
            }
        default:
            return state;
    }
}