import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarTarjetas, eliminarTarjeta } from '../../actions/promociones';

const PromocionesTarjetas = ({ history }) => {

    const dispatch = useDispatch();

    const promociones = useSelector(state => state.promociones);
    const { permisos_promociones_tarjetas } = useSelector(state => state.auth.user);

    const formatCard = (numero) => {
        let spacedNumber = "";

        if(numero){
            let inputNumbersOnly = numero.replace(/\D/g, "");
        
            if (inputNumbersOnly.length > 16) {
                inputNumbersOnly = inputNumbersOnly.substr(0, 16);
            }
        
            const splits = inputNumbersOnly.match(/.{1,4}/g);
        
            if (splits) {
                spacedNumber = splits.join(" ");
            }
        }
    
        return spacedNumber;
    }

    const tableRow = (items) => {
        if(items.length == 0){
            return (
                <tbody>
                    <tr>
                        <td className="text-center" colSpan={7}>No hay registros actualmente.</td>
                    </tr>
                </tbody>
            );
        }else{
            return (
                <tbody>
                    {items.map(item => (
                        <Fragment key={item._id}>
                            <tr>
                                <td>{formatCard(item.numero)}</td>
                                <td>{item.farmacia}</td>
                                <td>{item.tipo == 0 ? 'Sin asignar' : (item.tipo == 1 ? 'Paciente' : 'Médico')}</td>
                                <td>{item.propietario}</td>
                                <td>{item.fecha_alta != '' && item.fecha_alta != null ? new Date(item.fecha_alta).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</td>
                                <td>
                                    {
                                        permisos_promociones_tarjetas?.editar ?
                                            <Link className="btn btn-link text-link" to={`/promociones/tarjetas/editar/${item._id}`}>Editar</Link>
                                        :
                                            null
                                    }
                                </td>
                                <td>
                                    {
                                        permisos_promociones_tarjetas?.eliminar ?
                                            <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                        :
                                            null
                                    }
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                </tbody>
            );
        }        
    }

    const eliminar = (id) => {
        dispatch(eliminarTarjeta(id));
    }

    useEffect(() => {
        dispatch(buscarTarjetas());
    }, []);

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Tarjetas</h3>
                        <p className="text-muted">{promociones.tarjetas?.length} tarjeta{promociones.tarjetas?.length == 1 ? '' : 's'} registrada{promociones.tarjetas?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_promociones_tarjetas?.agregar ?
                                <Link to="/promociones/tarjetas/nueva" className="btn btn-success text-right">Nueva tarjeta</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Número</th>
                            <th scope="col">Sucursal</th>
                            <th scope="col">Tipo de propietario</th>
                            <th scope="col">Propietario</th>
                            <th scope="col">Asignación</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(promociones.tarjetas != undefined ? promociones.tarjetas : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default PromocionesTarjetas;