import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

import { buscarFarmacias } from '../../actions/farmacias';
import { generarReporte } from '../../actions/reportes';
import { buscarProductos, buscarCategorias } from '../../actions/productos';

const styles = {
    titulo: {
        color: '#198FC1'
    },
    subtitulo: {
        margin: 0
    },
    buttonIcon: {
        padding: 20,
        fontSize: 30,
        backgroundColor: '#198fc1',
        color: '#FFFFFF',
        borderRadius: 10,
        marginBottom: 15
    }
}

const Reportes = () => {

    const dispatch = useDispatch();

    const { farmacias } = useSelector(state => state.farmacias);
    const { productos, categorias } = useSelector(state => state.productos);
    const { permisos_reportes } = useSelector(state => state.auth.user);

    const [productoInformationData, setProductoInformationData] = useState(null);
    const [productosFilter, setProductosFilter] = useState([]);
    
    const [categoriaInformationData, setCategoriaInformationData] = useState(null);
    const [categoriasFilter, setCategoriasFilter] = useState([]);

    const [filtrosModal, setFiltrosModal] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        reporte: null,
        id_sucursal: null,
        id_producto: null,
        fecha_inicio: null,
        fecha_fin: null
    });

    const handleAbrirFiltros = (reporte) => {
        setFiltrosModal(true);
        setFiltrosData({
            ...filtrosData,
            reporte
        })
    }

    const onChange = (e) => {
        setFiltrosData({
            ...filtrosData, [e.target.name]: e.target.value
        })
    }

    const handleSelectFilter = (option, tipo) => {
        if(tipo == 'producto'){
            setProductoInformationData(option);
            setFiltrosData({
                ...filtrosData,
                id_producto: option != null ? option.value : null
            });
        }

        if(tipo == 'categoria'){
            setCategoriaInformationData(option);
            setFiltrosData({
                ...filtrosData,
                id_categoria: option != null ? option.value : null
            });
        }
    }

    const procesarReporte = async () => {
        await setFiltrosModal(false);
        await setFiltrosData({
            ...filtrosData,
            reporte: null,
            id_sucursal: null,
            id_producto: null,
            fecha_inicio: null,
            fecha_fin: null
        });
        await setProductoInformationData(null);
        await setCategoriaInformationData(null);
        await dispatch(generarReporte(filtrosData));
    }

    useEffect(() => {
        dispatch(buscarFarmacias());
        dispatch(buscarProductos());
        dispatch(buscarCategorias());
    }, []);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < productos?.length; i++) {
            options.push({ value: productos[i]._id, label: productos[i].nombre });
        }

        setProductosFilter(options);
    }, [productos]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < categorias?.length; i++) {
            options.push({ value: categorias[i]._id, label: categorias[i].nombre });
        }

        setCategoriasFilter(options);
    }, [categorias]);

    return (
        <Fragment>
            {/* Modal de filtros */}
            <Modal show={filtrosModal} onHide={() => {
                setFiltrosModal(false);
                setFiltrosData({
                    ...filtrosData,
                    reporte: null,
                    id_sucursal: null,
                    id_producto: null,
                    fecha_inicio: null,
                    fecha_fin: null
                });
                setProductoInformationData(null);
                setCategoriaInformationData(null);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Generación de reporte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Sucursal:</label>
                            <select className="form-control" name="id_sucursal" value={filtrosData.id_sucursal} onChange={e => onChange(e)}>
                                <option value="">Selecciona Categoría</option>
                                {
                                    farmacias ?
                                        farmacias?.map(farmacia => (
                                            <option key={farmacia._id} value={farmacia._id}>{farmacia.nombre}</option>
                                        ))
                                    :
                                        null
                                }
                            </select>
                        </div>
                        
                        {
                            filtrosData.reporte == 'producto_sucursal' ?
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Producto:</label>
                                    <Select name="id_producto" isClearable={true} options={productosFilter} value={productoInformationData} onChange={(value) => handleSelectFilter(value, 'producto')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                </div>
                            :
                                null
                        }

                        {
                            filtrosData.reporte == 'categoria_sucursal' ?
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Categoria:</label>
                                    <Select name="id_categoria" isClearable={true} options={categoriasFilter} value={categoriaInformationData} onChange={(value) => handleSelectFilter(value, 'categoria')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                </div>
                            :
                                null
                        }

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Fecha de inicio:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ filtrosData.fecha_inicio ? new Date(filtrosData.fecha_inicio) : '' } onChange={ date => setFiltrosData({ ...filtrosData, fecha_inicio: date })}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Fecha de finalización:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ filtrosData.fecha_fin ? new Date(filtrosData.fecha_fin) : '' } onChange={ date => setFiltrosData({ ...filtrosData, fecha_fin: date })}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        permisos_reportes?.procesar ?
                            <button type="button" className="btn btn-primary" onClick={() => procesarReporte()} disabled={filtrosData.id_sucursal != null && filtrosData.fecha_inicio != null && filtrosData.fecha_fin != null ? false : true}>Procesar</button>
                        :
                            null
                    }
                </Modal.Footer>
            </Modal>

            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-8">
                            <h3>Reportes</h3>
                        </div>
                    </div>
                    <div className="row mb-3 mt-5">
                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('ventas')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-shopping-cart" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Ventas</h5>
                                        <p style={styles.subtitulo}>Reporte de las ventas desde el PDV y las Apps.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('ordenes')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-truck" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Ordenes a proveedores</h5>
                                        <p style={styles.subtitulo}>Reporte de las ordenes de reabastecimiento hacia los proveedores.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('gastos')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-receipt" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Gastos</h5>
                                        <p style={styles.subtitulo}>Reporte de los gastos realizados desde sucursal.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('inventario')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-th" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Inventario</h5>
                                        <p style={styles.subtitulo}>Reporte de las cantidades exitentes por cada producto en sucursal.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('ganancias_perdidas')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-dollar-sign" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Ganancias y perdidas</h5>
                                        <p style={styles.subtitulo}>Reporte del balance entre las ganancias y perdidas de la sucursal.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('actividades_usuario')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-file-alt" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Actividades por usuario</h5>
                                        <p style={styles.subtitulo}>Reporte de las actividades realizadas por cada usuario en sucursal.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('producto_sucursal')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-pills" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Ventas por producto</h5>
                                        <p style={styles.subtitulo}>Reporte de las ventas realizadas por producto en cada sucursal.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('categoria_sucursal')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-pills" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Ventas por categoria</h5>
                                        <p style={styles.subtitulo}>Reporte de las ventas realizadas por categoria en cada sucursal.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100" onClick={() => handleAbrirFiltros('categorias_sucursal')}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0">
                                        <i className="fa fa-pills" style={styles.buttonIcon} />
                                        <h5 style={styles.titulo}>Ventas por categorias</h5>
                                        <p style={styles.subtitulo}>Reporte de las ventas realizadas por cada categoria en cada sucursal.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Reportes;