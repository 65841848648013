import { LOAD_DISTRIBUIDORES, ADD_DISTRIBUIDOR, DETAIL_DISTRIBUIDOR, EDIT_DISTRIBUIDOR, DELETE_DISTRIBUIDOR } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_DISTRIBUIDORES:
        	return {
                ...state,
                distribuidores: payload
            }
        case ADD_DISTRIBUIDOR:
            return {
                ...state,
                distribuidores: [
                    ...state.distribuidores, payload
                ]
            }
        case EDIT_DISTRIBUIDOR:
            return {
                ...state,
                distribuidores: state.distribuidores.map( distribuidor => distribuidor._id == payload._id ? payload : distribuidor)
            }
        case DETAIL_DISTRIBUIDOR:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_DISTRIBUIDOR:
            return {
                ...state,
                distribuidores: state.distribuidores.filter( distribuidor => distribuidor._id != payload.id )
            }
        default:
            return state;
    }
}