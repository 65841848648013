import { LOAD_AVISOS, ADD_AVISO, DETAIL_AVISO, EDIT_AVISO, DELETE_AVISO } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_AVISOS:
        	return {
                ...state,
                avisos: payload
            }
        case ADD_AVISO:
            return {
                ...state,
                avisos: [
                    ...state.avisos, payload
                ]
            }
        case EDIT_AVISO:
            return {
                ...state,
                avisos: state.avisos.map( aviso => aviso._id == payload._id ? payload : aviso)
            }
        case DETAIL_AVISO:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_AVISO:
            return {
                ...state,
                avisos: state.avisos.filter( aviso => aviso._id != payload.id )
            }
        default:
            return state;
    }
}