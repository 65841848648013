import React, { createRef, useEffect, useState, Fragment } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Badge, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { setAlert } from '../../actions/alert';
import { detallesPagoDistribuidor, actualizarPagoDistribuidor } from '../../actions/pagos';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    btnStatus: {
        cursor: 'pointer',
    }
}

const PagoDetallesDistribuidor = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay resultados a mostrar</b></p>);

    const { id, id_distribuidor } = useParams();

    const dispatch = useDispatch();

    const pago = useSelector(state => state.pagos);
    const { permisos_pagos_distribuidores } = useSelector(state => state.auth.user);

    const [facturaModal, setFacturaModal] = useState(false);
    
    const [pagoModal, setPagoModal] = useState(false);

    const [pagoData, setPagoData] = useState({
        id: '',
        pago: false
    });

    const handleFactura = () => {
        setFacturaModal(true);
    }

    const handlePago = () => {
        setPagoModal(true);
    }

    const changePago = () => {
        dispatch(actualizarPagoDistribuidor(id, id_distribuidor));
        setPagoData({ ...pagoData, pago: true });
        setPagoModal(false);
    }

    useEffect(() => {
        dispatch(detallesPagoDistribuidor(id, id_distribuidor));
    }, [id]);

    useEffect(() => {
        setPagoData({
            ...pagoData,
            id: pago.detalles_distribuidor?._id,
            pago: pago.detalles_distribuidor?.pagado
        });
    }, [pago]);

    const columnsAcciones = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Acción',
            selector: 'descripcion',
            sortable: true,
            cell: item => item.descripcion ? item.descripcion : '-',
        }
    ]

    const facturaBotonReminder = (facturado) => {
        if(facturado){
            return <Badge variant="success" style={styles.btnStatus} onClick={() => handleFactura()}>Realizada</Badge>;
        }else{
            return <Badge variant="warning">Pendiente</Badge>;
        }
    }

    const facturaReminder = (facturado) => {
        if(facturado){
            return <Badge variant="success">Realizada</Badge>;
        }else{
            return <Badge variant="warning">Pendiente</Badge>;
        }
    }

    const pagoBotonReminder = (pagado) => {
        if(pagado){
            return <Badge variant="success">Realizado</Badge>;
        }else{
            return <Badge variant="warning" style={styles.btnStatus} onClick={() => handlePago()}>Pendiente</Badge>;
        }
    }

    const pagoReminder = (pagado) => {
        if(pagado){
            return <Badge variant="success">Realizado</Badge>;
        }else{
            return <Badge variant="warning">Pendiente</Badge>;
        }
    }

    const botonesDescargarPdf = (facturado) => {
        if(!facturado){
            return <img
                        height="150"
                        className="my-3 text-center"
                        src="/assets/images/pdf_gris.png"
                    ></img>;
        }else{
            return <Fragment>    
                        <a href={pago.detalles_distribuidor?.url_pdf} target="_blank">
                            <img
                                height="150"
                                className="my-3 text-center"
                                src="/assets/images/pdf_verde.png"
                            ></img>
                        </a>
                    </Fragment>;
        }
    }

    const botonesDescargarXml = (facturado) => {
        if(!facturado){
            return <img
                        height="150"
                        className="my-3 text-center"
                        src="/assets/images/xml_gris.png"
                    ></img>;
        }else{
            return <Fragment>    
                        <a href={pago.detalles_distribuidor?.url_xml} target="_blank">
                            <img
                                height="150"
                                className="my-3 text-center"
                                src="/assets/images/xml_verde.png"
                            ></img>
                        </a>
                    </Fragment>;
        }
    }

    const botonesSolicitarPago = (pagado) => {
        if(!pagado){
            return <Fragment>
                        <Button variant="success" onClick={() => changePago()}>
                            Pagar
                        </Button>
                    </Fragment>;
        }else{
            return <Button variant="primary" onClick={() => setPagoModal(false)}>
                        Entendido
                    </Button>;
        }
    }

    return (
        pago.detalles_distribuidor ? <Fragment>
            {/* Modal de factura */}
            <Modal show={facturaModal} onHide={() => {
                setFacturaModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Factura del distribuidor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6 text-center">
                            { botonesDescargarPdf(pago.detalles_distribuidor.facturado) }
                        </div>
                        <div className="col-md-6 text-center">
                            { botonesDescargarXml(pago.detalles_distribuidor.facturado) }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setFacturaModal(false)}>
                        Entendido
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de pago */}
            <Modal show={pagoModal} onHide={() => {
                setPagoModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Procesar el pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={styles.info}>¿Estas seguro de procesar el pago? Puedes revisar los detalles del mismo en <b>Detalles del pago</b>.</p>
                </Modal.Body>
                <Modal.Footer>
                    { botonesSolicitarPago(pago.detalles_distribuidor.pagado) }
                </Modal.Footer>
            </Modal>

            <div className="row px-5 pt-3">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pago.detalles_distribuidor.total ? pago.detalles_distribuidor.total : 0)
                                    }</p>
                                    <p style={styles.infoText}>total a pagar</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pago.detalles_distribuidor.total_factura ? pago.detalles_distribuidor.total_factura : 0)
                                    }</p>
                                    <p style={styles.infoText}>total factura</p>
                                </div>
                                <div className="col-md">
                                    {
                                        permisos_pagos_distribuidores?.facturas ?
                                            <p style={styles.infoNumero}>{facturaBotonReminder(pago.detalles_distribuidor.facturado)}</p>
                                        :
                                            <p style={styles.infoNumero}>{facturaReminder(pago.detalles_distribuidor.facturado)}</p>
                                    }
                                    <p style={styles.infoText}>facturado</p>
                                </div>
                                <div className="col-md">
                                    {
                                        permisos_pagos_distribuidores?.pagar ?
                                            <p style={styles.infoNumero}>{pagoBotonReminder(pagoData.pago)}</p>
                                        :
                                            <p style={styles.infoNumero}>{pagoReminder(pagoData.pago)}</p>
                                    }
                                    <p style={styles.infoText}>pagado</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Detalles del pago</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="d-flex justify-content-center">
                                        { pago.detalles_distribuidor?.url_perfil ?
                                            <img
                                                height="150"
                                                className="my-3 text-center"
                                                src={pago.detalles_distribuidor?.url_perfil}
                                            ></img>
                                        :   [
                                                (pago.detalles_distribuidor?.sexo == 'm' ?
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_distribuidor.png"
                                                        ></img>
                                                    :
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_distribuidora.png"
                                                        ></img>
                                                )
                                            ]
                                        }
                                    </div>
                                </div>
                                <div className="col-md-8 row">
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Distribuidor</p>
                                        <p style={styles.info}>{pago.detalles_distribuidor.nombre ? pago.detalles_distribuidor.nombre : '-' }</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Teléfono</p>
                                        <p style={styles.info}>{pago.detalles_distribuidor.telefono ? pago.detalles_distribuidor.telefono : '-' }</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Correo</p>
                                        <p style={styles.info}>{pago.detalles_distribuidor.email ? pago.detalles_distribuidor.email : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Titular</p>
                                        <p style={styles.info}>{pago.detalles_distribuidor.titular ? pago.detalles_distribuidor.titular : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Numero de cuenta</p>
                                        <p style={styles.info}>{pago.detalles_distribuidor.cuenta ? pago.detalles_distribuidor.cuenta : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>CLABE</p>
                                        <p style={styles.info}>{pago.detalles_distribuidor.clabe ? pago.detalles_distribuidor.clabe : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Banco</p>
                                        <p style={styles.info}>{pago.detalles_distribuidor.banco ? pago.detalles_distribuidor.banco : '-' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Historial de acciones</h5>
                            <div style={styles.sectionContainer}>
                                <DataTable
                                    columns={columnsAcciones}
                                    data={pago?.detalles_distribuidor?.historial_status}
                                    defaultSortField="title"
                                    noHeader={true}
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    noDataComponent={noDataConst}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default PagoDetallesDistribuidor;
