import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { loadDashboard } from '../../actions/dashboard'

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#198FC1' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 20,
        margin: 0,
        fontWeight: 'bold'
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    buttonIcon: {
        fontSize: 30,
    },
    bgImg: {
        height: '40vw',
        opacity: 0.08,
        position: 'absolute',
        left: '-320px',
        top: '10vh'
    },
}

const Dashboard = () => {

    const user = useSelector(state => state.auth.user);
    const dashboard = useSelector(state => state.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDashboard());
    }, [])

    return (
        dashboard.info ? <div className="row">
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-10 offset-md-1 mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row mt-4">
                            <div className="col-md-12 mb-3 d-flex align-items-center">
                                <h4 style={styles.titulo} className="mb-4 ml-4">
                                    Bievenido {user.nombre}
                                </h4>
                            </div>
                            <div className="col-md-12">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Usuarios</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_medicos}</p>
                                                <p style={styles.infoText}>médicos</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_pacientes}</p>
                                                <p style={styles.infoText}>pacientes</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_distribuidores}</p>
                                                <p style={styles.infoText}>distribuidores</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_farmacias}</p>
                                                <p style={styles.infoText}>farmacias</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_gerentes}</p>
                                                <p style={styles.infoText}>gerentes</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_proveedores}</p>
                                                <p style={styles.infoText}>proveedores</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_usuarios}</p>
                                                <p style={styles.infoText}>usuarios</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="card bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Compras</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_compras_pacientes}</p>
                                                <p style={styles.infoText}>de pacientes</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.monto_compras_pacientes)
                                                }</p>
                                                <p style={styles.infoText}>monto ($)</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_compras_medicos}</p>
                                                <p style={styles.infoText}>de médicos</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.monto_compras_medicos)
                                                }</p>
                                                <p style={styles.infoText}>monto ($)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="card bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Órdenes</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.ordenes_totales}</p>
                                                <p style={styles.infoText}>totales</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.ordenes_pendientes}</p>
                                                <p style={styles.infoText}>pendientes</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.ordenes_finalizadas}</p>
                                                <p style={styles.infoText}>finalizadas</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Pagos a médicos</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_pagos_medicos}</p>
                                                <p style={styles.infoText}>totales</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_pagos_pendientes_medicos}</p>
                                                <p style={styles.infoText}>pendientes</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_pagos_realizados_medicos}</p>
                                                <p style={styles.infoText}>realizados</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Pagos a distribuidores</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_pagos_distri}</p>
                                                <p style={styles.infoText}>totales</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_pagos_distri_pendientes}</p>
                                                <p style={styles.infoText}>pendientes</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.cantidad_pagos_distri_realizados}</p>
                                                <p style={styles.infoText}>realizados</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="card bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Ingresos del dia</h5>
                                        </div>
                                        <div className="row mt-2">

                                            {
                                                dashboard.info?.farmacias?.map(item => (
                                                    <Fragment key={item._id}>
                                                        <div className="col">
                                                            <p style={styles.infoNumero}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.ingresos)}</p>
                                                            <p style={styles.infoText}>{item.nombre}</p>
                                                        </div>
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div ></div > : <div></div>
    )
}

export default Dashboard;