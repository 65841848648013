import React, { useState, createRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { crearProducto, buscarCategorias } from '../../actions/productos';

const ProductoNuevo = ({ history }) =>{

    const dispatch = useDispatch();
    const categorias = useSelector(state => state.productos.categorias);    
    
    const [productoData, setProductoData ] = useState({
        categoria: '',
        nombre: '',
        descripcion: '',
        aparece_app: null,
        precio_compra: '',
        reorden: '',
        controlado: false,
        precio_venta: '',
        iva_venta: null,
        precio_oferta: '',
        iva_oferta: null,
        oferta_activa: null,
        aparece_app_medico: null,
        precio_venta_medico: '',
        iva_venta_medico: null,
        precio_oferta_medico: '',
        iva_oferta_medico: null,
        oferta_activa_medico: null,
        codigo_barras: '',
        clave_alternativa: '',
        imagenes: ''
    });
    
    let img = createRef();

    const onChange = (e) => {
        setProductoData({
            ...productoData, [e.target.name]: e.target.value
        })
    }

    const onChangeControlado = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
        }

        if(e.target.value == 'true'){
            valor = true;
        }

        setProductoData({
            ...productoData, [e.target.name]: valor
        })
    }

    const onChangeAparicion = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
        }

        if(e.target.value == 'true'){
            valor = true;
        }

        setProductoData({
            ...productoData, [e.target.name]: valor
        })
    }

    const onChangeImpuestos = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
        }

        if(e.target.value == 'true'){
            valor = true;
        }

        setProductoData({
            ...productoData, [e.target.name]: valor
        })
    }

    const onChangeOferta = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
            setProductoData({
                ...productoData, precio_oferta: '', oferta_activa: valor
            });
        }

        if(e.target.value == 'null'){
            valor = null;
            setProductoData({
                ...productoData, precio_oferta: '', oferta_activa: valor
            });
        }

        if(e.target.value == 'true'){
            valor = true;
            setProductoData({
                ...productoData, oferta_activa: valor
            });
        }
    }

    const onChangeOfertaMedico = (e) => {
        let valor = null;

        if(e.target.value == 'false'){
            valor = false;
            setProductoData({
                ...productoData, precio_oferta_medico: '', oferta_activa_medico: valor
            });
        }

        if(e.target.value == 'null'){
            valor = null;
            setProductoData({
                ...productoData, precio_oferta_medico: '', oferta_activa_medico: valor
            });
        }

        if(e.target.value == 'true'){
            valor = true;
            setProductoData({
                ...productoData, oferta_activa_medico: valor
            });
        }
    }

    const onChangeImg = () => {
        setProductoData({
            ...productoData, imagenes: img.current.files
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let formData = new FormData();
            formData.append('categoria', productoData.categoria);
            formData.append('nombre', productoData.nombre);
            formData.append('descripcion', productoData.descripcion);
            formData.append('precio_compra', productoData.precio_compra);
            formData.append('reorden', productoData.reorden);
            formData.append('controlado', productoData.controlado);
            formData.append('precio_venta', productoData.precio_venta);
            formData.append('iva_venta', productoData.iva_venta);
            formData.append('precio_oferta', productoData.precio_oferta);
            formData.append('iva_oferta', productoData.iva_oferta);
            formData.append('aparece_app', productoData.aparece_app);
            formData.append('oferta_activa', productoData.oferta_activa);
            formData.append('precio_venta_medico', productoData.precio_venta_medico);
            formData.append('iva_venta_medico', productoData.iva_venta_medico);
            formData.append('precio_oferta_medico', productoData.precio_oferta_medico);
            formData.append('iva_oferta_medico', productoData.iva_oferta_medico);
            formData.append('aparece_app_medico', productoData.aparece_app_medico);
            formData.append('oferta_activa_medico', productoData.oferta_activa_medico);
            formData.append('codigo_barras', productoData.codigo_barras);
            formData.append('clave_alternativa', productoData.clave_alternativa);

            for (var i = 0; i < productoData.imagenes.length; i++) {
                formData.append('imagenes', productoData.imagenes[i]);
            }
            
            await dispatch(crearProducto(formData));

            await history.push('/productos');
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        dispatch(buscarCategorias());
    }, []);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nuevo producto</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre del producto" name="nombre" onChange={e => onChange(e) } value={productoData.nombre}></input>
                    </div>
                    
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Categoria</label> <br></br>
                        <select className="form-control" name="categoria" value={productoData.categoria} onChange={e => onChange(e)}>
                            <option value="">Selecciona Categoría</option>
                            {categorias ? categorias.map(categoria => (
                                <option key={categoria._id} value={categoria._id}>{categoria.nombre}</option>
                            )) : null}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Descripción</label> <br></br>
                        <input type="text" className="form-control" placeholder="Descripción" name="descripcion" onChange={e => onChange(e) } value={productoData.descripcion}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de compra</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de compra" name="precio_compra" onChange={e => onChange(e) } value={productoData.precio_compra}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Punto de reorden</label> <br></br>
                        <input type="text" className="form-control" placeholder="Punto de reorden" name="reorden" onChange={e => onChange(e) } value={productoData.reorden}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Requiere de receta?</label> <br></br>
                        <select className="form-control" name="controlado" value={productoData.controlado} onChange={e => onChangeControlado(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de venta a paciente</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de venta paciente" name="precio_venta" onChange={e => onChange(e) } value={productoData.precio_venta}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (venta a paciente)</label> <br></br>
                        <select className="form-control" name="iva_venta" value={productoData.iva_venta} onChange={e => onChangeImpuestos(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Aparece en la app de paciente?</label> <br></br>
                        <select className="form-control" name="aparece_app" value={productoData.aparece_app} onChange={e => onChangeAparicion(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Esta en oferta para paciente?</label> <br></br>
                        <select className="form-control" name="oferta_activa" value={productoData.oferta_activa} onChange={e => onChangeOferta(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de oferta a paciente</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de oferta a paciente" name="precio_oferta" onChange={e => onChange(e) } value={productoData.precio_oferta} disabled={productoData.oferta_activa == true ? false : true}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (oferta a paciente)</label> <br></br>
                        <select className="form-control" name="iva_oferta" value={productoData.iva_oferta} onChange={e => onChangeImpuestos(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de venta a médico</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de venta médico" name="precio_venta_medico" onChange={e => onChange(e) } value={productoData.precio_venta_medico}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (venta a médico)</label> <br></br>
                        <select className="form-control" name="iva_venta_medico" value={productoData.iva_venta_medico} onChange={e => onChangeImpuestos(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Aparece en la app de médico?</label> <br></br>
                        <select className="form-control" name="aparece_app_medico" value={productoData.aparece_app_medico} onChange={e => onChangeAparicion(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">¿Esta en oferta para médico?</label> <br></br>
                        <select className="form-control" name="oferta_activa_medico" value={productoData.oferta_activa_medico} onChange={e => onChangeOfertaMedico(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Precio de oferta a médico</label> <br></br>
                        <input type="text" className="form-control" placeholder="Precio de oferta a médico" name="precio_oferta_medico" onChange={e => onChange(e) } value={productoData.precio_oferta_medico} disabled={productoData.oferta_activa_medico == true ? false : true}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">IVA incluido (oferta a médico)</label> <br></br>
                        <select className="form-control" name="iva_oferta_medico" value={productoData.iva_oferta_medico} onChange={e => onChangeImpuestos(e)}>
                            <option value="null">Selecciona una opción</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Clave Alternativa</label> <br></br>
                        <input type="text" className="form-control" placeholder="Clave Alternativa" name="clave_alternativa" onChange={e => onChange(e) } value={productoData.clave_alternativa}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Código de Barras</label> <br></br>
                        <input type="text" className="form-control" placeholder="Código de Barras" name="codigo_barras" onChange={e => onChange(e) } value={productoData.codigo_barras}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Imagenes</label> <br></br>
                        <input type="file" className="form-control" name="image" id="image" ref={img} onChange={onChangeImg} accept="image/*" multiple/>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right mb-5" onClick={handleSubmit}>Agregar producto</button>
            </form>
        </div>
    )
}

export default ProductoNuevo;