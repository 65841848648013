import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarPromociones, eliminarPromocion } from '../../actions/promociones';

const Promociones = ({ history }) => {

    const dispatch = useDispatch();

    const promociones = useSelector(state => state.promociones);
    const { permisos_promociones_generales } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.codigo}</td>
                            <td>{item.concepto}</td>
                            <td>{item.porcentaje}</td>
                            <td>{item.tipo == 0 ? 'Por usos' : (item.tipo == 1 ? 'Por tiempo' : '-')}</td>
                            <td>{item.tipo == 0 ? item.usos : '-'}</td>
                            <td>{item.fecha_inicio != '' && item.fecha_inicio != null ? new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</td>
                            <td>{item.fecha_fin != '' && item.fecha_fin != null ? new Date(item.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</td>
                            <td>
                                {
                                    permisos_promociones_generales?.editar ?
                                        <Link className="btn btn-link text-link" to={`/promociones/editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_promociones_generales?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarPromocion(id));
    }

    useEffect(() => {
        dispatch(buscarPromociones());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Descuentos generales</h3>
                        <p className="text-muted">{promociones.descuentos?.length} descuento{promociones.descuentos?.length == 1 ? '' : 's'} registrado{promociones.descuentos?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_promociones_generales?.agregar ?
                                <Link to="/promociones/nueva" className="btn btn-success text-right">Nuevo descuento</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Concepto</th>
                            <th scope="col">Porcentaje</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Usos</th>
                            <th scope="col">Inicio</th>
                            <th scope="col">Fin</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(promociones.descuentos != undefined ? promociones.descuentos : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Promociones;