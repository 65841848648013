import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarCategorias, eliminarCategoria } from '../../actions/productos';

const Categorias = ({ history }) => {

    const dispatch = useDispatch();
    
    const categorias = useSelector(state => state.productos.categorias);
    const { permisos_categorias } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.descripcion}</td>
                            <td><Link className="btn btn-link text-link" to={`/productos/${item._id}`}>{item.numero_productos}</Link></td>
                            <td>
                                {
                                    permisos_categorias?.editar ?
                                        <Link className="btn btn-link text-link" to={`/categorias/editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_categorias?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarCategoria(id));
    }

    useEffect(() => {
        dispatch(buscarCategorias());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Categorías</h3>
                        <p className="text-muted">{categorias?.length} categoría{categorias?.length == 1 ? '' : 's'} registrada{categorias?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_categorias?.agregar ?
                                <Link to="/categorias/nuevo" className="btn btn-success text-right">Nueva categoria</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Descripcion</th>
                            <th scope="col">Productos</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(categorias != undefined ? categorias : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Categorias;