import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { buscarProductos, eliminarProducto } from '../../actions/productos';

const Productos = ({ history }) => {

    const { id } = useParams();

    const dispatch = useDispatch();

    const productos = useSelector(state => state.productos);
    const { permisos_productos } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr className={item.oferta_activa || item.oferta_activa_medico ? 'fila_oferta' : ''}>
                            <td>{item.codigo_barras}</td>
                            <td>{item.nombre}</td>
                            <td>{item.nombre_categoria}</td>
                            <td>{item.imagenes_cargadas ? 'Si': 'No'}</td>
                            <td><Link className="btn btn-link text-link" to={`/promociones/productos/${item._id}`}>{item.promociones_existentes}</Link></td>
                            <td>
                                {
                                    permisos_productos?.editar ?
                                        <Link className="btn btn-link text-link" to={`/productos_editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_productos?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarProducto(id));
    }

    useEffect(() => {

        let id_categoria = id;

        if(!id_categoria){
            id_categoria = null;
        }

        dispatch(buscarProductos(id_categoria));
    }, [id]);

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Productos</h3>
                        <p className="text-muted">{productos.productos?.length} producto{productos.productos?.length == 1 ? '' : 's'} registrado{productos.productos?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_productos?.agregar ?
                                <Link to="/productos_nuevo" className="btn btn-success text-right">Nuevo producto</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Código de barras</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Categoría</th>
                            <th scope="col">Imágen cargada</th>
                            <th scope="col">Promociones existentes</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(productos.productos != undefined ? productos.productos : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Productos;