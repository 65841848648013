
// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const EDIT_PERFIL = 'EDIT_PERFIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const RECUPERAR = 'RECUPERAR';
export const RESTABLECER = 'RESTABLECER';

// DASHBOARD
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';

// MEDICOS
export const LOAD_MEDICOS = 'LOAD_MEDICOS';
export const DETAIL_MEDICO = 'DETAIL_MEDICO';
export const DELETE_MEDICO = 'DELETE_MEDICO';

// PACIENTES
export const LOAD_PACIENTES = 'LOAD_PACIENTES';
export const DETAIL_PACIENTE = 'DETAIL_PACIENTE';
export const DELETE_PACIENTE = 'DELETE_PACIENTE';

// DISTRIBUIDORES
export const LOAD_DISTRIBUIDORES = 'LOAD_DISTRIBUIDORES';
export const ADD_DISTRIBUIDOR = 'ADD_DISTRIBUIDOR';
export const EDIT_DISTRIBUIDOR = 'EDIT_DISTRIBUIDOR';
export const DETAIL_DISTRIBUIDOR = 'DETAIL_DISTRIBUIDOR';
export const DELETE_DISTRIBUIDOR = 'DELETE_DISTRIBUIDOR';

// GERENTES
export const LOAD_GERENTES = 'LOAD_GERENTES';
export const LOAD_SUCURSALES = 'LOAD_SUCURSALES';
export const LOAD_EDITABLES = 'LOAD_EDITABLES';
export const ADD_GERENTE = 'ADD_GERENTE';
export const EDIT_GERENTE = 'EDIT_GERENTE';
export const DETAIL_GERENTE = 'DETAIL_GERENTE';
export const DELETE_GERENTE = 'DELETE_GERENTE';

// PROVEEDORES
export const LOAD_PROVEEDORES = 'LOAD_PROVEEDORES';
export const LOAD_SUCURSALES_PROVEEDORES = 'LOAD_SUCURSALES_PROVEEDORES';
export const LOAD_EDITABLES_PROVEEDORES = 'LOAD_EDITABLES_PROVEEDORES';
export const ADD_PROVEEDOR = 'ADD_PROVEEDOR';
export const EDIT_PROVEEDOR = 'EDIT_PROVEEDOR';
export const DETAIL_PROVEEDOR = 'DETAIL_PROVEEDOR';
export const DELETE_PROVEEDOR = 'DELETE_PROVEEDOR';

// USUARIOS
export const LOAD_USUARIOS = 'LOAD_PRODUCTOS';
export const ADD_USUARIO = 'ADD_USUARIO';
export const EDIT_USUARIO = 'EDIT_USUARIO';
export const DETAIL_USUARIO = 'DETAIL_USUARIO';
export const DELETE_USUARIO = 'DELETE_USUARIO';

// FARMACIAS
export const LOAD_FARMACIAS = 'LOAD_FARMACIAS';
export const ADD_FARMACIA = 'ADD_FARMACIA';
export const EDIT_FARMACIA = 'EDIT_FARMACIA';
export const DETAIL_FARMACIA = 'DETAIL_FARMACIA';
export const LOAD_ESTADISTICA = 'LOAD_ESTADISTICA';
export const INVENTORY_FARMACIA = 'INVENTORY_FARMACIA';
export const LOAD_UBICACIONES = 'LOAD_UBICACIONES';
export const ADD_UBICACION = 'ADD_UBICACION';
export const EDIT_UBICACION = 'EDIT_UBICACION';
export const DETAIL_UBICACION = 'DETAIL_UBICACION';
export const DELETE_UBICACION = 'DELETE_UBICACION';
export const DELETE_FARMACIA = 'DELETE_FARMACIA';
export const ADD_CAJERO = 'ADD_CAJERO';
export const EDIT_CAJERO = 'EDIT_CAJERO';
export const DETAIL_CAJERO = 'DETAIL_CAJERO';
export const DELETE_CAJERO = 'DELETE_CAJERO';

// PRODUCTOS
export const LOAD_PRODUCTOS = 'LOAD_PRODUCTOS';
export const ADD_PRODUCTO = 'ADD_PRODUCTO';
export const EDIT_PRODUCTO = 'EDIT_PRODUCTO';
export const DETAIL_PRODUCTO = 'DETAIL_PRODUCTO';
export const DELETE_PRODUCTO = 'DELETE_PRODUCTO';
export const LOAD_CATEGORIAS = 'LOAD_CATEGORIAS';
export const ADD_CATEGORIA = 'ADD_CATEGORIA';
export const EDIT_CATEGORIA = 'EDIT_CATEGORIA';
export const DETAIL_CATEGORIA = 'DETAIL_CATEGORIA';
export const DELETE_CATEGORIA = 'DELETE_CATEGORIA';

// PROMOCIONES
export const LOAD_PROMOCIONES = 'LOAD_PROMOCIONES';
export const ADD_PROMOCION = 'ADD_PROMOCION';
export const EDIT_PROMOCION = 'EDIT_PROMOCION';
export const DETAIL_PROMOCION = 'DETAIL_PROMOCION';
export const DELETE_PROMOCION = 'DELETE_PROMOCION';
export const LOAD_PROMOCIONES_PRODUCTOS = 'LOAD_PROMOCIONES_PRODUCTOS';
export const ADD_PROMOCION_PRODUCTO = 'ADD_PROMOCION_PRODUCTO';
export const EDIT_PROMOCION_PRODUCTO = 'EDIT_PROMOCION_PRODUCTO';
export const DETAIL_PROMOCION_PRODUCTO = 'DETAIL_PROMOCION_PRODUCTO';
export const STATUS_PROMOCION_PRODUCTO = 'STATUS_PROMOCION_PRODUCTO';
export const DELETE_PROMOCION_PRODUCTO = 'DELETE_PROMOCION_PRODUCTO';
export const LOAD_TARJETAS = 'LOAD_TARJETAS';
export const ADD_TARJETA = 'ADD_TARJETA';
export const EDIT_TARJETA = 'EDIT_TARJETA';
export const DETAIL_TARJETA = 'DETAIL_TARJETA';
export const DELETE_TARJETA = 'DELETE_TARJETA';

// AVISOS
export const LOAD_AVISOS = 'LOAD_AVISOS';
export const ADD_AVISO = 'ADD_AVISO';
export const EDIT_AVISO = 'EDIT_AVISO';
export const DETAIL_AVISO = 'DETAIL_AVISO';
export const DELETE_AVISO = 'DELETE_AVISO';

// NOTICIAS
export const LOAD_NOTICIAS = 'LOAD_NOTICIAS';
export const ADD_NOTICIA = 'ADD_NOTICIA';
export const EDIT_NOTICIA = 'EDIT_NOTICIA';
export const DETAIL_NOTICIA = 'DETAIL_NOTICIA';
export const DELETE_NOTICIA = 'DELETE_NOTICIA';

// ORDENES
export const LOAD_ORDENES = 'LOAD_ORDENES';
export const LOAD_FINALIZADAS = 'LOAD_FINALIZADAS';
export const DETAIL_ORDEN = 'DETAIL_ORDEN';
export const LOAD_ACCION = 'LOAD_ACCION';

// PAGOS
export const LOAD_PAGOS_DISTRIBUIDORES = 'LOAD_PAGOS_DISTRIBUIDORES';
export const DETAIL_PAGO_DISTRIBUIDOR = 'DETAIL_PAGO_DISTRIBUIDOR';
export const PROCESS_PAGO_DISTRIBUIDOR = 'PROCESS_PAGO_DISTRIBUIDOR';
export const LOAD_PAGOS_MEDICOS = 'LOAD_PAGOS_MEDICOS';
export const DETAIL_PAGO_MEDICO = 'DETAIL_PAGO_MEDICO';
export const PROCESS_PAGO_MEDICO = 'PROCESS_PAGO_MEDICO';

// LOADER
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
