import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { editarPromDes, detallesPromDes } from '../../actions/promociones';
import { buscarProductos } from '../../actions/productos';

const PromocionProductoEditar = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const promocion = useSelector(state => state.promociones);
    const productos = useSelector(state => state.productos.productos);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(editarPromDes(promocionData));
            history.push('/promociones/productos');
        } catch (error) {
            return false;
        }
    }
    
    const [promocionData, setPromocionData ] = useState({
        id: '',
        tipo: 2,
        producto: null,
        concepto: '',
        porcentaje: 0,
        cantidad_comprar: 0,
        cantidad_cobrar: 0
    });
    
    const onChange = (e) => {
        setPromocionData({
            ...promocionData, [e.target.name]: e.target.value
        })
    }

    const onChangeTipo = (e) => {
        if(e.target.value == 0){
            setPromocionData({
                ...promocionData,
                tipo: e.target.value,
                porcentaje: 0
            });
        }else{
            if(e.target.value == 2){
                setPromocionData({
                    ...promocionData,
                    tipo: e.target.value,
                    cantidad_comprar: 0,
                    cantidad_cobrar: 0,
                    porcentaje: 0
                });
            }else{
                setPromocionData({
                    ...promocionData,
                    tipo: e.target.value,
                    cantidad_comprar: 0,
                    cantidad_cobrar: 0
                });
            }
        }
    }

    useEffect(() => {
        dispatch(buscarProductos());
        dispatch(detallesPromDes(id));
    }, []);

    useEffect(() => {
        setPromocionData({
            ...promocionData,
            id: promocion.detalles?._id,
            tipo: promocion.detalles?.tipo,
            producto: promocion.detalles?.producto,
            concepto: promocion.detalles?.concepto,
            porcentaje: promocion.detalles?.porcentaje,
            cantidad_comprar: promocion.detalles?.cantidad_comprar,
            cantidad_cobrar: promocion.detalles?.cantidad_cobrar
        });
    }, [promocion]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar promoción o descuento</h3>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Concepto</label> <br></br>
                        <input type="text" className="form-control" placeholder="Concepto de descuento" name="concepto" onChange={e => onChange(e) } value={promocionData.concepto || ''}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Producto</label> <br></br>
                        <select className="form-control" name="producto" value={promocionData.producto} onChange={e => onChange(e)}>
                            <option value="null">Selecciona el producto</option>
                            {productos ? productos.map(producto => (
                                <option key={producto._id} value={producto._id}>{producto.nombre}</option>
                            )) : null}
                        </select>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Tipo</label> <br></br>
                        <select className="form-control" name="oferta_activa" value={promocionData.tipo} onChange={e => onChangeTipo(e)}>
                            <option value="2">Selecciona una opción</option>
                            <option value="0">Promoción</option>
                            <option value="1">Descuento</option>
                        </select>
                    </div>
                </div>
                {
                    promocionData.tipo == 0 &&
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Cantidad a comprar:</label>
                                <div className="contenedor_datepicker">
                                    <input type="number" className="form-control" placeholder="Cantidad a comprar" name="cantidad_comprar" onChange={e => onChange(e)} value={promocionData.cantidad_comprar || 0}></input>
                                </div>
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Cantidad a cobrar:</label>
                                <div className="contenedor_datepicker">
                                    <input type="number" className="form-control" placeholder="Cantidad a cobrar" name="cantidad_cobrar" onChange={e => onChange(e)} value={promocionData.cantidad_cobrar || 0}></input>
                                </div>
                            </div>
                        </div>

                }
                {
                    promocionData.tipo == 1 &&
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Porcentaje</label> <br></br>
                                <input type="number" className="form-control" placeholder="%" name="porcentaje" onChange={e => onChange(e) } value={promocionData.porcentaje || 0}></input>
                            </div>
                        </div>

                }
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Editar descuento</button>
            </form>
        </div>
    )
}

export default PromocionProductoEditar;