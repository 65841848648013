import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarNoticias, eliminarNoticia } from '../../actions/noticias';

const Noticias = ({ history }) => {

    const dispatch = useDispatch();

    const noticias = useSelector(state => state.noticias);
    const { permisos_noticias } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.titulo}</td>
                            <td>{item.contenido.substr(0, 50)}...</td>
                            <td>{new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>
                                {
                                    permisos_noticias?.editar ?
                                        <Link className="btn btn-link text-link" to={`/noticias/editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_noticias?.editar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarNoticia(id));
    }

    useEffect(() => {
        dispatch(buscarNoticias());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Noticias</h3>
                        <p className="text-muted">{noticias.noticias?.length} noticia{noticias.noticias?.length == 1 ? '' : 's'} registrada{noticias.noticias?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_noticias?.agregar ?
                                <Link to="/noticias/nueva" className="btn btn-success text-right">Nueva noticia</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Titulo</th>
                            <th scope="col">Contenido</th>
                            <th scope="col">Fecha</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(noticias.noticias != undefined ? noticias.noticias : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Noticias;