import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { buscarOrdenesFinalizadas } from '../../actions/ordenes';

const OrdenesFinalizadas = ({ history }) => {

    const dispatch = useDispatch();

    const ordenes = useSelector(state => state.ordenes);
    const { permisos_ordenes_finalizadas } = useSelector(state => state.auth.user);

    const tableRow = (items) => {

        const numberToDecimal = (number) => {
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }

        const statusReminder = (status) => {
            switch(status){
                case 0:
                    return <Badge variant="primary">Pendiente</Badge>;
                    break;

                case 1:
                    return <Badge variant="warning">En proceso</Badge>;
                    break;

                case 2:
                    return <Badge variant="success">Finalizada</Badge>;
                    break;

                case 3:
                    return <Badge variant="danger">Cancelada</Badge>;
                    break;
            }
        }

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.folio}</td>
                            <td>{item.distribuidor?.nombre}</td>
                            <td>{item.distribuidor?.telefono}</td>
                            <td>{item.cantidad}</td>
                            <td>{new Date(item.fecha_creacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>{statusReminder(item.status)}</td>
                            <td>
                                {
                                    permisos_ordenes_finalizadas?.detalles ?
                                        <Link className="btn btn-link text-link" to={`/ordenes/detalles/${item._id}`}>Detalles</Link>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(buscarOrdenesFinalizadas());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-12">
                        <h3>Órdenes finalizadas</h3>
                        <p className="text-muted">{ordenes.finalizadas?.length} orden{ordenes.finalizadas?.length == 1 ? '' : 'es'} finalizada{ordenes.finalizadas?.length == 1 ? '' : 's'}</p>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Folio</th>
                            <th scope="col">Distribuidor</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(ordenes.finalizadas != undefined ? ordenes.finalizadas : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default OrdenesFinalizadas;