import React, { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel, VictoryTheme } from 'victory';

import { detallesFarmacia } from '../../actions/farmacias';
import { generarEstadistica } from '../../actions/reportes';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const FarmaciaEstadisticas = () => {

    const { id } = useParams();

    const dispatch = useDispatch();

    const { detalles, datos_estadistica } = useSelector(state => state.farmacias);

    const [estadisticasFilter, setProductosFilter] = useState([
        {value: 0, label: 'Productos más vendidos', ruta: 'productos_mas'},
        {value: 1, label: 'Productos menos vendidos', ruta: 'productos_menos'},
        {value: 2, label: 'Monto vendido por empleado', ruta: 'montos_empleados'},
        {value: 3, label: 'Ventas hechas por empleado', ruta: 'ventas_empleados'},
        {value: 4, label: 'Horarios de venta', ruta: 'horarios'}
    ]);

    const [estadisticaInformationData, setEstadisticaInformationData] = useState(null);

    const [estadisticasData, setEstadisticasData] = useState({
        estadistica: null,
        ruta: null,
        id_sucursal: null,
        cantidad_datos: 0,
        fecha_inicio: null,
        fecha_fin: null
    });

    const handleSelectEstadistica = (option) => {
        setEstadisticaInformationData(option);
        setEstadisticasData({
            ...estadisticasData,
            estadistica: option != null ? option.value : null,
            ruta: option != null ? option.ruta : null
        });
    }

    const handleProcesarEstadistica = () => {
        dispatch(generarEstadistica(estadisticasData));
    }

    const handleCloseEstadisticas = () => {
        setEstadisticaInformationData(null);
        setEstadisticasData({
            ...estadisticasData,
            estadistica: null,
            ruta: null,
            id_sucursal: null,
            cantidad_datos: 0,
            fecha_inicio: null,
            fecha_fin: null
        });
    }

    useEffect(() => {
        dispatch(detallesFarmacia(id));

        setEstadisticasData({
            ...estadisticasData,
            id_sucursal: id
        });
    }, [id]);

    useEffect(() => {
        dispatch(generarEstadistica(null));
    }, [estadisticasData]);

    return (
        detalles ? <Fragment>
            <div className="row px-5 pt-5">
                <div className="col-md-12">
                    <h5 style={styles.titulo}>Estadisticas ({detalles.nombre})</h5>
                </div>

                <div className="col-md-12 mt-3 row">
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Tipo de estadistica:</label>
                        <Select name="estadistica" isClearable={true} options={estadisticasFilter} value={estadisticaInformationData} onChange={(value) => handleSelectEstadistica(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    {
                        estadisticasData.estadistica == 0 || estadisticasData.estadistica == 1 ?
                            <div className="col-md form-group">
                                <label className="font-weight-bold">Cantidad de datos:</label>
                                <div className="contenedor_datepicker">
                                    <input className="form-control text-center" type="number" min="0" value={estadisticasData.cantidad_datos} onChange={(e) => setEstadisticasData({ ...estadisticasData, cantidad_datos: e.target.value })}/>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Fecha de inicio:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ estadisticasData.fecha_inicio ? new Date(estadisticasData.fecha_inicio) : '' } onChange={ date => setEstadisticasData({ ...estadisticasData, fecha_inicio: date })}/>
                        </div>
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Fecha de finalización:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ estadisticasData.fecha_fin ? new Date(estadisticasData.fecha_fin) : '' } onChange={ date => setEstadisticasData({ ...estadisticasData, fecha_fin: date })}/>
                        </div>
                    </div>

                    <div className="col-md-3 form-group text-right">
                        <button type="button" className="btn btn-primary" onClick={() => handleProcesarEstadistica()}>Procesar</button>
                    </div>
                </div>

                <div className="col-md-12 mb-3 row">
                    {
                        estadisticasData.estadistica == 0 && datos_estadistica ?
                            <div className="col-md-12">
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    width={800}
                                    height={20 * datos_estadistica.length}
                                    padding={{ top: 30, bottom: 40, right: 80, left: 300}}
                                >
                                    <VictoryAxis
                                        label=""
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 5, padding: 0},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 5, padding: 5}
                                        }}
                                    />

                                    <VictoryAxis
                                        dependentAxis
                                        label="Ventas"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 30},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 7, padding: 5}
                                        }}
                                    />

                                    <VictoryBar
                                        horizontal
                                        barWidth={10}
                                        style={{data: { fill: "#198FC1" }, labels: { fill: "white" }}}
                                        data={datos_estadistica}
                                        labels={({ datum }) => new Intl.NumberFormat('es-MX').format(datum.y)}
                                        labelComponent={<VictoryLabel style={{ fill: "white", fontSize: 6 }} backgroundStyle={{ fill: "black" }} backgroundPadding={3} dy={-3} dx={0}/>}
                                    />
                                </VictoryChart>
                            </div>
                        :
                            null
                    }

                    {
                        estadisticasData.estadistica == 1 && datos_estadistica ?
                            <div className="col-md-12">
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    width={800}
                                    height={20 * datos_estadistica.length}
                                    padding={{ top: 30, bottom: 40, right: 80, left: 300}}
                                >
                                    <VictoryAxis
                                        label=""
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 5, padding: 0},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 5, padding: 5}
                                        }}
                                    />

                                    <VictoryAxis
                                        dependentAxis
                                        label="Ventas"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 30},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 7, padding: 5}
                                        }}
                                    />

                                    <VictoryBar
                                        horizontal
                                        barWidth={10}
                                        style={{data: { fill: "#198FC1" }, labels: { fill: "white" }}}
                                        data={datos_estadistica}
                                        labels={({ datum }) => new Intl.NumberFormat('es-MX').format(datum.y)}
                                        labelComponent={<VictoryLabel style={{ fill: "white", fontSize: 6 }} backgroundStyle={{ fill: "black" }} backgroundPadding={3} dy={-3} dx={0}/>}
                                    />
                                </VictoryChart>
                            </div>
                        :
                            null
                    }

                    {
                        estadisticasData.estadistica == 2 && datos_estadistica ?
                            <div className="col-md-12">
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    width={800}
                                    padding={{ top: 40, bottom: 70, right: 200, left: 200}}
                                >

                                    <VictoryAxis
                                        label="Empleados"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 30},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 5, padding: 5}
                                        }}
                                    />

                                    <VictoryAxis
                                        dependentAxis
                                        label="Ventas"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 60},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 7, padding: 5}
                                        }}
                                    />

                                    <VictoryBar
                                        barWidth={30}
                                        alignment="start"
                                        style={{data: { fill: "#198FC1" }, labels: { fill: "white" }}}
                                        data={datos_estadistica}
                                        labels={({ datum }) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(datum.y)}
                                        labelComponent={<VictoryLabel style={{ fill: "white", fontSize: 10 }} backgroundStyle={{ fill: "black" }} backgroundPadding={5} dy={-10} dx={20}/>}
                                    />
                                </VictoryChart>
                            </div>
                        :
                            null
                    }

                    {
                        estadisticasData.estadistica == 3 && datos_estadistica ?
                            <div className="col-md-12">
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    width={800}
                                    padding={{ top: 40, bottom: 70, right: 200, left: 200}}
                                >

                                    <VictoryAxis
                                        label="Empleados"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 30},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 5, padding: 5}
                                        }}
                                    />

                                    <VictoryAxis
                                        dependentAxis
                                        label="Ventas"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 50},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 7, padding: 5}
                                        }}
                                    />

                                    <VictoryBar
                                        barWidth={30}
                                        alignment="start"
                                        style={{data: { fill: "#198FC1" }, labels: { fill: "white" }}}
                                        data={datos_estadistica}
                                        labels={({ datum }) => new Intl.NumberFormat('es-MX').format(datum.y)}
                                        labelComponent={<VictoryLabel style={{ fill: "white", fontSize: 10 }} backgroundStyle={{ fill: "black" }} backgroundPadding={5} dy={-10} dx={20}/>}
                                    />
                                </VictoryChart>
                            </div>
                        :
                            null
                    }

                    {
                        estadisticasData.estadistica == 4 && datos_estadistica ?
                            <div className="col-md-12">
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    width={1000}
                                    padding={{ top: 40, bottom: 40, right: 75, left: 75}}
                                >

                                    <VictoryAxis
                                        label="Horarios"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 30},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 5, padding: 5}
                                        }}
                                    />

                                    <VictoryAxis
                                        dependentAxis
                                        label="Ventas"
                                        style={{
                                            axis: {stroke: "#756f6a"},
                                            axisLabel: {fontSize: 10, padding: 30},
                                            grid: {stroke: ({ tick }) => tick > 0.5 ? "red" : "grey"},
                                            ticks: {stroke: "grey", size: 5},
                                            tickLabels: {fontSize: 7, padding: 5}
                                        }}
                                    />

                                    <VictoryBar
                                        barWidth={30}
                                        alignment="start"
                                        style={{data: { fill: "#198FC1" }, labels: { fill: "white" }}}
                                        data={datos_estadistica}
                                        labels={({ datum }) => new Intl.NumberFormat('es-MX').format(datum.y)}
                                        labelComponent={<VictoryLabel style={{ fill: "white", fontSize: 10 }} backgroundStyle={{ fill: "black" }} backgroundPadding={5} dy={-10} dx={10}/>}
                                    />
                                </VictoryChart>
                            </div>
                        :
                            null
                    }                    
                </div>

                {
                    estadisticasData.estadistica == null || !datos_estadistica ? 
                        <div className="col-md-10 offset-md-1 text-center contenido_vacio">
                            <h3>Selecciona el tipo de estadistica y sus fechas.</h3>
                            <i className="fas fa-chart-pie mt-3 icono-contenedor_centrado"/>
                        </div>
                    :
                        null
                }
            </div>
        </Fragment> : <div></div>
    )
}




export default FarmaciaEstadisticas;
