import { combineReducers } from 'redux';
import alert from './alert'
import auth from './auth'
import dashboard from './dashboard'
import medicos from './medicos'
import pacientes from './pacientes'
import distribuidores from './distribuidores'
import gerentes from './gerentes'
import proveedores from './proveedores'
import usuarios from './usuarios'
import farmacias from './farmacias'
import productos from './productos'
import ubicaciones from './ubicaciones'
import promociones from './promociones'
import avisos from './avisos'
import noticias from './noticias'
import ordenes from './ordenes'
import pagos from './pagos'
import loader from './loader'

export default combineReducers({
    alert, auth, dashboard, medicos, pacientes, distribuidores, gerentes, proveedores, usuarios, farmacias, productos, ubicaciones, promociones, avisos, noticias, ordenes, pagos, loader
})