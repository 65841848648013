import { LOAD_MEDICOS, DETAIL_MEDICO, DELETE_MEDICO } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_MEDICOS:
        	return {
                ...state,
                medicos: payload
            }
        case DETAIL_MEDICO:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_MEDICO:
            return {
                ...state,
                medicos: state.medicos.filter( medico => medico._id != payload.id )
            }
        default:
            return state;
    }
}