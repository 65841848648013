import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarUsuarios, eliminarUsuario } from '../../actions/usuarios';

const Usuarios = ({ history }) => {

    const dispatch = useDispatch();

    const usuarios = useSelector(state => state.usuarios);
    const { permisos_administradores } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.email}</td>
                            <td>
                                {
                                    permisos_administradores?.editar ?
                                        <Link className="btn btn-link text-link" to={`/usuarios/editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_administradores?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarUsuario(id));
    }

    useEffect(() => {
        dispatch(buscarUsuarios());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Usuarios</h3>
                        <p className="text-muted">{usuarios.usuarios?.length} usuario{usuarios.usuarios?.length == 1 ? '' : 's'} registrado{usuarios.usuarios?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_administradores?.agregar ?
                                <Link to="/usuarios/nuevo" className="btn btn-success text-right">Nuevo usuario</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Email</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(usuarios.usuarios != undefined ? usuarios.usuarios : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Usuarios;