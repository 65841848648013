import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarDistribuidores, eliminarDistribuidor } from '../../actions/distribuidores';

const Distribuidores = ({ history }) => {

    const dispatch = useDispatch();

    const distribuidores = useSelector(state => state.distribuidores);
    const { permisos_distribuidores } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.telefono}</td>
                            <td>{item.email}</td>
                            <td>
                                {
                                    permisos_distribuidores?.editar ?
                                        <Link className="btn btn-link text-link" to={`/distribuidores/editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_distribuidores?.perfil ?
                                        <Link className="btn btn-link text-success" to={`/distribuidores/detalles/${item._id}`}>Perfil</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_distribuidores?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarDistribuidor(id));
    }

    useEffect(() => {
        dispatch(buscarDistribuidores());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Distribuidores</h3>
                        <p className="text-muted">{distribuidores.distribuidores?.length} distribuidor{distribuidores.distribuidores?.length == 1 ? '' : 'es'} registrado{distribuidores.distribuidores?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_distribuidores?.agregar ?
                                <Link to="/distribuidores/nuevo" className="btn btn-success text-right">Nuevo distribuidor</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Email</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(distribuidores.distribuidores != undefined ? distribuidores.distribuidores : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Distribuidores;