import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { detallesFarmacia, eliminarCajero } from '../../actions/farmacias';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const FarmaciaDetalles = () => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles } = useSelector(state => state.farmacias);
    const { permisos_farmacias } = useSelector(state => state.auth.user);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay empleados asignados aún</b></p>);

    const columnsEmpleados = [
        {
            name: 'Nombre',
            selector: 'nombre',
            sortable: true,
            cell: item => item.nombre ? item.nombre : '-',
        },
        {
            name: 'Teléfono',
            selector: 'telefono',
            sortable: true,
            cell: item => item.telefono ? item.telefono : '-',
        },
        {
            name: 'Fecha de ingreso',
            selector: 'date',
            sortable: false,
            cell: item => new Date(item.date).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: '',
            selector: 'id',
            sortable: false,
            cell: item => {
                if(permisos_farmacias?.editar_empleado){
                    return <Link className="btn btn-link text-primary" to={`/cajeros/editar/${item._id}/${id}`}>Editar</Link>
                }else{
                    return null;
                }
            },
        },
        {
            name: '',
            selector: 'id',
            sortable: false,
            cell: item => {
                if(permisos_farmacias?.eliminar_empleado){
                    return <button className="btn btn-link text-danger" onClick={() => eliminarEmpleado(item._id)}>Eliminar</button>
                }else{
                    return null;
                }
            },
        }
    ]

    const eliminarEmpleado = (id) => {
        dispatch(eliminarCajero(id));
    }

    useEffect(() => {
        dispatch(detallesFarmacia(id));
    }, [id]);

    return (
        detalles ? <Fragment>
            <div className="row px-5 pt-5">
                <div className="col-md-12">

                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    {
                                        permisos_farmacias?.estadisticas ?
                                            <Link type="button" className="btn btn-primary text-right" to={`/farmacias/estadisticas/${id}`}>Estadísticas</Link>
                                        :
                                            null
                                    }
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{detalles.cajeros?.length ? detalles.cajeros?.length : 0}</p>
                                    <p style={styles.infoText}>empleados activos</p>
                                </div>

                                <div className="col-md">
                                    <p style={styles.infoNumero}>{detalles.num_ventas ? detalles.num_ventas : 0}</p>
                                    <p style={styles.infoText}>ventas totales realizadas</p>
                                </div>

                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(detalles.ingresos)
                                    }</p>
                                    <p style={styles.infoText}>ingresos generadas</p>
                                </div>

                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(detalles.ganancias)
                                    }</p>
                                    <p style={styles.infoText}>ganancias generadas</p>
                                </div>

                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(detalles.gastos)
                                    }</p>
                                    <p style={styles.infoText}>gastos generados</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Información de sucursal</h5>
                            <div className="row">
                                <div className="col-md-3 px-5">
                                    <div className="d-flex justify-content-center">
                                        { detalles.url_perfil ?
                                            <img
                                                height="150"
                                                className="my-3 text-center"
                                                src={detalles.url_perfil}
                                                alt=""
                                            ></img>
                                        :   [
                                                (detalles.gerente?.sexo == 'm' ?
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_distribuidor.png"
                                                            alt=""
                                                        ></img>
                                                    :
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_distribuidora.png"
                                                            alt=""
                                                        ></img>
                                                )
                                            ]
                                        }
                                    </div>
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 text-center">
                                            <h3 className="text-muted"><Badge variant="warning">Gerente</Badge></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 row">
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Nombre</p>
                                        <p style={styles.info}>{detalles.nombre}</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p style={styles.subtitulo}>Dirección</p>
                                        <p style={styles.info}>{detalles.direccion}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Gerente</p>
                                        <p style={styles.info}>{detalles.gerente ? detalles.gerente.nombre : 'Sin gerente asignado'}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Teléfono</p>
                                        <p style={styles.info}>{detalles.gerente ? detalles.gerente.telefono : '-'}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Correo</p>
                                        <p style={styles.info}>{detalles.gerente ? detalles.gerente.email : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Empleados asignados</h5>
                            <div className="col-md-12 text-right">
                                {
                                    permisos_farmacias?.agregar_empleado ?
                                        <Link className="btn btn-success text-right" to={`/cajeros/nuevo/${id}`}>Nuevo empleado</Link>
                                    :
                                        null
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={styles.sectionContainer}>
                                        <DataTable
                                            columns={columnsEmpleados}
                                            data={detalles?.cajeros}
                                            defaultSortField="title"
                                            noHeader={true}
                                            pagination
                                            paginationComponentOptions={paginationOptions}
                                            noDataComponent={noDataConst}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default FarmaciaDetalles;
