import { LOAD_FARMACIAS, ADD_FARMACIA, EDIT_FARMACIA, DETAIL_FARMACIA, INVENTORY_FARMACIA, DELETE_FARMACIA, ADD_CAJERO, EDIT_CAJERO, DETAIL_CAJERO, DELETE_CAJERO } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const buscarFarmacias = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_farmacias/`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_FARMACIAS,
            payload: res.data.farmacias
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const crearFarmacia = (farmacia) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }
    }

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_farmacias/agregar`, farmacia, config);
        dispatch(changeLoader(false));
        dispatch({
            type: ADD_FARMACIA,
            payload: res.data.farmacia
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarFarmacia = (farmacia) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }
    }

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_farmacias/editar`, farmacia, config);
        dispatch(changeLoader(false));
        dispatch({
            type: EDIT_FARMACIA,
            payload: res.data.farmacia
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesFarmacia = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_farmacias/detalles`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: DETAIL_FARMACIA,
            payload: res.data.farmacia
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const inventarioFarmacia = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_farmacias/inventario`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: INVENTORY_FARMACIA,
            payload: res.data.productos
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const eliminarFarmacia = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_farmacias/eliminar`, body, config);
        dispatch({
            type: DELETE_FARMACIA,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const crearCajero = (cajero) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(cajero);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_cajeros/agregar`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: ADD_CAJERO,
            payload: res.data.cajero
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarCajero = (cajero) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(cajero);

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_cajeros/editar`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: EDIT_CAJERO,
            payload: res.data.cajero
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesCajero = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_cajeros/detalles`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: DETAIL_CAJERO,
            payload: res.data.cajero
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const eliminarCajero = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_cajeros/eliminar`, body, config);
        dispatch({
            type: DELETE_CAJERO,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}