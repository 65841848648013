import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Badge } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';

import { detallesDistribuidor } from '../../actions/distribuidores';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const DistribuidorDetalles = () => {

    const { id } = useParams();

    const dispatch = useDispatch();

    const distribuidor = useSelector(state => state.distribuidores);

    useEffect(() => {
        dispatch(detallesDistribuidor(id));
    }, [id])

    return (
        distribuidor.detalles ? 
        <div className="row px-5 pt-5">
            
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 px-4">
                        <h5 style={styles.titulo}>Indicadores generales</h5>
                        <div className="row">
                            <div className="col-md">
                                <p style={styles.infoNumero}>{distribuidor.detalles.medicos_gestionados ? distribuidor.detalles.medicos_gestionados : 0}</p>
                                <p style={styles.infoText}>médicos gestionados</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{distribuidor.detalles.cantidad_biogenicas ? distribuidor.detalles.cantidad_biogenicas : 0}</p>
                                <p style={styles.infoText}>biogenicas vendidas</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{
                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(distribuidor.detalles.derrama_generada ? distribuidor.detalles.derrama_generada : 0)
                                }</p>
                                <p style={styles.infoText}>derrama generada</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{
                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(distribuidor.detalles.ganancias_totales ? distribuidor.detalles.ganancias_totales : 0)
                                }</p>
                                <p style={styles.infoText}>ganancia obtenida</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-12">
                        <h5 style={styles.titulo}>Información Personal</h5>
                        <div className="row">
                            <div className="col-md-3 px-5">
                                <div className="d-flex justify-content-center">
                                    { distribuidor.detalles.url_perfil_g ?
                                        <img
                                            height="150"
                                            className="my-3 text-center picture-med"
                                            src={distribuidor.detalles.url_perfil_g}
                                            alt=""
                                        ></img>
                                    :   [
                                            (distribuidor.detalles.sexo == 'm' ?
                                                    <img
                                                        height="150"
                                                        className="my-3 text-center"
                                                        src="/assets/images/avatar_distribuidor.png"
                                                        alt=""
                                                    ></img>
                                                :
                                                    <img
                                                        height="150"
                                                        className="my-3 text-center"
                                                        src="/assets/images/avatar_distribuidora.png"
                                                        alt=""
                                                    ></img>
                                            )
                                        ]
                                    }
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-12 text-center">
                                        <h3 className="text-muted"><Badge variant="warning">Distribuidor</Badge></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 row">
                                <div className="col-md-4">
                                    <p style={styles.subtitulo}>Nombre</p>
                                    <p style={styles.info}>{distribuidor.detalles.nombre}</p>
                                </div>
                                <div className="col-md-4">
                                    <p style={styles.subtitulo}>Fecha de registro</p>
                                    <p style={styles.info}>{new Date(distribuidor.detalles.date).toLocaleDateString('es-MX')}</p>
                                </div>
                                <div className="col-md-4">
                                    <p style={styles.subtitulo}>Correo</p>
                                    <p style={styles.info}>{distribuidor.detalles.email}</p>
                                </div>
                                <div className="col-md-4">
                                    <p style={styles.subtitulo}>Teléfono</p>
                                    <p style={styles.info}>{distribuidor.detalles.telefono}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <div></div>
    )
}




export default DistribuidorDetalles;
