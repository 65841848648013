import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import '@fortawesome/fontawesome-free/css/all.css';


const Sidebar = ({ history, location }) => {

    const { permisos_medicos, permisos_pacientes, permisos_distribuidores, permisos_gerentes, permisos_proveedores, permisos_administradores, permisos_ordenes_proceso, permisos_ordenes_finalizadas, permisos_pagos_distribuidores, permisos_pagos_medicos, permisos_farmacias, permisos_categorias, permisos_productos, permisos_reportes, permisos_promociones_generales, permisos_promociones_productos, permisos_promociones_tarjetas, permisos_avisos, permisos_noticias, permisos_configuracion } = useSelector(state => state.auth.user);

    const styles = {
        navStyles: { fontSize: '1rem', color: '#EEE', whiteSpace: 'nowrap' }
    }
    
    return (
        <SideNav
            style={{ backgroundColor: '#198FC1', position: 'fixed' }}
            onSelect={(selected) => {
                if (location.pathname != selected) {
                    history.push(selected);
                }
            }}
        >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected="/dashboard">
                <NavItem eventKey="/dashboard">
                    <NavIcon>
                        <i className="fa fa-home" style={styles.navStyles} />
                    </NavIcon>
                    <NavText style={styles.navStyles}>
                        Inicio
                    </NavText>
                </NavItem>

                {
                    permisos_medicos?.ver || permisos_pacientes?.ver || permisos_distribuidores?.ver || permisos_gerentes?.ver || permisos_proveedores?.ver || permisos_administradores?.ver ?
                        <NavItem eventKey="/medicos" subnavStyle={styles.submenuStyle}>
                            <NavIcon>
                                <i className="fa fa-user" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Usuarios
                            </NavText>
                            
                            {
                                permisos_medicos?.ver ?
                                    <NavItem eventKey="/medicos">
                                        <NavText>
                                            Médicos
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_pacientes?.ver ?
                                    <NavItem eventKey="/pacientes">
                                        <NavText>
                                            Pacientes
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_distribuidores?.ver ?
                                    <NavItem eventKey="/distribuidores">
                                        <NavText>
                                            Distribuidores
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_gerentes?.ver ?
                                    <NavItem eventKey="/gerentes">
                                        <NavText>
                                            Gerentes
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_proveedores?.ver ?
                                    <NavItem eventKey="/proveedores">
                                        <NavText>
                                            Proveedores
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_administradores?.ver ?
                                    <NavItem eventKey="/usuarios">
                                        <NavText>
                                            Administradores
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }
                        </NavItem>
                    :
                        null
                }

                {
                    permisos_ordenes_proceso?.ver || permisos_ordenes_finalizadas?.ver ?
                        <NavItem eventKey="/ordenes" subnavStyle={styles.submenuStyle}>
                            <NavIcon>
                                <i className="fa fa-shopping-cart" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Órdenes
                            </NavText>

                            {
                                permisos_ordenes_proceso?.ver ?
                                    <NavItem eventKey="/ordenes">
                                        <NavText>
                                            En proceso
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_ordenes_finalizadas?.ver ?
                                    <NavItem eventKey="/ordenes/finalizadas">
                                        <NavText>
                                            Finalizadas
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }
                        </NavItem>
                    :
                        null
                }

                {
                    permisos_pagos_distribuidores?.ver || permisos_pagos_medicos?.ver ?
                        <NavItem eventKey="/pagos" subnavStyle={styles.submenuStyle}>
                            <NavIcon>
                                <i className="fa fa-money-bill-wave-alt" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Pagos
                            </NavText>
                            {
                                permisos_pagos_distribuidores?.ver ?
                                    <NavItem eventKey="/pagos/distribuidores">
                                        <NavText>
                                            Distribuidores
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_pagos_medicos?.ver ?
                                    <NavItem eventKey="/pagos/medicos">
                                        <NavText>
                                            Médicos
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }
                        </NavItem>
                    :
                        null
                }
                
                {
                    permisos_farmacias?.ver ?
                        <NavItem eventKey="/farmacias">
                            <NavIcon>
                                <i className="fa fa-clinic-medical" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Farmacias
                            </NavText>
                        </NavItem>
                    :
                        null
                }

                {
                    permisos_categorias?.ver || permisos_productos?.ver ?
                        <NavItem eventKey="/categorias" subnavStyle={styles.submenuStyle}>
                            <NavIcon>
                                <i className="fa fa-box-open" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Productos
                            </NavText>
                            {
                                permisos_categorias?.ver ?
                                    <NavItem eventKey="/categorias">
                                        <NavText>
                                            Categorías
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_productos?.ver ?
                                    <NavItem eventKey="/productos">
                                        <NavText>
                                            Productos
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }
                        </NavItem>
                    :
                        null
                }
                    
                {
                    permisos_reportes?.ver ?
                        <NavItem eventKey="/reportes">
                            <NavIcon>
                                <i className="fa fa-file-alt" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Reportes
                            </NavText>
                        </NavItem>
                    :
                        null
                }

                {
                    permisos_promociones_generales?.ver || permisos_promociones_productos?.ver ?
                        <NavItem eventKey="/promociones" subnavStyle={styles.submenuStyle}>
                            <NavIcon>
                                <i className="fa fa-percent" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Promociones
                            </NavText>
                            {
                                permisos_promociones_generales?.ver ?
                                    <NavItem eventKey="/promociones">
                                        <NavText>
                                            Generales
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_promociones_productos?.ver ?
                                    <NavItem eventKey="/promociones/productos">
                                        <NavText>
                                            Por productos
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }

                            {
                                permisos_promociones_tarjetas?.ver ?
                                    <NavItem eventKey="/promociones/tarjetas">
                                        <NavText>
                                            Tarjetas
                                        </NavText>
                                    </NavItem>
                                :
                                    null
                            }
                        </NavItem>
                    :
                        null
                }
                
                {
                    permisos_avisos?.ver ?
                        <NavItem eventKey="/avisos">
                            <NavIcon>
                                <i className="fa fa-exclamation-triangle" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Avisos
                            </NavText>
                        </NavItem>
                    :
                        null
                }

                {
                    permisos_noticias?.ver ?
                        <NavItem eventKey="/noticias">
                            <NavIcon>
                                <i className="fa fa-newspaper" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                    Noticias
                            </NavText>
                        </NavItem>
                    :
                        null
                }

                {
                    permisos_configuracion?.ver ?
                        <NavItem eventKey="/configuracion">
                            <NavIcon>
                                <i className="fa fa-cogs" style={styles.navStyles} />
                            </NavIcon>
                            <NavText style={styles.navStyles}>
                                Configuración
                            </NavText>
                        </NavItem>
                    :
                        null
                }
            </SideNav.Nav>
        </SideNav>

    )
}

export default withRouter(Sidebar);
