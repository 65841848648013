import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buscarFarmacias, eliminarFarmacia } from '../../actions/farmacias';

const Farmacias = ({ history }) => {

    const dispatch = useDispatch();

    const { farmacias } = useSelector(state => state.farmacias);
    const { permisos_farmacias } = useSelector(state => state.auth.user);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.direccion}</td>
                            <td>{new Date(item.date).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>
                                {
                                    permisos_farmacias?.inventario ?
                                        <Link className="btn btn-link text-success" to={`/farmacias/inventario/${item._id}`}>Inventario</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_farmacias?.inventario ?
                                        <Link className="btn btn-link text-success" to={`/farmacias/ubicaciones/${item._id}`}>Ubicaciones</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_farmacias?.detalles ?
                                        <Link className="btn btn-link text-success" to={`/farmacias/detalles/${item._id}`}>Detalles</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_farmacias?.editar ?
                                        <Link className="btn btn-link text-link" to={`/farmacias/editar/${item._id}`}>Editar</Link>
                                    :
                                        null
                                }
                            </td>
                            <td>
                                {
                                    permisos_farmacias?.eliminar ?
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    :
                                        null
                                }
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarFarmacia(id));
    }

    useEffect(() => {
        dispatch(buscarFarmacias());
    }, []);

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Farmacias</h3>
                        <p className="text-muted">{farmacias?.length} farmacia{farmacias?.length == 1 ? '' : 's'} registrada{farmacias?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            permisos_farmacias?.agregar ?
                                <Link to="/farmacias/nueva" className="btn btn-success text-right">Nueva farmacia</Link>
                            :
                                null
                        }
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Direccion</th>
                            <th scope="col">Registro</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(farmacias != undefined ? farmacias : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Farmacias;