import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import { crearPromocion } from '../../actions/promociones';

registerLocale('es', es);

const PromocionNueva = ({ history }) =>{

    const dispatch = useDispatch();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(crearPromocion(promocionData));
            history.push('/promociones');
        } catch (error) {
            return false;
        }
    }
    
    const [ promocionData, setPromocionData ] = useState({
        concepto: '',
        porcentaje: '',
        tipo: 2,
        usos: 0,
        fecha_inicio: '',
        fecha_fin: ''
    });
    
    const { concepto, porcentaje, tipo, usos, fecha_inicio, fecha_fin } = promocionData;
    
    const onChange = (e) => {
        setPromocionData({
            ...promocionData, [e.target.name]: e.target.value
        })
    }

    const onChangeTipo = (e) => {
        if(e.target.value == 0){
            setPromocionData({
                ...promocionData,
                tipo: e.target.value,
                fecha_inicio: '',
                fecha_fin: ''
            });
        }else{
            if(e.target.value == 2){
                setPromocionData({
                    ...promocionData,
                    tipo: e.target.value,
                    fecha_inicio: '',
                    fecha_fin: '',
                    usos: 0
                });
            }else{
                setPromocionData({
                    ...promocionData,
                    tipo: e.target.value,
                    usos: 0
                });
            }
        }
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Nuevo descuento</h3>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Concepto</label> <br></br>
                        <input type="text" className="form-control" placeholder="Concepto de descuento" name="concepto" onChange={e => onChange(e) } value={concepto}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Porcentaje</label> <br></br>
                        <input type="text" className="form-control" placeholder="%" name="porcentaje" onChange={e => onChange(e) } value={porcentaje}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Tipo</label> <br></br>
                        <select className="form-control" name="oferta_activa" value={tipo} onChange={e => onChangeTipo(e)}>
                            <option value="2">Selecciona una opción</option>
                            <option value="0">Usos</option>
                            <option value="1">Fecha</option>
                        </select>
                    </div>
                </div>
                {
                    promocionData.tipo == 0 &&
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Número de usos permitidos</label> <br></br>
                                <input type="number" className="form-control" placeholder="# de usos" name="usos" onChange={e => onChange(e) } value={usos}></input>
                            </div>
                        </div>

                }
                {
                    promocionData.tipo == 1 &&
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Fecha de inicio:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ fecha_inicio ? new Date(fecha_inicio) : '' } onChange={ date => setPromocionData({ ...promocionData, fecha_inicio: date })}/>
                                </div>
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Fecha de fin:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ fecha_fin ? new Date(fecha_fin) : '' } onChange={ date => setPromocionData({ ...promocionData, fecha_fin: date })}/>
                                </div>
                            </div>
                        </div>

                }
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Crear descuento</button>
            </form>
        </div>
    )
}

export default PromocionNueva;