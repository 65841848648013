import { LOAD_GERENTES, LOAD_SUCURSALES, LOAD_EDITABLES, ADD_GERENTE, DETAIL_GERENTE, EDIT_GERENTE, DELETE_GERENTE } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_GERENTES:
        	return {
                ...state,
                gerentes: payload
            }
        case LOAD_SUCURSALES:
            return {
                ...state,
                sucursales: payload
            }
        case LOAD_EDITABLES:
            return {
                ...state,
                editables: payload
            }
        case ADD_GERENTE:
            return {
                ...state,
                gerentes: [
                    ...state.gerentes, payload
                ]
            }
        case EDIT_GERENTE:
            return {
                ...state,
                gerentes: state.gerentes.map( gerente => gerente._id == payload._id ? payload : gerente)
            }
        case DETAIL_GERENTE:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_GERENTE:
            return {
                ...state,
                gerentes: state.gerentes.filter( gerente => gerente._id != payload.id )
            }
        default:
            return state;
    }
}