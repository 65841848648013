import { LOAD_PAGOS_DISTRIBUIDORES, DETAIL_PAGO_DISTRIBUIDOR, PROCESS_PAGO_DISTRIBUIDOR, LOAD_PAGOS_MEDICOS, DETAIL_PAGO_MEDICO, PROCESS_PAGO_MEDICO } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_PAGOS_DISTRIBUIDORES:
        	return {
                ...state,
                distribuidores: payload
            }
        case DETAIL_PAGO_DISTRIBUIDOR:
        	return {
                ...state,
                detalles_distribuidor: payload
            }
        case PROCESS_PAGO_DISTRIBUIDOR:
            return {
                ...state,
                detalles_distribuidor: payload
            }
        case LOAD_PAGOS_MEDICOS:
            return {
                ...state,
                medicos: payload
            }
        case DETAIL_PAGO_MEDICO:
            return {
                ...state,
                detalles_medico: payload
            }
        case PROCESS_PAGO_MEDICO:
            return {
                ...state,
                detalles_medico: payload
            }
        default:
            return state;
    }
}