import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import { editarUbicacion, detallesUbicacion } from '../../actions/ubicaciones';
import { detallesFarmacia } from '../../actions/farmacias';

registerLocale('es', es);

const UbocacionEditar = ({ history }) =>{

    const { id, id_farmacia } = useParams();

    const dispatch = useDispatch();

    const { detalles } = useSelector(state => state.farmacias);
    const ubicacion = useSelector(state => state.ubicaciones);
    
    const [ubicacionData, setUbicacionData] = useState({
        id: '',
        nombre: '', 
        nivel: ''
    });
    
    const onChange = (e) => {
        setUbicacionData({
            ...ubicacionData, 
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(editarUbicacion(ubicacionData));
            history.push('/farmacias/ubicaciones/' + id_farmacia);
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        dispatch(detallesUbicacion(id));
    }, [id]);

    useEffect(() => {
        setUbicacionData({
            ...ubicacionData,
            id: ubicacion.detalles?._id,
            nombre: ubicacion.detalles?.nombre, 
            nivel: ubicacion.detalles?.nivel
        })
    }, [ubicacion]);

    useEffect(() => {
        if(id_farmacia){
            dispatch(detallesFarmacia(id_farmacia));

            setUbicacionData({
                ...ubicacionData, 
                id_farmacia
            });
        }
    }, [id_farmacia]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 my-5">Editar ubicacion de {detalles?.nombre}</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={ubicacionData.nombre || ''}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Nivel</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nivel" name="nivel" onChange={e => onChange(e) } value={ubicacionData.nivel || ''}></input>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Editar ubicación</button>
            </form>
        </div>
    )
}

export default UbocacionEditar;