import { LOAD_PRODUCTOS, ADD_PRODUCTO, EDIT_PRODUCTO, DETAIL_PRODUCTO, DELETE_PRODUCTO, 
    LOAD_CATEGORIAS, ADD_CATEGORIA, EDIT_CATEGORIA, DETAIL_CATEGORIA, DELETE_CATEGORIA
} from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_PRODUCTOS:
        	return {
                ...state,
                productos: payload
            }
        case LOAD_CATEGORIAS: 
            return {
                ...state,
                categorias: payload
            }
        case ADD_PRODUCTO:
            return {
                ...state,
                productos: [
                    ...state.productos, payload
                ]
            }
        case ADD_CATEGORIA:
            return {
                ...state,
                categorias: [
                    ...state.categorias, payload
                ]
            }
        case EDIT_PRODUCTO:
            return {
                ...state,
                productos: state.productos.map( producto => producto._id == payload._id ? payload : producto)
            }
        case EDIT_CATEGORIA:
            return {
                ...state,
                categorias: state.categorias.map( categoria => categoria._id == payload._id ? payload : categoria)
            }
        case DETAIL_PRODUCTO:
        	return {
                ...state,
                detalles: payload
            }
        case DETAIL_CATEGORIA:
        	return {
                ...state,
                detalles_categoria: payload
            }
        case DELETE_PRODUCTO:
            return {
                ...state,
                productos: state.productos.filter( producto => producto._id != payload.id )
            }
        case DELETE_CATEGORIA:
            return {
                ...state,
                categorias: state.categorias.filter( categoria => categoria._id != payload.id )
            }
        default:
            return state;
    }
}