import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { detallesPaciente } from '../../actions/pacientes';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const PacienteDetalles = () => {

    const { id } = useParams();

    const dispatch = useDispatch();

    const paciente = useSelector(state => state.pacientes);

    useEffect(() => {
        dispatch(detallesPaciente(id));
    }, [id])

    return (
        paciente.detalles ? <div className="row pt-5">
            <div className="col-md-3 px-5">
                <div className="d-flex justify-content-center">
                    { paciente.detalles.url_perfil ?
                        <img
                            height="150"
                            className="my-3 text-center"
                            src={paciente.detalles.url_perfil}
                        ></img>
                    :   [
                            (paciente.detalles.sexo == 'm' ?
                                    <img
                                        height="150"
                                        className="my-3 text-center"
                                        src="/assets/images/avatar_hombre.png"
                                    ></img>
                                :
                                    <img
                                        height="150"
                                        className="my-3 text-center"
                                        src="/assets/images/avatar_mujer.png"
                                    ></img>
                            )
                        ]
                    }
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 text-center">
                        <h4 className="">{paciente.detalles.nombre} {paciente.detalles.apellido}</h4>
                        <h3 className="text-muted"><Badge variant="success">Paciente</Badge></h3>
                    </div>
                </div>
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-12 px-4">
                        <div className="row">
                            <div className="col-md">
                                <p style={styles.infoNumero}>{paciente.detalles.num_pacientes ? paciente.detalles.num_pacientes : 0} %</p>
                                <p style={styles.infoText}>cumplimiento</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{paciente.detalles.num_biogenicas ? paciente.detalles.num_biogenicas : 0}</p>
                                <p style={styles.infoText}>biogenicas adquiridas</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{
                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(0)
                                }</p>
                                <p style={styles.infoText}>derrama generada</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-12">
                        <Accordion>
                            <div className="row">
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <h5 style={styles.titulo}>
                                            Información Personal
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <div style={styles.sectionContainer}>
                                            <p style={styles.subtitulo}>Nombre</p>
                                            <p style={styles.info}>{paciente.detalles.nombre} {paciente.detalles.apellido}</p>
                                            <p style={styles.subtitulo}>Fecha de registro</p>
                                            <p style={styles.info}>{new Date(paciente.detalles.date).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                            <p style={styles.subtitulo}>Correo</p>
                                            <p style={styles.info}>{paciente.detalles.email ? paciente.detalles.email : '-'}</p>
                                            <p style={styles.subtitulo}>Teléfono</p>
                                            <p style={styles.info}>{paciente.detalles.telefono ? paciente.detalles.telefono : '-'}</p>
                                            <p style={styles.subtitulo}>Fecha de nacimiento</p>
                                            <p style={styles.info}>{new Date(paciente.detalles.nacimiento).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div> : <div></div>
    )
}




export default PacienteDetalles;
